<template>
  <extra-table :title="$t('pickups')" entity="pickups" :fields="fields"></extra-table>
</template>

<script>
import ExtraTable from './ExtraTable.vue'

export default {
  metaInfo () {
    return { title: this.$i18n.t('pickups') }
  },
  components: { 'extra-table': ExtraTable },
  data () {
    return {
      fields: [
        { name: 'reference' },
        { name: 'carrier_account_id' },
        { name: 'test' },
        { name: 'status' },
        { name: 'min_datetime', column: false },
        { name: 'max_datetime', column: false }
      ]
    }
  }
}
</script>
