<template>
  <v-container v-if="document">
    <pdf
      ref="pdf"
      :src="mimePrefix('pdf') + document.document"
      v-if="document.format === 'PDF'"
    ></pdf>
    <p v-else-if="document.format === 'HTML'" v-html="document.document"></p>
    <img
      v-else-if="['JPG', 'PNG', 'GIF', 'SVG'].includes(document.format)"
      height="96%"
      :src="`data:image/${document.format};base64,${document.document}`"
    />
    <pre v-else v-text="decode64(document.document)"></pre>
    <v-btn
      v-if="document.format !== 'XLS'"
      fab
      absolute
      :color="print_button_color"
      :loading="printing"
      bottom
      right
      @click="print(document)"
      style="bottom: 33px; right: 30px"
    >
      <o-icon>{{ print_button_icon }}</o-icon>
    </v-btn>
    <v-btn
      fab
      absolute
      small
      bottom
      right
      :href="mimePrefix(document.format) + document.document"
      :download="document.id + '.' + document.format"
      style="bottom: 39px; right: 100px"
    >
      <o-icon>download</o-icon>
    </v-btn>
  </v-container>
</template>

<script>
import Vue from "vue";
import OIcon from "./OIcon.vue";
import pdf from "vue-pdf";
import download from "downloadjs";
import ExtraPostNodes from "../nodes.js";

export default Vue.component("document", {
  name: "document",
  props: {
    document: {
      type: Object,
    },
  },
  components: {
    pdf,
    OIcon,
  },
  data() {
    return {
      printing: false,
      print_button_icon: "print",
      print_button_color: "primary",
    };
  },
  watch: {
    document: function (document) {
      console.log(document);
      this.printing = false;
      this.print_button_color = "primary";
      this.print_button_icon = "print";
    },
  },
  methods: {
    mimePrefix(format) {
      if (format === undefined) return "";

      switch (format.toLowerCase()) {
        case "pdf":
          return "data:application/octet-stream;base64,";

        case "xls":
          return "data:application/octet-stream;base64,";

        case "html":
          return "data:text/html;charset=utf-8,";

        case "zpl":
          return "data:text/zpl;charset=utf-8,";

        case "epl":
          return "data:text/epl;charset=windows-1251,";

        case "png":
        case "gif":
        case "svg":
        case "jpg":
        case "jpeg":
          return `data:image/${format.toLowerCase()};base64,`;

        default:
          return "data:text/plain;charset=utf-8,base64,";
      }
    },
    print(document) {
      var self = this;
      this.printing = true;
      this.print_button_color = "primary";
      this.doPrint(document)
        .then((resp) => {
          console.log("Print success: " + resp);
          self.printing = false;
          self.print_button_icon = "check";
          self.print_button_color = "success";
        })
        .catch((error) => {
          console.dir(error, { colors: true });
          self.printing = false;
          self.print_button_icon = "error_outline";
          self.print_button_color = "error";
        });
    },
    doPrint(document) {
      if (document.type === "label" || ["ZPL", "EPL"].includes(document.format))
        return ExtraPostNodes.printLabel(document.id);
      else return ExtraPostNodes.printDocument(document.id);
    },
    decode64(base64) {
      return window.atob(base64);
    },
  },
});
</script>
