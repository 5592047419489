<template>
  <img v-if="carrier"
    :width="width()"
    style="vertical-align: middle;"
    :src="'/img/logos/' + carrier + '.svg'"
  />
</template>

<script>
export default {
  name: "carrier-icon",
  props: {
    small: { type: Boolean, default: true },
    carrier: String,
  },
  methods: {
    width() {
      if (this.small) {
        return "25px";
      } else {
        return "";
      }
    },
    onClick(event) {
      if (this.$listeners.click) {
        this.$listeners.click();
      }
    },
  },
};
</script>
