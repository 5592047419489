<template>
  <v-container v-if="shipment">
    <v-dialog v-model="labelPreview" scrollable hide-overlay full-width>
      <v-card style="min-width: 400px; min-height: 200px;">
        <v-card-title class="pb-0">
          <span class="headline pl-2">{{ shipment.label.id }}</span>
          <v-spacer />
          <v-btn icon @click.native="closeLabelPreview()" :ripple="uiAnimation">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <extra-document :document="shipment.label"></extra-document>
        </v-card-text>
      </v-card>
    </v-dialog>
    <a @click="previewLabel()">{{ shipment.label_id }}</a>
  </v-container>
</template>
<script>
import Vue from "vue";
import Document from "./Document.vue";

export default Vue.component("shipment", {
  name: "shipment",
  props: {
    shipment: {
      type: Object,
      default: {
        to_address: { country: "RU", zip: "", name: "" },
        currency: "",
        label: {},
        extra: {},
      },
    },
    errors: {
      type: Object,
      default: () => {
        {
        }
      },
    },
  },
  components: {
    "extra-document": Document,
  },
  data() {
    return {
      labelPreview: false,
    };
  },
  methods: {
    previewLabel() {
      this.labelPreview = true;
    },
    closeLabelPreview() {
      this.labelPreview = false;
    },
  },
});
</script>
