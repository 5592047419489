<template>
  <extra-table :title="$t('trackers')" entity="trackers" :fields="fields"></extra-table>
</template>

<script>
import ExtraTable from './ExtraTable.vue'
import { carriersList, carrierImageTag, trackerStatusIcon } from '../functions.js'

export default {
  metaInfo () {
    return { title: this.$i18n.t('trackers') }
  },
  components: { 'extra-table': ExtraTable },
  data () {
    return {
      fields: [
        { name: 'tracking_code', sortable: true, value: (t) => {
            return '<nobr>' + carrierImageTag(t.carrier) + "&nbsp;" + t.tracking_code + '</nobr>'
          }
        },
        { name: 'carrier', list: carriersList(), column: false },
        { name: 'status', value: (t) => { return trackerStatusIcon(t.status) } },
        { name: 'sender', value: (t) => t.extra.sender, editable: false },
        { name: 'elapsed_days', column: false, editable: false },
        { name: 'tracking_history', column: false, fields: [
          { name: 'date' },
          { name: 'location.name' },
          { name: 'operation' }
        ]}
      ]
    }
  },
  methods: {
  }
}
</script>
