import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookie from "vue-cookie";

Vue.use(VueI18n);

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
countries.registerLocale(require("i18n-iso-countries/langs/zh.json"));

const messages = {
  en: {
    en: "English",
    ru: "Russian",
    zh: "Chinese",

    extra: "Extra",
    post: "Post",
    extrapost: "ExtraPost",
    home: "Home",
    dashboard: "Dashboard",
    login: "Login",
    in_one_click_with: "In one click with",
    or_with_login_and_password: "Or with login and password",
    register: "Register",
    email: "Email",
    password: "Password",
    password_confirm: "Confirm password",
    logout: "Logout",
    dark_theme: "Dark theme",
    ui_animation: "UI Animation",
    ooo_extra: '"Extra" LLC',
    all: "All",
    carriers: "Carriers",
    carrier_accounts: "Carrier Accounts",
    order_sources: "Order Sources",
    carrier: "Carrier",
    service: "Service",
    services: "Services",
    mailing_types: "Mailing types",
    addresses: "Addresses",
    companies: "Companies",
    orders: "Orders",
    shipments: "Shipments",
    documents: "Documents",
    document: "Document",
    manifest: "Manifest",
    parcels: "Parcels",
    batches: "Batches",
    batch_id: "Batch",
    pickups: "Pickups",
    trackers: "Trackers",
    products: "Products",
    products_in_orders: "Products in orders",
    fulfillment: "Fulfillment",
    lists: "Lists",
    order_fulfillment: "Fulfillment of order",
    settings: "Settings",
    load_label_fonts: "Load label fonts",
    users: "Users",
    add: "Add",
    search: "Search",
    fast_search: "Fast Search",
    delete_selected: "Delete selected",
    add_carrier_account: "Add Carrier Account",
    add_card: "Add",
    email_is_mandatory: "Email is mandatory",
    wrong_email_format: "Wrong email format",
    password_is_mandatory: "Password is mandadory",
    password_longer_6: "Password should be longer, than 6 symbols",
    password_confirm_is_mandatory: "Password confirmation is mandatory",
    password_confirm_equal: "Password and password confirmation are not equal",
    sure_to_delete: "Are you sure you want to delete this item?",
    line_item_not_found:
      'Product with barcode "%{barcode}" is not found in this order.',
    enough_line_items:
      'The order already has enough products with barcode "%{barcode}" — %{quantity} pcs.',
    received_weight_less_than_expected:
      "Received weight is less than expected (%{expected_weight} gr.).",
    received_weight_greater_than_expected:
      "Received weight is much greater than expected (%{expected_weight} gr.).",
    no_more_orders_to_fulfill: "No more orders to fulfill",
    end_of_orders_to_fulfill: "End of orders to fulfill list. Starting over.",
    date: "Date",
    zip: "ZIP",
    country: "Country",
    locale: "Locale",
    region: "Region",
    city: "City",
    post_office: "Post Office",
    address: "Address",
    carrier_facility: "Carrier Facility",
    to_address_id: "Desination Address",
    company_id: "Company",
    street: "Address",
    name: "Name",
    legal_entity: "Legal Entity",
    phone: "Phone",
    title: "Title",
    product_title: "Product Title",
    product_sku: "Product SKU",
    sku: "SKU",
    barcode: "Barcode",
    skus: "SKUs",
    barcodes: "Barcodes",
    hs_code: "HS Code",
    origin_country: "Country of origin",
    price: "Price",
    currency: "Currency",
    comment: "Comment",
    tag: "Tag",
    google_client_id: "Google ClientID",
    receiver: "Receiver",
    sender: "Sender",
    tracking_code: "Tracking Code",
    elapsed_days: "Elapsed days",
    status: "Status",
    shipment_id: "Shipment",
    carrier_account_id: "Carrier Account",
    weight: "Weight",
    width: "Width",
    height: "Height",
    length: "Length",
    payment: "Payment",
    value: "Value",
    product_title: "Product",
    quantity: "Quantity",
    reference: "Reference",
    test: "Test",
    type: "Type",
    format: "Format",
    size: "Size",
    actions: "Actions",
    clone: "Clone",
    fulfill: "Fulfill",
    edit: "Edit",
    delete: "Delete",
    reregister_shipments: "Re-register",
    show_all_orders: "Show all orders",
    cancel: "Cancel",
    save: "Save",
    close: "Close",
    collapse: "Collapse",
    more: "More",
    generate_batches: "Generate Batches",
    print_invoice: "Print Invoice",
    print_label: "Print Label",
    label: "Label",
    invoice: "Invoice",
    fulfilled: "Fulfilled",
    next: "Next",
    speech_interface: "Speech Interface",
    speech_only_chrome:
      "Speech recognition is only supproted by Chrome browser.",
    label_printer: "Label printer",
    total: "Total",
    from: "from",
    orders_fulfilled: "orders fulfilled",
    completness_checking: "Order completness checking",
    sms_notice_recipient: "Recipient SMS notification",
    fulfillment: "Fulfillment",
    apiKey: "API key",
    token: "Token",
    domain: "Domain",
    show_map: "Show map",
    map_width: "Map width",
    map_height: "Map height",
    map_zoom: "Map zoom",
    send_date: "Send date",
    project_id: "Project",
    delivery_cost: "Delivery Cost",
    archived: "Archived",
    changes_history: "Changes history",
    inserted_at: "Date",
    user: "User",
    event: "Event",
    qty: "Qty",
    loading: "Loading...",
    import: "Import",

    gr: "gr",
    kg: "kg",
    pcs: "pcs.",

    RussianPost: "Russian Post",
    PrivatePost: "Private Post",
    CDEK: "CDEK",
    DPD: "DPD",
    Boxberry: "Boxberry",
    PickPoint: "PickPoint",
    UniExpress: "UniExpress",
    Ozon: "Ozon",
    AliExpress: "AliExpress",
    Wildberries: "Wildberries",

    Parcel: "Parcel",
    SmallPacket: "Small Packet",
    FirstClassParcel: "First Class Parcel",
    ParcelOnline: "Parcel Online",
    CourierOnline: "Courier Online",
    EMSOptimal: "EMS Optimal",
    EMS: "EMS",
    ECOM: "ECOM",

    cash_on_delivery: "COD",
    ordinary: "",

    FacilityToDoor: "Facility to Door",
    FacilityToFacility: "Facility to Facility",
    DoorToDoor: "Дверь-Дверь",
    DoorToFacility: "Дверь-Склад",
    EconomyFacilityToDoor: "Эконом. Склад-Дверь",
    EconomyFacilityToFacility: "Эконом. Склад-Склад",
    ExpressLightFacilityToDoor: "Экспресс-Лайт Склад-Дверь",

    WorldwideExpress: "Worldwide Express",

    ExpressSaver: "Express Saver",

    Courier: "Courier",

    FBS: "FBS",

    RetailCRM: "RetailCRM",
    Bitrix24: "Bitrix24",
    MoySklad: "MoySklad",

    mm: "mm",
    m3: "m³",

    USD: "US Dollar",
    USD_sign: "$",
    RUB: "Russian Rouble",
    RUB_sign: "₽",
    EUR: "Euro",
    EUR_sign: "€",
    CNY: "Yuan",
    CNY_sign: "¥",

    IC: "Canary Islands",

    settings_explanation:
      "Select one or more scales and one document printer (A4) with left radio button and one label printer (A6) with right radio button.",
  },
  ru: {
    en: "Английский",
    ru: "Русский",
    zh: "Китайский",

    extra: "Экстра",
    post: "Почта",
    extrapost: "ЭкстраПочта",
    home: "Главная",
    dashboard: "Показатели",
    login: "Вход",
    token: "Токен",
    register: "Регистрация",
    in_one_click_with: "В один клик с помощью",
    or_with_login_and_password: "Или с логином и паролём",
    email: "Эл. почта",
    email_is_mandatory: "Эл. почта обязательна",
    wrong_email_format: "Неверный формат эл. почты",
    password_is_mandatory: "Пароль обязателен",
    password_confirm_is_mandatory: "Подтверждение пароля обязательно",
    password_longer_6: "Пароль должен быть длиннее 6 символов",
    password: "Пароль",
    password_confirm: "Пароль ещё раз",
    password_confirm_equal: "Пароль и подтверждение должны совпадать",
    sure_to_delete: "Уверены, что хотите удалить?",
    line_item_not_found: 'Товар со штрихкодом "%{barcode}" в заказе не найден.',
    sender_change: 'Смена компании-отправителя на "%{sender}"',
    enough_line_items:
      'В заказе уже достаточно товаров со штрихкодом "%{barcode}" — %{quantity} шт.',
    received_weight_less_than_expected:
      "Полученный вес (%{received_weight} гр.) заметно меньше ожидаемого (%{expected_weight} гр.).",
    received_weight_greater_than_expected:
      "Полученный вес (%{received_weight} гр.) заметно больше ожидаемого (%{expected_weight} гр.).",
    no_more_orders_to_fulfill: "Нет заказов для сборки.",
    end_of_orders_to_fulfill:
      "Достигнут конец списка заказов к сборке. Начинаем с начала.",
    enter_weight_in_gramms: "Введите вес в граммах",
    price_is_lower: "Цена ниже розничной",
    logout: "Выйти",
    dark_theme: "Тёмная тема",
    ui_animation: "Анимация интерфейса",
    cancel: "Отмена",
    save: "Сохранить",
    print: "Печать",
    ooo_extra: 'ООО "Экстра"',
    all: "Все",
    id: "ID",
    carriers: "Перевозчики",
    carrier_accounts: "Перевозчики",
    carrier: "Перевозчик",
    service: "Услуга",
    services: "Услуги",
    mailing_types: "Типы отправлений",
    add_carrier_account: "Добавить перевозчика",
    add_card: "Добавить",
    addresses: "Адреса",
    address: "Адрес",
    companies: "Компании",
    order_sources: "Источники Заказов",
    orders: "Заказы",
    order: "Заказ",
    documents: "Документы",
    document: "Документ",
    manifest: "Манифест",
    shipment: "Отправка",
    shipments: "Отправки",
    existing_shipments: "Собранные отправки",
    parcels: "Посылки",
    batches: "Партии",
    send_date: "Дата отправки",
    batch_id: "Партия",
    pickups: "Заборы",
    trackers: "Трэкеры",
    products: "Товары",
    products_in_orders: "Товары в заказах",
    slot: "Ячейка адресного хранения",
    slot_address: "Адрес хранения",
    lists: "Списки",
    fulfillment: "Сборка",
    order_fulfillment: "Сборка заказа",
    settings: "Настройки",
    other_settings: "Прочие настройки",
    load_label_fonts: "Загрузить шрифты для ярлыков",
    prohibit_manual_marking: "Запретить ручную регистрацию товаров при сборке.",
    unreadable_barcode: "Нечитаемый штрихкод",
    enter_number_of_containers: "Введите количество мест в партии",
    users: "Пользователи",
    add: "Добавить",
    install: "Установить",
    uninstall: "Демонтировать",
    search: "Поиск",
    fast_search: "Быстрый поиск",
    delete_selected: "Удалить выбранные",
    export_selectd: "Экспортировать выбранные",
    export: "Экспорт",
    reregister_shipments: "Перерегистрировать",
    date: "Дата",
    zip: "Индекс",
    country: "Страна",
    locale: "Язык",
    region: "Регион",
    city: "Город",
    post_office: "Почтовое отделение",
    address: "Адрес",
    carrier_facility: "Пункт выдачи",
    street: "Адрес",
    name: "Имя",
    legal_entity: "Юр. лицо",
    taxpayer_number: "ИНН",
    api_key: "API-ключ",
    apiKey: "API ключ",
    domain: "Домен",
    phone: "Телефон",
    title: "Название",
    product_title: "Название товара",
    product_sku: "Артикул товара",
    sku: "Артикул",
    skus: "Артикулы",
    barcode: "Штрихкод",
    barcodes: "Штрихкоды",
    avia_forbidden: "Запрещено к авиа-пересылке",
    hs_code: "Код ТН ВЭД",
    customs_description: "Описание на английском для таможни",
    origin_country: "Страна происхождения",
    price: "Цена",
    currency: "Валюта",
    comment: "Комментарий",
    paper_product_suitable_for_letter:
      "Бумажный товар, подходящий для отправки письмом",
    does_not_need_package:
      "Не нуждается в упаковке (уже упакован для перевозки)",
    tag: "Метка",
    google_client_id: "Google ClientID",
    to_address_id: "Адрес Получателя",
    receiver: "Получатель",
    sender: "Отправитель",
    tracking_code: "Трэк",
    elapsed_days: "Прошло дней",
    status: "Статус",
    shipment_id: "Отправка",
    carrier_account_id: "Перевозчик",
    company_id: "Компания",
    assigned_location_id: "Склад отправки",
    project_id: "Проект",
    order_source_id: "Источник заказа",
    archived: "Архивирован",
    prepaid: "Предоплатный",
    prepaid_paid: "Оплачен",
    charge_duties_to_shipper: "Таможенные пошлины оплачивает отправитель",
    weight: "Вес",
    weight_kg: "Вес (кг)",
    width: "Ширина",
    height: "Высота",
    length: "Длина",
    payment: "Плата",
    value: "Стоимость",
    inserted_at: "Дата",
    changes_history: "История изменений",
    user: "Пользователь",
    event: "Событие",
    parcel: "Посылка",
    product_title: "Товар",
    quantity: "Количество",
    qty: "Кол-во",
    reference: "Номер",
    test: "Тест",
    type: "Тип",
    format: "Формат",
    location: "Местоположение",
    locations: "Местоположения",
    roles: "Роли",
    size: "Размер",
    actions: "Действия",
    active: "Активен",
    call: "Позвонить",
    call_client: "Позвонить клиенту",
    clone: "Клонировать",
    fulfill: "Собрать",
    cannot_fly:
      "Нельзя отправить по воздуху заказ с запрещёнными к авиа-перевозке товарами",
    edit: "Редактировать",
    delete: "Удалить",
    close: "Закрыть",
    collapse: "Свернуть",
    more: "Ещё",
    generate_batches: "Сформировать партии",
    show_all_orders: "Показывать все заказы",
    print_invoice: "Печать накладной",
    print_label: "Печать ярлыка",
    repeat_label: "Ярлык повторно",
    invoice: "Накладная",
    invoice_invoice: "Инвойс",
    label: "Ярлык",
    one_more_parcel: "Добавить ещё одну посылку",
    fulfilled: "Собран",
    next: "Следующий",
    speech_interface: "Голосовой интерфейс",
    turbo_mode: "Турбо-режим",
    speech_only_chrome:
      "Голосовой интерфейс поддерживается только в браузерах Хром и Андроид ВебВью.",
    cannot_fulfill_fulfilled_order: "Невозможно собрать уже собранный заказ",
    select_company_to_load_rates: "Выберите компанию, чтобы загрузить тариифы",
    label_printer: "Принтер ярылков",
    total: "Всего",
    from: "из",
    orders_fulfilled: "заказов собрано",
    completness_checking: "Проверка комплектности",
    card_on_delivery: "Оплата картой курьеру",
    sms_notice_recipient: "SMS-уведомление получателя",
    use_online_balance: "Использовать онлайн-баланс",
    inspection_of_contents: "Осмотр вложения",
    create_order_2: "Создание заказа 2.0",
    self_label: "Генерация этикетки",
    fulfillment: "Сборка заказов",
    direct_label_load: "Прямая загрузка ярлыка",
    show_map: "Показывать карту",
    autocomplete_region_from_postcode: "Подставлять регион по индексу",
    order_price_from_prepayment: "Сумма наложенного платежа из поля totalSum",
    use_external_id: "Артикул товара из поля externalId",
    map_width: "Ширина карты",
    map_height: "Высота карты",
    map_zoom: "Масштаб карты",
    extra_weight: "Добавочный вес",
    netto_weight: "Вес нетто",
    netto: "Нетто",
    archived: "Архив",
    avia_delivery: "Авиа-доставка",
    no_delivery: "Нет доставки",
    avia_delivery_forever: "Авиа-доставка круглый год.",
    no_delivery_forever: "Нет доставки круглый год (как так?).",
    avia_delivery_till: "Авиа-доставка до %{till}",
    no_delivery_till: "Нет доставки до %{till}",
    delivery_cost: "Плата за доставку",
    enter_postcode_to_view_rates:
      "Введите почтовый индекс, чтобы увидеть тарифы",
    loading: "Идёт загрузка...",
    telegram_notifications_chat: "Чат в Телеграме для уведомлений",
    notify_unfulfilled_phone: "Телефон для уведомлений о несобранных заказах",
    notify_undelivered_phone: "Телефон для уведомлений о неотгруженных заказах",
    dont_buy_shipment: "Не создавать отгрузку",
    link_products: "Привязать товары",
    link_products_with_moy_sklad: "Связать товары с МоимСкладом",
    import: "Импорт",

    update_stock: "Передавать остатки",
    update_prices: "Устанавливать безубыточные цены",
    trusting_acceptance: "Доверительная приёмка",
    containers_count: "Количество мест доверительной приёмки",

    available_products: "Доступные товары",
    available_carriers: "Доступные перевозчики",

    gr: "гр",
    kg: "кг",
    mm: "мм",
    m3: "м³",
    pcs: "шт",
    days: "суток",

    PNG: "PNG",
    PDF: "PDF",
    ZPL: "ZPL",
    EPL: "EPL",
    HTML: "HTML",
    GIF: "GIF",
    SVG: "SVG",

    RussianPost: "Почта России",
    PrivatePost: "Частная Почта",
    CDEK: "СДЭК",
    DPD: "DPD",
    Boxberry: "Boxberry",
    PickPoint: "PickPoint",
    UniExpress: "ЮниЭкспресс",
    DHL: "DHL",
    UPS: "UPS",
    Ozon: "Озон",
    AliExpress: "АлиЭкспресс",
    Wildberries: "Wildberries",

    Letter: "Письмо",
    Banderol: "Бандероль",
    InternationalParcel: "Международная Посылка",
    InternationalParcelAvia: "Международная Посылка Авиа",
    SmallPacket: "Мелкий Пакет",
    Parcel: "Посылка",
    InternationalParcel: "Международная Посылка",
    RetailParcel: "Розничная Посылка",
    FirstClassParcel: "Посылка 1-го класса",
    RetailFirstClassParcel: "Розничная Посылка 1-го класса",
    ParcelOnline: "Посылка Онлайн",
    ParcelOnlineCombined: "Посылка Онлайн Комбинированная",
    CourierOnline: "Курьер Онлайн",
    EMSOptimal: "ЕМС Оптимальное",
    EMSOptimalCourier: "ЕМС Оптимальное c курьером",
    EMS: "ЕМС",
    EMSRT: "ЕМС РТ",
    EMSTender: "ЕМС Тендер",
    ECOM: "ЕКОМ",

    SelfPickup: "Самовывоз",

    cash_on_delivery: "НП",
    ordinary: "",

    domestic: "Внутренние",
    international: "Международные",

    FacilityToDoor: "Склад-Дверь",
    FacilityToFacility: "Склад-Склад",
    DoorToDoor: "Дверь-Дверь",
    DoorToFacility: "Дверь-Склад",
    EconomyFacilityToDoor: "Эконом. Склад-Дверь",
    EconomyFacilityToFacility: "Эконом. Склад-Склад",
    ExpressLightFacilityToDoor: "Экспресс-Лайт Склад-Дверь",
    ExpressLightFacilityToFacility: "Экспресс-Лайт Склад-Склад",

    Optimum: "Optimum",
    OptimumToDoor: "Optimum To Door",

    WorldwideExpress: "Worldwide Express",
    ExpressWorldwide: "Express Worldwide",

    ExpressSaver: "Express Saver",

    PickPoint: "ПВЗ",
    Postamat: "Постамат",

    CHEAPEST: "Дешёвый",
    FASTEST: "Быстрый",

    Courier: "Курьер",

    FBS: "FBS",

    RetailCRM: "RetailCRM",
    Bitrix24: "Битрикс24",
    MoySklad: "МойСклад",

    RU: "Россия",
    BY: "Белоруссия",
    KZ: "Казахстан",
    KG: "Кыргызстан",
    US: "США",
    CN: "Китай",
    UK: "Великобритания",
    TW: "Тайвань",
    IC: "Канарские Острова",

    USD: "Доллар США",
    RUB: "Рубль",
    EUR: "Евро",
    CNY: "Юань",
    USD_sign: "$",
    RUB_sign: "₽",
    EUR_sign: "€",
    CNY_sign: "¥",

    settings_explanation:
      "Выберите одни или несколько весов, показания которых хотите видеть в ЭкстраПочте. А также принтер документов (А4) радио-кнопкой слева и принтер ярлыков (А6) кнопкой справа.",
    order_has_unlinked_products: "В заказе есть непривязанные товары.",
  },
  zh: {
    en: "英语",
    ru: "俄语",
    zh: "中文语",

    extra: "额外",
    post: "邮局",

    extrapost: "额外邮局",
    home: "首页",
    dashboard: "仪表板",
    login: "登录",
    in_one_click_with: "一键式，在",
    or_with_login_and_password: "或使用登录名和密码",
    register: "注册",
    email: "电子邮件",
    password: "密码",
    password_confirm: "重复输入密码",
    email_is_mandatory: "电子邮件是强制性",
    password_is_mandatory: "密码是强制性的",
    password_longer_6: "密码应该超过6个符号",
    password_confirm_is_mandatory: "密码确认是强制性的",
    password_confirm_equal: "密码和密码确认不相等",
    wrong_email_format: "错误的电邮格式",
    sure_to_delete: "你确定要删除这个项目吗？",
    line_item_not_found: "未在此订单中找到带有条形码%{barcode}的产品。",
    enough_line_items:
      'The order already has enough products with barcode "%{barcode}" — %{quantity} pcs.',
    logout: "登出",
    all: "所有",
    dark_theme: "暗黑主题",
    ui_animation: "用户界面动画",
    cancel: "取消",
    save: "保存",
    ooo_extra: '"额外" 公司',
    carriers: "运营商",
    carrier: "运营商",
    service: "服务",
    carrier_account_id: "运营商",
    addresses: "地址",
    companies: "公司",
    shipments: "装运",
    shipment_id: "装运",
    orders: "订单",
    order_sources: "订单来源",
    documents: "文件",
    parcels: "包裹",
    batches: "批量",
    batch_id: "批量",
    pickups: "拾起",
    trackers: "跟踪器",
    products: "制品",
    fulfillment: "订单完成度",
    settings: "设置",
    users: "用户",
    add: "添新",
    add_card: "添新",
    install: "安装",
    uninstall: "卸载",
    search: "搜索",
    fast_search: "快速搜索",
    generate_batches: "产生批次",
    show_all_orders: "显示所有订单",
    company_id: "公司",
    project_id: "项目",
    delivery_cost: "运输成本",
    changes_history: "改变历史",
    archived: "已封存",
    date: "日期",
    zip: "邮政编码",
    country: "国",
    locale: "语言",
    region: "区域",
    city: "市",
    address: "地址",
    street: "地址",
    name: "名字",
    phone: "电话",
    title: "标题",
    price: "价钱",
    currency: "货币",
    value: "价钱",
    receiver: "收货人",
    tracking_code: "跟踪代码",
    status: "状态",
    comment: "按语",
    tag: "标签",
    google_client_id: "Google Client ID",
    reference: "参考编号",
    test: "测试",
    type: "类型",
    format: "格式",
    weight: "重量",
    width: "宽度",
    height: "高度",
    length: "长度",
    payment: "付款",
    product_title: "产品",
    barcode: "条码",
    quantity: "数量",
    actions: "行动",
    fulfill: "履行订单",
    clone: "克隆",
    edit: "编辑",
    delete: "删除",
    delete_selected: "删除所选",
    add_carrier_account: "添加运营商帐户",
    close: "关闭",
    collapse: "折叠",
    lists: "清单",
    more: "更多",
    inserted_at: "日期",
    user: "用户",
    event: "事件",
    carrier_facility: "承运商设施",
    qty: "数量",
    domain: "网络域",
    send_date: "发送日期",

    pcs: "个",
    total: "总金额",
    invoice: "发票",
    label: "标签",
    next: "下一个",
    speech_interface: "语音介面",
    order_fulfillment: "订单完成度",
    fulfilled: "完成",
  },
};

function detectLocale() {
  var locale = VueCookie.get("currentLang");
  if (locale !== null) {
    return locale;
  } else {
    locale = navigator.language.substr(0, 2);
    if (["ru", "en", "zh"].includes(locale)) {
      return locale;
    } else if (
      [
        "uk",
        "be",
        "az",
        "kk",
        "hy",
        "lv",
        "lt",
        "mo",
        "ka",
        "uz",
        "sr",
      ].includes(locale)
    ) {
      return "ru";
    } else if (["ko", "ja", "mn"].includes(locale)) {
      return "zh";
    } else {
      return "en";
    }
  }
}

function countryName(iso, locale) {
  return countries.getName(iso, locale || i18n.locale);
}

function countriesSelectList() {
  var names = countries.getNames(i18n.locale);
  // Add some territories manually,
  // Cuz they are not separate countries, but needed for delivery
  names["IC"] = i18n.t("IC");
  return Object.keys(names).map((key) => {
    if (["RU", "TW", "CN"].includes(key) && i18n.locale === "ru")
      // Shorten some countries names
      return { text: i18n.t(key), value: key };
    else return { text: names[key], value: key };
  });
}

const i18n = new VueI18n({
  locale: detectLocale(), // set locale from browser settings
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
export { detectLocale, countriesSelectList, countryName };
