<template>
  <extra-table
    ref="ordersTable"
    :title="$t('orders')"
    entity="orders"
    :companiesTabs="true"
    :fields="fields"
    :sortBy="null"
    :exportFunction="exportSelected"
    :defaultItem="defaultOrder"
    :rowClass="
      (order) => {
        if (order.archived) return 'grey--text';
        else if (order.shipments && order.shipments.length > 0)
          return 'shipped';
        else if (order.priority > 0) return 'priority';
        else return '';
      }
    "
    search
    v-on:extra-table:loaded="
      $event > 0 ? loadPreBatches(companies[$event - 1].id) : ''
    "
  >
    <!-- <template v-slot:editForm="props">
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md6 key="price" pr-4 pl-0>
            <v-text-field
              @input="props.item.price = e.value"
              :value="props.item.price / 100"
              :label="$t('price')"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </template>-->
    <template v-slot:header="{ selected }">
      <v-menu
        offset-y
        v-if="$children[0].company_num > 0 && isAdminOrOperator()"
        :close-on-content-click="false"
        :transition="uiAnimation ? 'scale-transition' : 'none'"
        :nudge-width="60"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            small
            v-on="on"
            :disabled="pre_batches.length == 0"
          >
            {{ $t("generate_batches") }} ({{
              pre_batches.reduce((total, b) => {
                return total + b.quantity;
              }, 0)
            }})
            <v-icon right dark>arrow_drop_down</v-icon>
          </v-btn>
          <input
            ref="import_file"
            type="file"
            v-on:change="importFileUploaded($event)"
            hidden
            multiple
            accept=".csv, .txt, .xls, .xlsx, .zip"
          />
          <!-- <v-btn small depressed @click="$refs.import_file.click()">{{
            $t("import")
          }}</v-btn> -->
          <v-btn small depressed @click="startFulfillment()">
            <v-icon left dark>all_inclusive</v-icon>
            {{ $t("start_fulfillment") }}</v-btn
          >
          <v-btn small depressed @click="linkProducts()">{{
            $t("link_products")
          }}</v-btn>
          <!-- <v-btn small depressed @click="linkProductsWithMoySklad()">{{
            $t("link_products_with_moy_sklad")
          }}</v-btn> -->
        </template>
        <v-card class="pt-2">
          <v-list>
            <v-list-tile
              v-for="(item, index) in pre_batches"
              :key="index"
              @click="generateBatch(item)"
            >
              <v-list-tile-title>
                <carrier-icon :carrier="item.carrier" />
                {{ $t(item.service || item.carrier) }}
                ({{ item.quantity }})
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-menu>

      <v-checkbox
        class="mt-4"
        v-model="showAll"
        :label="$t('show_all_orders')"
      ></v-checkbox>

      <v-btn
        v-if="selected.length > 0"
        :loading="fulfilling_multiple"
        small
        depressed
        @click="fulfillSelected(selected)"
      >
        {{ $t("fulfill") }}
      </v-btn>

      <v-dialog
        v-model="productsDialog"
        fullscreen
        v-if="selected.length > 0"
        :transition="uiAnimation ? 'slide-x-transition' : 'none'"
      >
        <template v-slot:activator="{ on }">
          <v-btn small depressed v-on="on">{{ $t("products") }}</v-btn>
        </template>
        <v-card
          ><v-toolbar dark color="primary">
            <v-btn icon dark @click="productsDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("products_in_orders") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark flat @click="exportProductsInOrders(selected)"
                ><o-icon>exit_to_app</o-icon>&nbsp;{{ $t("export") }}</v-btn
              >
              <v-btn dark flat @click="printProductsInOrders()"
                ><o-icon>print</o-icon>&nbsp;{{ $t("print") }}</v-btn
              >
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :headers="[
              { text: $t('sku'), value: 'sku' },
              { text: $t('title'), value: 'title' },
              { text: $t('slot_address'), value: 'slot' },
              { text: $t('quantity'), value: 'quantity' },
            ]"
            :items="productsInOrders(selected)"
            :rows-per-page-items="[10, 20, 50]"
          >
            <template v-slot:items="{ item }">
              <td>{{ item.sku }}</td>
              <td>
                {{ item.title }}
                <div v-if="item.comment" class="caption grey--text">
                  {{ item.comment }}
                </div>
              </td>
              <td>{{ item.slot }}</td>
              <td>{{ item.quantity }}</td>
            </template>
          </v-data-table>
        </v-card>

        <div id="products_in_orders_for_print">
          <h3>Товары в заказах</h3>
          <table>
            <tr
              v-for="product in productsInOrders(selected)"
              :key="product.sku"
            >
              <td>{{ product.sku }}</td>
              <td>
                {{ product.title }}
                <i v-if="product.comment" class="caption grey--text">
                  {{ product.comment }}
                </i>
              </td>
              <td>{{ product.quantity }}</td>
              <td>{{ product.price }}</td>
            </tr>
          </table>
        </div>
      </v-dialog>
    </template>

    <template v-slot:editForm="props">
      <extra-order :order="props.item" :errors="props.errors"></extra-order>
    </template>

    <template v-slot:actions="props">
      <o-icon
        v-if="
          props.item.to_address.phone && props.item.to_address.country === 'RU'
        "
        small
        class="mr-2"
        @click="callClient(props.item)"
        :title="$t('call') + clientLocalTime(props.item.to_address)"
        >phone</o-icon
      >

      <template v-if="!isFulfilled(props.item) && isAdminOrOperator()">
        <v-tooltip left transition="none" open-delay="150">
          <template #activator="{ on }">
            <o-icon
              v-if="isOutsourceCarrier(props.item.carrier)"
              :on="on"
              small
              class="mr-2"
              @click="outsourceOrder(props.item)"
              :title="$t('fulfill')"
              >open_in_browser</o-icon
            >

            <o-icon
              v-else-if="!props.item.archived"
              :on="on"
              :disabled="cannotFly(props.item)"
              small
              class="mr-2"
              :href="`/fulfillment/${props.item.id}`"
              @click="fulfillOrder(props.item)"
              @click_middle="fulfillOrderInNewWindow(props.item)"
              :title="cannotFly(props.item) ? $t('cannot_fly') : $t('fulfill')"
              >all_inclusive</o-icon
            >
          </template>
          <table class="order-contents-tooltip">
            <tr v-for="(li, index) in props.item.line_items" :key="index">
              <td>[{{ li.product_sku }}]&nbsp;&nbsp;</td>
              <td>{{ li.product_title }}</td>
              <td>
                <v-icon small dark>close</v-icon>
              </td>
              <td>{{ li.quantity }}&nbsp;{{ $t("pcs") }}</td>
            </tr>
            <tfoot>
              <tr>
                <td></td>
                <td>
                  <strong>{{ $t("total") }}</strong>
                </td>
                <td></td>
                <td>
                  <strong>
                    {{
                      props.item.line_items.reduce((acc, li) => {
                        return acc + li.quantity;
                      }, 0)
                    }}&nbsp;{{ $t("pcs") }}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </v-tooltip>
      </template>
      <o-icon
        v-if="isAdmin()"
        small
        class="mr-2"
        @click="cloneOrder(props.item)"
        :title="$t('clone')"
        >file_copy</o-icon
      >
    </template>
  </extra-table>
</template>

<script>
import Vue from "vue";
import Moment from "moment-timezone";
import { mapState } from "vuex";
import ExtraTable from "./ExtraTable.vue";
import Order from "./Order.vue";
import ExtraPostAPI from "../api.js";
import ExtraPostNodes from "../nodes.js";
import OIcon from "./OIcon.vue";
import CarrierIcon from "./CarrierIcon.vue";
import {
  carrierImageTag,
  countriesList,
  carriersList,
  loadingIconOn,
  loadingIconOff,
  orderWarningsIcon,
  cityWithFlag,
  currentUser,
  globalSettings,
  callClient,
  isAdmin,
  isOperator,
  isStoreowner,
  deliveryLimitIcon,
  cannotFly,
  trackerStatusIcon,
} from "../functions.js";
import printJS from "print-js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("orders") };
  },
  components: {
    "extra-table": ExtraTable,
    "extra-order": Order,
    OIcon,
    CarrierIcon,
  },
  data() {
    return {
      fields: [
        {
          name: "reference",
          sortable: true,
          value: (order) => {
            return (
              trackerStatusIcon(
                order.shipments[0] && order.shipments[0].trackers[0].status
              ) +
              " " +
              order.reference +
              orderWarningsIcon(order, this)
            );
          },
        },
        {
          name: "carrier",
          value: (order) => {
            return (
              carrierImageTag(order.carrier) + " " + this.$i18n.t(order.service)
            );
          },
          editable: false,
        },
        {
          name: "price",
          sortable: true,
          align: "right",
          order_price_tooltip: true,
        },
        { name: "currency", column: false, list: ["RUB", "USD", "EUR", "CNY"] },
        { name: "to_address.country", column: false, list: countriesList() },
        { name: "to_address.zip", column: false },
        { name: "to_address.region", column: false },
        {
          name: "to_address.city",
          value: (order) => {
            return cityWithFlag(order.to_address) + deliveryLimitIcon(order);
          },
        },
        {
          name: "to_address.name",
          order_tooltip: true,
        },
        { name: "to_address.phone", column: false },
        { name: "to_address.email", column: false },
        { name: "to_address.street", column: false },
        { name: "comment", column: false },
        { name: "tag", column: false },
        { name: "google_client_id", column: false },
        { name: "carrier", column: false, list: carriersList() },
        { name: "service", column: false },
        {
          name: "company_id",
          value: (order) => {
            return order.company ? order.company.title : "";
          },
          list: this.$store.state.companies.map((c) => {
            return { text: c.title, value: c.id };
          }),
          column: false,
        },
        {
          name: "project_id",
          value: (order) => {
            return order.project_id ? order.project.title : "";
          },
        },
        {
          name: "line_items",
          column: false,
          fields: [
            { name: "product_sku" },
            { name: "product.title" },
            { name: "quantity" },
            { name: "price" },
          ],
        },
      ],
      dialog: null,
      productsDialog: false,
      on: false,
      pre_batches: [],
      orderContents: null,
      fulfilling_multiple: false,
      showAll: false,
      batchMenu: false,
      defaultOrder: {
        to_address: { country: "RU" },
        currency: "RUB",
        extra: {},
        line_items: [],
      },
    };
  },
  computed: {
    ...mapState(["uiAnimation", "companies", "currentUser"]),
  },
  watch: {
    showAll: function () {
      var newQuery = Object.assign(_.clone(this.$route.query || {}), {
        show_all: this.showAll,
      });
      delete newQuery.company_id;

      this.$router.replace({
        query: newQuery,
      });
      this.$refs.ordersTable.loadData({ show_all: this.showAll });
    },
    "$store.state.currentUser": function () {
      this.loadUserDefaults();
    },
  },
  mounted() {
    if (this.$route.params.company_id !== undefined)
      this.loadPreBatches(this.$route.params.company_id);

    this.showAll = this.$route.query.show_all === "true";
  },
  beforeUpdate() {
    this.loadUserDefaults();
  },
  methods: {
    loadUserDefaults() {
      if (this.currentUser) {
        this.defaultOrder.currency = this.currentUser.extra.default_currency;
        this.defaultOrder.to_address.country =
          this.currentUser.extra.default_country;
        this.defaultOrder.project_id =
          this.currentUser.extra.default_project_id;
        this.defaultOrder.company_id =
          this.currentUser.extra.default_company_id;
        this.defaultOrder.order_source_id =
          this.currentUser.extra.default_order_source_id;
      }
    },
    isAdmin() {
      return isAdmin(this);
    },
    isAdminOrOperator() {
      return isAdmin(this) || isOperator(this);
    },
    cannotFly(order) {
      return cannotFly(order);
    },
    startFulfillment() {
      var company_id = this.$route.params.company_id;
      var self = this;

      ExtraPostAPI.get("fulfillment/next_order?company_id=" + company_id)
        .then((response) => {
          loadingIconOff(self.$root);
          console.log(response);
          var order_id = response.data.next_order_id;
          if (order_id !== null)
            self.$router.push({ path: `/fulfillment/${order_id}` });
          else
            self.$root.$emit(
              "snack-message",
              self.$i18n.t("no_more_orders_to_fulfill")
            );
        })
        .catch((error) => {
          console.log(error.response);
          self.$root.$emit(
            "snack-message",
            error.response.statusText +
              ": " +
              (error.response.data["error"] ||
                JSON.stringify(error.response.data["errors"]) ||
                error.response.data ||
                ""),
            "error"
          );
        });
    },
    fulfillOrder(order) {
      this.$router.push("/fulfillment/" + order.id);
      console.log(order);
    },
    fulfillOrderInNewWindow(order) {
      window.open(`/fulfillment/${order.id}`, "_blank");
      // this.$router.push("/fulfillment/" + order.id);
    },
    isOutsourceCarrier(carrier) {
      return ["UniExpress"].includes(carrier);
    },
    outsourceOrder(order) {
      var self = this;
      this.$root.$children[0].loadingDialog = true;
      ExtraPostAPI.post("fulfill/" + order.id)
        .then((response) => {
          this.$root.$children[0].loadingDialog = false;
          console.log(response);
          var shipment = response.data;
          order.shipments.push(shipment);
        })
        .catch((error) => {
          console.log(error.response);
          self.$root.$emit(
            "snack-message",
            error.response.data["error"] || error.response.statusText,
            "error"
          );
          this.$root.$children[0].loadingDialog = false;
        });
    },
    isFulfilled(order) {
      return order.shipments.length > 0;
    },
    cloneOrder(order) {
      var self = this;
      ExtraPostAPI.post("orders/" + order.id + "/clone").catch((error) => {
        self.$root.$emit("snack-message", error.response.data, "error");
      });
    },
    showOrderContents(order) {
      console.log();
      this.orderContents = order;
    },
    hideOrderContents() {
      this.orderContents = null;
    },
    loadPreBatches(company_id) {
      if (company_id !== undefined) {
        var self = this;

        var url = "batches/pre_batches?company_id=" + company_id;
        if (this.currentUser && this.currentUser.extra.warehouse)
          url += "&warehouse_id=" + this.currentUser.extra.warehouse;

        ExtraPostAPI.get(url).then((response) => {
          self.pre_batches = response.data;
        });
      }
    },
    generateBatch(pre_batch) {
      loadingIconOn(this.$root);

      const PAPERLESS_CARRIERS = ["YandexMarket"];
      const CARRIERS_WITH_CONTAINERS = ["Ozon"];

      var batch_params = {
        company_id: pre_batch.company_id,
        carrier_account_id: pre_batch.carrier_account_id,
        carrier: pre_batch.carrier,
        service: pre_batch.service,
      };

      if (CARRIERS_WITH_CONTAINERS.includes(pre_batch.carrier)) {
        // Request number of containers
        var result = window.prompt(this.$i18n.t("enter_number_of_containers"));
        if (result) {
          const containers_count = Number(result);
          batch_params.containers_count = containers_count;
        } else {
          loadingIconOff(this.$root);
          return;
        }
      }

      var self = this;
      ExtraPostAPI.post("batches/autocreate", batch_params)
        .then((response) => {
          console.log(response);
          var batch = response.data;
          if (
            batch.document_id &&
            !PAPERLESS_CARRIERS.includes(pre_batch.carrier)
          ) {
            if (
              ["ZPL", "EPL"].includes(batch.document_format) ||
              batch.document_type === "label"
            )
              ExtraPostNodes.printLabel(batch.document_id);
            else ExtraPostNodes.printDocument(batch.document_id);
          }
          self.$root.$emit(
            "snack-message",
            "Batch " + batch.reference + " successfully created.",
            "success"
          );
        })
        .then(() => {
          loadingIconOff(self.$root);
          self.loadPreBatches(pre_batch.company_id);
        })
        .catch((error) => {
          console.log(error);
          loadingIconOff(self.$root);
          self.$root.$emit(
            "snack-message",
            error.response.data.error || error.response.data,
            "error"
          );
        });
    },
    linkProducts() {
      loadingIconOn(this.$root);

      var company_id = this.$route.params.company_id;
      var self = this;
      ExtraPostAPI.post(`companies/${company_id}/link_products`, {})
        .then((response) => {
          console.log(response);
          self.$root.$emit(
            "snack-message",
            `${response.data.linked_products_count} products linked.`,
            "success"
          );
        })
        .then(() => {
          loadingIconOff(self.$root);
        })
        .catch((error) => {
          console.log(error);
          loadingIconOff(self.$root);
          self.$root.$emit(
            "snack-message",
            error.response.data.error || error.response.data,
            "error"
          );
        });
    },
    linkProductsWithMoySklad() {
      loadingIconOn(this.$root);

      var company_id = this.$route.params.company_id;
      var self = this;
      ExtraPostAPI.post(
        `companies/${company_id}/link_products_with_moy_sklad`,
        {}
      )
        .then((response) => {
          console.log(response);
          self.$root.$emit(
            "snack-message",
            `${response.data.linked_products_count} products linked.`,
            "success"
          );
        })
        .then(() => {
          loadingIconOff(self.$root);
        })
        .catch((error) => {
          console.log(error);
          loadingIconOff(self.$root);
          self.$root.$emit(
            "snack-message",
            error.response.data.error || error.response.data,
            "error"
          );
        });
    },
    exportSelected(selected) {
      let csv =
        "data:text/csv;charset=utf-8,ID,REFERENCE,DATE,CARRIER_SERVICE,PRICE,CURRENCY,COUNTRY,CITY,STREET,NAME,PHONE,COMPANY,PRODUCTS,TRACKING_CODE,COMMENT\n" +
        selected
          .map(
            (e) =>
              `${e.id},${e.reference},${e.inserted_at},${e.carrier || ""}:${
                e.service || ""
              },${e.price / 100.0},${e.currency},${e.to_address.country},${
                e.to_address.city
              },"${e.to_address.street}",${e.to_address.name},${
                e.to_address.phone
              },${e.company.title},${e.line_items.reduce(
                (total, li) =>
                  total +
                  `${li.product_title}:${li.quantity}:${li.price / 100.0}${
                    li.currency
                  };`,
                ""
              )},${(e.shipments[0] && e.shipments[0].tracking_code) || ""},"${
                e.comment && e.comment.replace(/[\n"]/gi, "")
              }"`
          )
          .join("\n");

      window.open(encodeURI(csv));
    },
    callClient(order) {
      var self = this;
      callClient(this, order).catch((error) => {
        console.log(error);
        self.$root.$emit(
          "snack-message",
          error.response.data.error || error.response.data,
          "error"
        );
      });
    },
    clientLocalTime(address) {
      if (address.extra["time_zone"])
        return `, у клиента сейчас ${Moment()
          .utcOffset(address.extra["time_zone"] * 60)
          .format("HH:mm")}`;
      else return "";
    },
    productsInOrders(orders) {
      var productsMap = orders.reduce(function (r, o) {
        o.line_items.forEach((li) => {
          var sku = (li.product && li.product.skus) || li.product_sku;
          r[sku] = r[sku] || {
            sku: sku,
            title: (li.product && li.product.title) || li.product_title,
            slot: li.product && li.product.extra["slot"],
            quantity: 0,
            price: 0,
            comment: li.product && li.product.comment,
          };
          r[sku].quantity += li.quantity;
          r[sku].price += li.price;
        });
        return r;
      }, Object.create(null));

      return Object.values(productsMap).sort((a, b) => {
        return b.quantity - a.quantity;
      });
    },
    printProductsInOrders() {
      printJS("products_in_orders_for_print", "html");
    },
    exportProductsInOrders(orders) {
      let csv =
        "data:text/csv;charset=utf-8,SKU,TITLE,QUANTITY,COST\n" +
        this.productsInOrders(orders)
          .map((p) => `"${p.sku}","${p.title}",${p.quantity},${p.price}`)
          .join("\n");

      window.open(encodeURI(csv));
    },
    importFileUploaded(event) {
      var self = this;
      var company_id = this.$route.query.company_id;
      loadingIconOn(this.$root);

      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var reader = new FileReader();

        reader.onload = function (event) {
          ExtraPostAPI.post("orders/import", {
            file: event.target.result,
            company_id: company_id,
            file_name: file.name,
          });
        };

        reader.readAsDataURL(file);
      }
      loadingIconOff(this.$root);
      event.target.value = ""; // Reset file loader, so we can load same file again
    },
    fulfillSelected(selected) {
      const weight = Number(
        window.prompt(this.$i18n.t("enter_weight_in_gramms"))
      );
      console.log(weight);

      var order_ids = selected.map((o) => {
        return o.id;
      });

      this.fulfilling_multiple = true;
      var self = this;

      ExtraPostAPI.post("fulfill_multiple", {
        order_ids: order_ids,
        weight: weight,
      })
        .then((response) => {
          self.fulfilling_multiple = false;
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
          var errorMessage =
            error.response.data["error"] ||
            JSON.stringify(error.response.data["errors"]) ||
            "";
          self.$root.$emit(
            "snack-message",
            error.response.statusText + ": " + errorMessage,
            "error"
          );
        });
    },
  },
};
</script>

<style lang="scss">
tr.shipped {
  background-color: palegreen;
}

.theme--dark tr.shipped {
  background-color: darkolivegreen;
}

tr.priority {
  background: repeating-linear-gradient(
    45deg,
    #bca66088,
    #bca66088 12px,
    #98464688 12px,
    #98464688 24px
  );
  background-blend-mode: difference;
}

.column-reference .v-icon {
  font-size: 18px;
}

.order-contents-tooltip {
  font-size: 110%;
}

#products_in_orders_for_print {
  z-index: -100;
  position: fixed;
  padding: 5em;
  font-family: "Sans Serif";
}

a.place,
a.name {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

a:hover.place,
a:hover.name {
  color: #00bbff;
}

.grey--text a,
.grey--text a.place {
  color: rgb(158, 158, 158) !important;
}

.theme--dark a.place,
.theme--dark a.name {
  color: white;
}
</style>
