<template>
  <extra-table :title="$t('users')" entity="users" :fields="fields">
    <template v-slot:editForm="props">
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md6 pr-4 pl-0>
            <v-text-field
              :value="props.item.name"
              @input="(e) => (props.item.name = e)"
              :label="$t('name')"
            ></v-text-field>
            <v-text-field
              :value="props.item.password"
              @input="(e) => (props.item.password = e)"
              :label="$t('password')"
              :append-icon="show_password ? 'visibility' : 'visibility_off'"
              :rules="[rules.min]"
              :type="show_password ? 'text' : 'password'"
              hint="At least 6 characters"
              counter
              @click:append="show_password = !show_password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md6 pr-4 pl-0>
            <v-text-field
              :value="props.item.email"
              @input="(e) => (props.item.email = e)"
              :label="$t('email')"
            ></v-text-field>
            <v-select
              :value="props.item.locale"
              :items="['en', 'ru', 'zh']"
              @input="(e) => (props.item.locale = e)"
              :label="$t('locale')"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md6 pr-4 pl-0>
            <v-select
              :value="(props.item.extra && props.item.extra.locations) || []"
              @input="(e) => (props.item.extra.locations = e)"
              :items="
                locations.map((l) => {
                  return { text: l.reference, value: l.id };
                })
              "
              attach
              chips
              :label="$t('locations')"
              multiple
              hide-details
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md6 pr-4 pl-0>
            <v-select
              v-model="props.item.roles"
              :items="roles"
              attach
              chips
              :label="$t('roles')"
              multiple
              hide-details
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12 pr-4 pl-0>
            <v-autocomplete
              :value="
                (props.item.extra && props.item.extra.available_products) || []
              "
              @input="(e) => (props.item.extra.available_products = e)"
              :items="
                products.map((p) => {
                  return { text: p.title, value: p.id };
                })
              "
              attach
              chips
              :label="$t('available_products')"
              multiple
              hide-details
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 sm6 md6 pr-4 pl-0>
            <v-select
              :value="
                (props.item.extra && props.item.extra.available_carriers) || []
              "
              @input="(e) => (props.item.extra.available_carriers = e)"
              :items="
                carriersList().map((c) => {
                  return { text: $t(c), value: c };
                })
              "
              attach
              chips
              :label="$t('available_carriers')"
              multiple
              hide-details
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </extra-table>
</template>

<script>
import ExtraTable from "./ExtraTable.vue";
import { mapState } from "vuex";
import { carriersList } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("users") };
  },
  components: { "extra-table": ExtraTable },
  data() {
    return {
      fields: [
        { name: "name" },
        { name: "email" },
        {
          name: "locale",
          list: ["en", "ru", "zh"],
          default: this.$i18n.locale,
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        // min: v => v && v.length >= 6 || 'Min 6 characters'
        min: (v) => true,
      },
      show_password: false,
      roles: [
        { text: "Store Owner", value: "storeowner" },
        { text: "Operator", value: "operator" },
        { text: "Admin", value: "admin" },
      ],
    };
  },
  computed: {
    ...mapState(["locations"]),
    ...mapState(["products"]),
  },
  methods: {
    carriersList,
  },
};
</script>
