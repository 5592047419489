import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Login from "./components/Login.vue";
import StoreToken from "./components/StoreToken.vue";
import Dashboard from "./components/Dashboard.vue";
import CarrierAccounts from "./components/CarrierAccounts.vue";
import Addresses from "./components/Addresses.vue";
import Companies from "./components/Companies.vue";
import Shipments from "./components/Shipments.vue";
import ShipmentPage from "./components/ShipmentPage.vue";
import ReRegisterShipment from "./components/ReRegisterShipment.vue";
import Orders from "./components/Orders.vue";
import OrderPage from "./components/OrderPage.vue";
import Parcels from "./components/Parcels.vue";
import Documents from "./components/Documents.vue";
import Products from "./components/Products.vue";
import Batches from "./components/Batches.vue";
import Pickups from "./components/Pickups.vue";
import Trackers from "./components/Trackers.vue";
import Fulfillment from "./components/Fulfillment.vue";
import Settings from "./components/Settings.vue";
import Users from "./components/Users.vue";
import OrderSources from "./components/OrderSources.vue";
import Install from "./components/Install.vue";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Root",
      redirect: (to) => {
        if (localStorage.getItem("extrapost_api_login")) {
          return "/orders";
        } else {
          return { name: "Login" };
        }
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/store_token",
      name: "StoreToken",
      component: StoreToken,
    },
    {
      path: "/install",
      name: "Install",
      component: Install,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/carrier_accounts",
      component: CarrierAccounts,
    },
    {
      path: "/companies",
      component: Companies,
    },
    {
      path: "/addresses",
      component: Addresses,
    },
    {
      path: "/shipments",
      component: Shipments,
    },
    {
      path: "/shipments/comp/:company_id",
      component: Shipments,
    },
    {
      path: "/shipments/reregister",
      component: ReRegisterShipment,
    },
    {
      path: "/shipments/:shipment_id",
      component: ShipmentPage,
    },
    {
      path: "/orders",
      component: Orders,
    },
    {
      path: "/orders/comp/:company_id",
      component: Orders,
    },
    {
      path: "/orders/:order_id",
      component: OrderPage,
    },
    {
      path: "/parcels",
      component: Parcels,
    },
    {
      path: "/documents",
      component: Documents,
    },
    {
      path: "/products",
      component: Products,
    },
    {
      path: "/batches",
      component: Batches,
    },
    {
      path: "/pickups",
      component: Pickups,
    },
    {
      path: "/trackers",
      component: Trackers,
    },
    {
      path: "/fulfillment/:order_id",
      component: Fulfillment,
    },
    {
      path: "/fulfillment",
      component: Fulfillment,
    },
    {
      path: "/settings",
      component: Settings,
    },
    {
      path: "/users",
      component: Users,
    },
    {
      path: "/order_sources",
      component: OrderSources,
    },
  ],
});
