<template>
  <extra-table
    :title="$t('addresses')"
    entity="addresses"
    :fields="fields"
    search
  >
    <template v-slot:editForm="props">
      <extra-address :address="props.item"></extra-address>
    </template>
  </extra-table>
</template>

<script>
import ExtraTable from "./ExtraTable.vue";
import ExtraPostAPI from "../api.js";
// import Tabulator from 'tabulator-tables';
import Address from "./Address.vue";
import { countriesList } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("addresses") };
  },
  components: { "extra-table": ExtraTable, "extra-address": Address },
  data() {
    return {
      fields: [
        { name: "zip" },
        {
          name: "country",
          value: (addr) => {
            return this.$i18n.t(addr.country);
          },
          list: countriesList(),
        },
        { name: "region", column: false },
        { name: "city" },
        { name: "street" },
        { name: "name" },
        { name: "phone", column: false },
        { name: "email", column: false },
        { name: "carrier_facility", column: false },
      ],
      table: null,
    };
  },
  mounted() {
    var self = this;
    ExtraPostAPI.get("addresses").then((response) => {
      console.log(response.data);
      self.table = new Tabulator("#tabulator", {
        data: response.data.addresses,
        autoColumns: true,
      });
    });
  },
};
</script>

<style>
@import "https://unpkg.com/tabulator-tables@4.9.1/dist/css/tabulator.min.css";
</style>
