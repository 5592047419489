<template>
  <v-layout row wrap>
    <v-flex
      v-for="field in [
        'apiKey',
        'domain',
        'map_height',
        'map_width',
        'map_zoom',
        'extra_weight',
      ]"
      :key="field"
      xs12
      sm6
      md6
      pr-4
      pl-0
    >
      <v-text-field
        v-model="$attrs.parameters[field]"
        :label="$t(field)"
      ></v-text-field>
    </v-flex>

    <v-checkbox
      v-for="service in [
        'fulfillment',
        'show_map',
        'autocomplete_region_from_postcode',
        'use_external_id',
        'order_price_from_prepayment',
      ]"
      :key="service"
      v-model="$attrs.parameters[service]"
      :label="$t(service)"
      hide-details
    ></v-checkbox>
  </v-layout>
</template>
<script>
import Vue from "vue";

export default Vue.component("retail-crm", {
  data() {
    return {};
  },
});
</script>
