import i18n from "./plugins/i18n";
import Moment from "moment-timezone";
import { countryName } from "./plugins/i18n";
import ExtraPostAPI from "./api";

function carrierImageTag(carrier) {
  if (carrier === undefined) return "";
  else
    return (
      "<img width='25px' style='vertical-align: middle;' title='" +
      i18n.t(carrier) +
      "'" +
      " src='/img/logos/" +
      carrier +
      ".svg" +
      "' />"
    );
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function isLoggedIn() {
  return localStorage.getItem("extrapost_api_access_token") !== null;
}

function login() {
  return localStorage.getItem("extrapost_api_login");
}

function countryFlag(country) {
  if (country) {
    return (
      '<span class="flag-icon flag-icon-' +
      country.toLowerCase() +
      '" title="' +
      countryName(country) +
      '"></span>'
    );
  } else {
    return "";
  }
}

function addressForQuery(address) {
  return [
    countryName(address.country, "en"),
    address.zip,
    address.region,
    address.city,
    address.street,
  ]
    .filter((s) => s && s.length > 0)
    .join("+")
    .replace(/\s\s+/g, "+");
}

function cannotFly(order) {
  const delivery_limit = order.to_address.delivery_limit;
  return (
    delivery_limit &&
    delivery_limit.delivery === "air" &&
    hasAviaForbiddenProducts(order)
  );
}

function deliveryLimitIcon(order, big = false) {
  const delivery_limit = order.to_address.delivery_limit;
  const air_delivery_color = hasAviaForbiddenProducts(order)
    ? "red--text"
    : "primary--text";
  const air_delivery_icon = hasAviaForbiddenProducts(order)
    ? "airplanemode_inactive"
    : "flight";
  if (delivery_limit) {
    if (delivery_limit.delivery === "air") {
      return ` <i title='${tillText(
        "avia_delivery",
        delivery_limit.till
      )}' class='v-icon material-icons-outlined ${air_delivery_color}' style='font-size: ${
        big ? 22 : 16
      }px;'>${air_delivery_icon}</i>`;
    } else if (delivery_limit.delivery === "forbidden") {
      return ` <i title='${tillText(
        "no_delivery",
        delivery_limit.till
      )}' class='v-icon material-icons-outlined red--text' style='font-size: ${
        big ? 22 : 16
      }px;'>block</i>`;
    }
  } else {
    return "";
  }
}

function tillText(text, till) {
  if (till === "2000-12-31") {
    // This is forever
    return i18n.t(text + "_forever");
  } else {
    return i18n.t(text + "_till", { till: Moment.utc(till).format("DD MMMM") });
  }
}

function hasAviaForbiddenProducts(order) {
  return order.line_items.some((li) => {
    return li.product && li.product.extra.avia_forbidden;
  });
}

function trackerStatusIcon(status) {
  var icon = "";
  var color = "";
  switch (status) {
    case "error":
      icon = "error";
      color = "error";
      break;
    case "undefined":
    case "unknown":
      icon = "help_outline";
      color = "warning";
      break;
    case "donottrack":
    case "donttrack":
      icon = "cancel";
      color = "";
      break;
    case "pre_transit":
      icon = "beenhere";
      color = "warning";
      break;
    case "sent":
      icon = "local_shipping";
      color = "primary";
      break;
    case "in_transit":
      icon = "local_shipping";
      color = "primary";
      break;
    case "available_for_pickup":
      icon = "call_received";
      color = "primary";
      break;
    case "out_for_delivery":
      icon = "directions_run";
      color = "primary";
      break;
    case "return_to_sender":
      icon = "call_missed";
      color = "warning";
      break;
    case "returned":
      icon = "call_missed";
      color = "primary";
      break;
    case "delivered":
      icon = "done";
      color = "success";
      break;
    case "abandoned":
      icon = "delete";
      color = "warning";
      break;

    case undefined:
      return "";

    default:
      icon = status;
    // case 'created':
    // case 'ready_for_fulfillment':
    // case 'fulfilled':
  }
  return (
    "<i class='v-icon material-icons-outlined " +
    color +
    "--text' title='" +
    status +
    "'>" +
    icon +
    "</i>"
  );
}

function orderWarningsIcon(order, component) {
  var company = getCompanyById(component, order.company_id);

  if (company.extra["moy_sklad_access_token"]) {
    if (order.extra["moy_sklad_customerorder_id"]) return "";
    else
      return "<i class='v-icon material-icons-outlined red--text' style='font-size: 18px;' title='Отсутствует связанный заказ в МоёмСкладе'>priority_high</i>";
  } else {
    return "";
  }
}

function shipmentWarningsIcon(shipment, component) {
  if (shipment.order) {
    var company = getCompanyById(component, shipment.order.company_id);

    if (company.extra["moy_sklad_access_token"]) {
      if (shipment.extra["moy_sklad_demand_id"]) return "";
      else
        return "<i class='v-icon material-icons-outlined red--text' style='font-size: 18px;' title='Отсутствует связанная отгрузка в МоёмСкладе'>priority_high</i>";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function cityWithFlag(address) {
  if (address.city) {
    return (
      (address.country === "RU" ? "" : countryFlag(address.country) + " ") +
      '<a class="place" title="' +
      fullAddress(address) +
      '" href="https://maps.google.com/maps/search/' +
      addressForQuery(address) +
      '" target="_blank">' +
      address.city +
      "</a>"
    );
  } else {
    return "";
  }
}

function countriesList() {
  return ["RU", "US", "CN", "UK"];
  // return [
  //   "AF",
  //   "AX",
  //   "AL",
  //   "DZ",
  //   "AS",
  //   "AD",
  //   "AO",
  //   "AI",
  //   "AQ",
  //   "AG",
  //   "AR",
  //   "AM",
  //   "AW",
  //   "AU",
  //   "AT",
  //   "AZ",
  //   "BS",
  //   "BH",
  //   "BD",
  //   "BB",
  //   "BY",
  //   "BE",
  //   "BZ",
  //   "BJ",
  //   "BM",
  //   "BT",
  //   "BO",
  //   "BQ",
  //   "BA",
  //   "BW",
  //   "BV",
  //   "BR",
  //   "IO",
  //   "BN",
  //   "BG",
  //   "BF",
  //   "BI",
  //   "CV",
  //   "KH",
  //   "CM",
  //   "CA",
  //   "KY",
  //   "CF",
  //   "TD",
  //   "CL",
  //   "CN",
  //   "CX",
  //   "CC",
  //   "CO",
  //   "KM",
  //   "CG",
  //   "CD",
  //   "CK",
  //   "CR",
  //   "CI",
  //   "HR",
  //   "CU",
  //   "CW",
  //   "CY",
  //   "CZ",
  //   "DK",
  //   "DJ",
  //   "DM",
  //   "DO",
  //   "EC",
  //   "EG",
  //   "SV",
  //   "GQ",
  //   "ER",
  //   "EE",
  //   "ET",
  //   "FK",
  //   "FO",
  //   "FJ",
  //   "FI",
  //   "FR",
  //   "GF",
  //   "PF",
  //   "TF",
  //   "GA",
  //   "GM",
  //   "GE",
  //   "DE",
  //   "GH",
  //   "GI",
  //   "GR",
  //   "GL",
  //   "GD",
  //   "GP",
  //   "GU",
  //   "GT",
  //   "GG",
  //   "GN",
  //   "GW",
  //   "GY",
  //   "HT",
  //   "HM",
  //   "VA",
  //   "HN",
  //   "HK",
  //   "HU",
  //   "IS",
  //   "IN",
  //   "ID",
  //   "IR",
  //   "IQ",
  //   "IE",
  //   "IM",
  //   "IL",
  //   "IT",
  //   "JM",
  //   "JP",
  //   "JE",
  //   "JO",
  //   "KZ",
  //   "KE",
  //   "KI",
  //   "KP",
  //   "KR",
  //   "KW",
  //   "KG",
  //   "LA",
  //   "LV",
  //   "LB",
  //   "LS",
  //   "LR",
  //   "LY",
  //   "LI",
  //   "LT",
  //   "LU",
  //   "MO",
  //   "MK",
  //   "MG",
  //   "MW",
  //   "MY",
  //   "MV",
  //   "ML",
  //   "MT",
  //   "MH",
  //   "MQ",
  //   "MR",
  //   "MU",
  //   "YT",
  //   "MX",
  //   "FM",
  //   "MD",
  //   "MC",
  //   "MN",
  //   "ME",
  //   "MS",
  //   "MA",
  //   "MZ",
  //   "MM",
  //   "NA",
  //   "NR",
  //   "NP",
  //   "NL",
  //   "NC",
  //   "NZ",
  //   "NI",
  //   "NE",
  //   "NG",
  //   "NU",
  //   "NF",
  //   "MP",
  //   "NO",
  //   "OM",
  //   "PK",
  //   "PW",
  //   "PS",
  //   "PA",
  //   "PG",
  //   "PY",
  //   "PE",
  //   "PH",
  //   "PN",
  //   "PL",
  //   "PT",
  //   "PR",
  //   "QA",
  //   "RE",
  //   "RO",
  //   "RU",
  //   "RW",
  //   "BL",
  //   "SH",
  //   "KN",
  //   "LC",
  //   "MF",
  //   "PM",
  //   "VC",
  //   "WS",
  //   "SM",
  //   "ST",
  //   "SA",
  //   "SN",
  //   "RS",
  //   "SC",
  //   "SL",
  //   "SG",
  //   "SX",
  //   "SK",
  //   "SI",
  //   "SB",
  //   "SO",
  //   "ZA",
  //   "GS",
  //   "SS",
  //   "ES",
  //   "LK",
  //   "SD",
  //   "SR",
  //   "SJ",
  //   "SZ",
  //   "SE",
  //   "CH",
  //   "SY",
  //   "TW",
  //   "TJ",
  //   "TZ",
  //   "TH",
  //   "TL",
  //   "TG",
  //   "TK",
  //   "TO",
  //   "TT",
  //   "TN",
  //   "TR",
  //   "TM",
  //   "TC",
  //   "TV",
  //   "UG",
  //   "UA",
  //   "AE",
  //   "GB",
  //   "US",
  //   "UM",
  //   "UY",
  //   "UZ",
  //   "VU",
  //   "VE",
  //   "VN",
  //   "VG",
  //   "VI",
  //   "WF",
  //   "EH",
  //   "YE",
  //   "ZM",
  //   "ZW",
  // ];
}

function carriersList() {
  return [
    "RussianPost",
    "CDEK",
    "Boxberry",
    "DPD",
    "PrivatePost",
    "Ozon",
    "Wildberries",
    "YandexMarket",
    "AliExpress",
  ];
}

function isMarketplace(carrier) {
  return ["Wildberries", "Ozon", "YandexMarket", "AliExpress"].includes(
    carrier
  );
}

function getPrice(object) {
  if (object.price === null || object.price === undefined) return object.price;
  else return object.price / 100;
}

function setPrice(object, val) {
  if (val === null || val === undefined) object.price = val;
  else object.price = Math.floor(val * 100);
}

function isInteger(val) {
  if (typeof val === "string") return /^\d+$/.test(val.trim());
  else return typeof val === "number";
}

function isNumber(val) {
  if (typeof val === "string") return /^[\d\.]+$/.test(val.trim());
  else return typeof val === "number";
}

function fullAddress(address) {
  return [
    countryName(address.country),
    address.zip,
    address.region,
    address.city,
    address.street,
  ]
    .filter((s) => s && s.length > 0)
    .join(", ");
}

function loadingIconOn(root) {
  root.$children[0].loadingDialog = true;
}

function loadingIconOff(root) {
  root.$children[0].loadingDialog = false;
}

function snackMessage(root, message, color) {
  root.$emit("snack-message", message, color);
}

function globalSettings(component) {
  return component.$store.state.settings;
}

function getCompanyById(component, company_id) {
  return component.$store.state.companies.find((c) => c.id === company_id);
}

function mangoPresent(component) {
  return globalSettings(component)["mango_api_key"] !== undefined;
}

function callClient(comp, order) {
  if (mangoPresent(comp)) {
    return ExtraPostAPI.post("current_user/phone_callback", {
      phone: order.to_address.phone,
    });
  } else {
    window.location = "tel:" + order.to_address.phone;
  }
}

function currentUser(component) {
  return component.$store.state.currentUser;
}

function hasRole(component, role) {
  return (
    component.$store.state.currentUser &&
    component.$store.state.currentUser.roles.includes(role)
  );
}

function acceptableWarehouse(component, item) {
  var user = component.$store.state.currentUser;
  if (!user || !item) {
    return true;
  }

  if (user.extra.locations) {
    return (
      user.extra.locations === item.assigned_location_id ||
      user.extra.locations.includes(item.assigned_location_id)
    );
  }

  return true;
}

function isAdmin(component) {
  return hasRole(component, "admin");
}

function isStoreowner(component) {
  return hasRole(component, "storeowner");
}

function isOperator(component) {
  return hasRole(component, "operator");
}

export {
  carrierImageTag,
  isMobile,
  countriesList,
  cityWithFlag,
  countryFlag,
  countryName,
  carriersList,
  isMarketplace,
  orderWarningsIcon,
  shipmentWarningsIcon,
  login,
  isLoggedIn,
  getPrice,
  setPrice,
  isInteger,
  isNumber,
  loadingIconOn,
  loadingIconOff,
  snackMessage,
  fullAddress,
  globalSettings,
  isAdmin,
  isOperator,
  isStoreowner,
  hasRole,
  acceptableWarehouse,
  currentUser,
  mangoPresent,
  callClient,
  deliveryLimitIcon,
  cannotFly,
  trackerStatusIcon,
};
