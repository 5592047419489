<template>
  <extra-table :title="$t('batches')" entity="batches" :fields="fields"></extra-table>
</template>

<script>
import ExtraTable from './ExtraTable.vue'
import { carrierImageTag } from '../functions.js'


export default {
  metaInfo () {
    return { title: this.$i18n.t('batches') }
  },
  components: { 'extra-table': ExtraTable },
  data () {
    return {
      fields: [
        { name: 'reference', value: (batch) => { return carrierImageTag(batch.carrier_account.carrier) + ' ' + (batch.reference || '') } },
        { name: 'carrier_account.company.title', editable: false },
        { name: 'shipments', editable: false,
          value: (batch) => { return batch.shipments.length; }
        },
        { name: 'parcels', editable: false,
          value: (batch) => { return batch.shipments.reduce((acc, sh) => {
                    return acc + sh.parcels.length;
                  }, 0); }
        },
        { name: 'weight_kg', editable: false,
          value: (batch) => { return batch.shipments.reduce((acc, sh) => {
                    return acc + sh.parcels.reduce((acc, pcl) => {
                      return acc + pcl.weight;
                    }, 0);
                  }, 0) / 1000.0; 
          }
        },
      ]
    }
  }
}
</script>
