<template>
  <v-container>
    <h1 v-if="order">{{ $t('order') }} {{ order.reference || order.id }}</h1>
    <extra-order v-if="order" :order="order" :errors="errors"></extra-order>
  </v-container>
</template>
<script>
import Order from "./Order.vue";
import ExtraPostAPI from "../api.js";
import { loadingIconOn, loadingIconOff } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("order") };
  },
    components: {
    "extra-order": Order
  },
  data() {
    return {
      order: null,
      errors: {}
    }
  },
  watch: {
    "$route.params.order_id": function(order_id) {
      this.loadOrder();
    }
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    loadOrder() {
      if (this.$route.params.order_id != undefined) {
        loadingIconOn(this.$root);
        this.order = { line_items: [] };
        var self = this;
        ExtraPostAPI.get("orders/" + self.$route.params.order_id)
          .then((response) => {
            loadingIconOff(this.$root);
            self.order = response.data;
          })
          .then(() => {
            loadingIconOff(this.$root);
          });
      }
    },
  }
}
</script>