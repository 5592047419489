<template>
  <extra-cards
    :title="$t('carrier_accounts')"
    entity="carrier_accounts"
    :fields="fields"
    type="carrier"
    :templates="templates"
    search
  >
    <template v-slot:title="props"> {{ props.item.company.title }}</template>
    <template v-slot:fields="props">
      <v-flex xs12 sm12 md12 pr-4 pl-0>
        <v-select
          :items="
            companies.map((c) => {
              return { text: c.title, value: c.id };
            })
          "
          v-model="props.item.company_id"
          :label="$t('company_id')"
        />
      </v-flex>
    </template>
  </extra-cards>
</template>

<script>
import { mapState } from "vuex";
import ExtraCards from "./ExtraCards.vue";
import RussianPost from "./RussianPost.vue";
import CDEK from "./CDEK.vue";
import DPD from "./DPD.vue";
import Wildberries from "./Wildberries.vue";
import ExtraPostAPI from "../api.js";
import { loadingIconOn, loadingIconOff } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("carrier_accounts") };
  },
  components: { "extra-cards": ExtraCards },
  computed: {
    ...mapState(["uiAnimation", "companies"]),
  },
  data() {
    return {
      fields: [{ name: "carrier" }, { name: "company" }],
      templates: {
        RussianPost: RussianPost,
        PrivatePost: ["current_track", "max_track", "post_office"],
        ExtraPost: [],
        CDEK: CDEK,
        DPD: DPD,
        DHL: ["siteId", "password", "accountNumber"],
        UPS: [
          "access_license_number",
          "username",
          "password",
          "account_number",
        ],
        Ozon: ["client_id", "api_key"],
        // OzonRocket: ["client_id", "client_secret", "from_place_id", "contract"],
        YandexMarket: ["campaign_id"],
        Boxberry: ["token"],
        PickPoint: ["ikn", "login", "password"],
        UniExpress: ["id", "token"],
        AliExpress: ["api_token"],
        Wildberries: Wildberries,
      },
    };
  },
  methods: {},
};
</script>

// [ // "login", // "password", // "token", // "post_office", //
"taxpayer_number" // ]
