<template>
  <v-tooltip left transition="none" open-delay="100">
    <template #activator="{ on }">
      <span v-on="on" :class="priceColor(item)">
        <!-- <v-icon small title="В заказе есть непривязанные товары." color="red" v-if="hasUnlinkedProducts(item)">error_outline</v-icon> -->
        {{
          (item.price / 100).toLocaleString($i18n.locale) +
          "&nbsp;" +
          $t(item.currency + "_sign")
        }}
      </span>
    </template>
    <table class="order-contents-tooltip">
      <tr colspan="4" v-if="hasUnlinkedProducts(item)">
        <td class="red--text">{{ $t("order_has_unlinked_products") }}</td>
      </tr>
      <tr v-for="(li, index) in item.line_items" :key="index">
        <td :class="lineItemColor(li)">[{{ li.product_sku }}]&nbsp;&nbsp;</td>
        <td>{{ li.product_title }}</td>
        <td>
          <v-icon small dark>close</v-icon>
        </td>
        <td>{{ li.quantity }}&nbsp;{{ $t("pcs") }}</td>
      </tr>
      <tfoot>
        <tr>
          <td></td>
          <td>
            <strong>{{ $t("total") }}</strong>
          </td>
          <td></td>
          <td>
            <strong>
              {{
                item.line_items.reduce((acc, li) => {
                  return acc + li.quantity;
                }, 0)
              }}&nbsp;{{ $t("pcs") }}
            </strong>
          </td>
        </tr>
        <tr v-if="allLineItemsHaveWeight(item)">
          <td></td>
          <td>
            <strong>{{ $t("netto") }}</strong>
          </td>
          <td></td>
          <td>
            <strong>{{ nettoWeight(item) }}&nbsp;{{ $t("kg") }}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  </v-tooltip>
</template>

<script>
import Vue from "vue";
import OIcon from "./OIcon.vue";
import { fullAddress } from "../functions.js";

export default Vue.component("order-price-tooltip", {
  props: { item: Object },
  components: { OIcon },
  methods: {
    newlineToBreaks(text) {
      return text.replace("\n", "<br/>");
    },
    allLineItemsHaveWeight(order) {
      return (
        order.line_items.length > 0 &&
        order.line_items.every((li) => {
          return li.product_weight || (li.product && li.product.weight);
        })
      );
    },
    nettoWeight(order) {
      return (
        order.line_items.reduce((acc, li) => {
          return acc + li.quantity * ((li.product && li.product.weight) || 0);
        }, 0) / 1000.0
      );
    },
    hasUnlinkedProducts(order) {
      return order.line_items.some((li) => {
        return li.product_id === null;
      });
    },
    priceColor(order) {
      var textColor = "";
      var backColor = "";
      if (this.hasUnlinkedProducts(order)) textColor = "red--text pa-1 rounded";
      if (order.prepaid) {
        backColor = "yellow darken-2";
        if (textColor === "") textColor = "white--text pa-1 rounded";
        if (order.prepaid_paid) backColor = "green";
      }

      return textColor + " " + backColor;
    },
    lineItemColor(li) {
      if (li.product_id === null) return "red--text";
      else return "";
    },
  },
});
</script>

<style>
.order-client-address-tooltip {
  font-size: 110%;
}

.order-comment-tooltip {
  font-size: 110%;
  white-space: pre;
}

.rounded {
  border-radius: 3px;
}
</style>
