<template>
  <extra-table
    :title="$t('products')"
    entity="products"
    :fields="fields"
    search
  >
    <template v-slot:header="{ selected }">
      <!-- <v-btn
        small
        depressed
        @click="linkProductsWithMoySklad(selected)"
        v-if="selected.length > 0"
        >{{ $t("link_products_with_moy_sklad") }}</v-btn
      > -->
    </template>
    <template v-slot:editForm="props">
      <extra-product
        :product="props.item"
        :errors="props.errors"
      ></extra-product>
    </template>
  </extra-table>
</template>

<script>
import ExtraTable from "./ExtraTable.vue";
import ExtraPostAPI from "../api.js";
import Product from "./Product.vue";
import { countriesList, loadingIconOn, loadingIconOff } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("products") };
  },
  components: { "extra-table": ExtraTable, "extra-product": Product },
  data() {
    return {
      fields: [
        {
          name: "skus",
          sortable: true,
          value: (product) => {
            return product.skus.join(", ");
          },
        },
        { name: "barcodes", column: false },
        {
          name: "",
          align: "center",
          value: (product) => {
            return (
              product.image_url &&
              `<img height="32px" src="/api/${product.image_url}" />`
            );
          },
        },
        {
          name: "title",
          sortable: true,
          value: (product) => {
            return (
              product.title +
              this.aviaForbidden(product) +
              this.paperProduct(product) +
              this.prepackagedProduct(product) +
              this.dimensionedProduct(product) +
              this.unreadableBarcode(product) +
              this.slot(product) +
              (product.comment
                ? '<div class="caption grey--text">' +
                  product.comment +
                  "</div>"
                : "")
            );
          },
        },
        {
          name: "weight",
          sortable: true,
          align: "right",
          value: (item) => {
            return (
              item.weight.toLocaleString(this.$i18n.locale) +
              "&nbsp;<sup>" +
              this.$i18n.t("gr") +
              "</sup>"
            );
          },
        },
        {
          name: "price",
          sortable: true,
          align: "right",
          value: (item) => {
            return (
              (item.price / 100).toLocaleString(this.$i18n.locale) +
              "&nbsp;<sup>" +
              this.$i18n.t(item.currency + "_sign") +
              "</sup>"
            );
          },
        },
        { name: "hs_code" },
        { name: "currency", column: false, list: ["RUB", "USD", "EUR", "CNY"] },
        { name: "origin_country", column: false, list: countriesList() },
      ],
    };
  },
  methods: {
    aviaForbidden(product) {
      if (product.extra.avia_forbidden) {
        return (
          " <i title='" +
          this.$i18n.t("avia_forbidden") +
          "' class='v-icon material-icons-outlined red--text' style='font-size: 16px;'>airplanemode_inactive</i>"
        );
      } else return "";
    },
    paperProduct(product) {
      if (product.extra.paper_product) {
        return (
          " <i title='" +
          this.$i18n.t("paper_product_suitable_for_letter") +
          "' class='v-icon material-icons-outlined primary--text' style='font-size: 16px;'>drafts</i>"
        );
      } else return "";
    },
    prepackagedProduct(product) {
      if (product.extra.does_not_need_package) {
        return (
          " <i title='" +
          this.$i18n.t("does_not_need_package") +
          "' class='v-icon material-icons-outlined primary--text' style='font-size: 16px;'>fullscreen</i>"
        );
      } else return "";
    },
    unreadableBarcode(product) {
      if (product.extra.unreadable_barcode) {
        return (
          " <i title='" +
          this.$i18n.t("unreadable_barcode") +
          "' class='v-icon material-icons-outlined red--text' style='font-size: 16px;'>blur_off</i>"
        );
      } else return "";
    },
    dimensionedProduct(product) {
      if (
        product.length != null &&
        product.width != null &&
        product.height != null
      ) {
        return (
          ` <i title='${product.length} × ${product.width} × ${product.height} ` +
          this.$i18n.t("mm") +
          ` (${
            (product.length * product.width * product.height) / 1000000000.0
          } ` +
          this.$i18n.t("m3") +
          ")' class='v-icon material-icons-outlined primary--text' style='font-size: 16px;'>view_in_ar</i>"
        );
      } else return "";
    },
    slot(product) {
      if (product.extra.slot)
        return `&nbsp;<strong title="${this.$i18n.t("slot")}">[${
          product.extra.slot
        }]</strong>`;
      else return "";
    },
  },
};
</script>
