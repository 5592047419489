import Axios from "axios";

const http = require("http");
const https = require("https");

const ExtraPostAPI = Axios.create({
  baseURL: "/api/v1/",
  timeout: 600000, // 600 sec
  maxContentLength: 50 * 1000 * 1000, // 50 Mb
  maxRedirects: 10,
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
});

ExtraPostAPI.interceptors.request.use(
  (config) => {
    var token = localStorage.getItem("extrapost_api_access_token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ExtraPostAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      localStorage.removeItem("extrapost_api_access_token");
      window.location = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default ExtraPostAPI;
