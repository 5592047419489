import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shipments: [],
    companies: [], // [{ id: 'aaa', title: 'abc' }, { id: 'bbb', title: 'def' }],
    locations: [],
    products: [],
    projects: [],
    order_sources: [],
    uiAnimation: localStorage.getItem("extrapost_ui_animation") === "true",
    ordersFulfilledToday: 0,
    totalUnfulfilledOrders: 0,
    serverState: {},
    settings: {},
    currentUser: null,
  },
  getters: {
    getShipments: (state) => {
      return state.shipments;
    },
    getCompanies: (state) => {
      return state.companies;
    },
    getProjects: (state) => {
      return state.projects;
    },
    getProducts: (state) => {
      return state.products;
    },
    getOrderSources: (state) => {
      return state.order_sources;
    },
    getLocations: (state) => {
      return state.locations;
    },
    getUIAnimation: (state) => {
      return state.uiAnimation;
    },
    getSettings: (state) => {
      return state.settings;
    },
  },
  mutations: {
    setShipments(state, val) {
      state.shipments = val;
    },
    setCompanies(state, val) {
      state.companies = val;
    },
    setProjects(state, val) {
      state.projects = val;
    },
    setProducts(state, val) {
      state.products = val;
    },
    setOrderSources(state, val) {
      state.order_sources = val;
    },
    setLocations(state, val) {
      state.locations = val;
    },
    setUIAnimation(state, val) {
      state.uiAnimation = val;
      localStorage.setItem("extrapost_ui_animation", val);
    },
    setSettings(state, val) {
      state.settings = val;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    clearAll(state) {
      state.shipments = [];
      state.companies = [];
      state.projects = [];
      state.products = [];
      state.order_sources = [];
      state.locations = [];
      state.uiAnimation = true;
      state.ordersFulfilledToday = 0;
      state.totalUnfulfilledOrders = 0;
      state.serverState = {};
      state.settings = {};
      state.currentUser = null;
    },
  },
});
