<template>
  <extra-table :title="$t('parcels')" entity="parcels" :fields="fields"></extra-table>
</template>

<script>
import ExtraTable from './ExtraTable.vue'

export default {
  metaInfo () {
    return { title: this.$i18n.t('parcels') }
  },
  components: { 'extra-table': ExtraTable },
  data () {
    return {
      fields: [
        { name: 'shipment_id' },
        { name: 'weight' },
        { name: 'payment', value: (item) => { return (item.payment/100).toLocaleString(this.$i18n.locale) } },
        { name: 'value', column: false },
        { name: 'length', column: false },
        { name: 'width', column: false },
        { name: 'height', column: false }
      ]
    }
  }
}
</script>
