<template>
  <v-container pt-0 px-0>
    <v-layout row wrap v-for="(li, index) in line_items" :key="index">
      <v-flex md8 xs8 py-0>
        <v-combobox
          :items="products"
          cache-items
          :autofocus="li.product_title === ''"
          :loading="products_loading"
          @input="(e) => setProduct(li, e)"
          @keyup="(e) => updateProductsList(e.target.value)"
          :value="getProduct(li)"
          :label="$t('product_title')"
          height="1em"
          :error-messages="li.product_id === null ? 'Товар не привязан' : []"
        ></v-combobox>
      </v-flex>
      <v-flex md1 xs1 py-0>
        <v-text-field
          v-model="li.quantity"
          :label="$t('qty')"
          height="1em"
        ></v-text-field>
      </v-flex>
      <v-flex md2 xs2 py-0>
        <v-text-field
          @input="(e) => setPrice(li, e)"
          :value="getPrice(li)"
          :label="$t('price')"
          height="1em"
          :suffix="$t(order.currency + '_sign')"
          :error-messages="priceMessages(li)"
        ></v-text-field>
      </v-flex>
      <v-flex md1 xs1 my-0>
        <v-btn @click="remove(li)" small icon>
          <v-icon small>delete_outline</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-btn color="primary" @click="add" dark small class="ml-0">{{
      $t("add")
    }}</v-btn>
  </v-container>
</template>

<script>
import Vue from "vue";
import { isMobile, getPrice, setPrice } from "../functions.js";
import ExtraPostAPI from "../api.js";

export default Vue.component("line-items", {
  name: "line-items",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      line_items: [],
      products: [],
      products_loading: false,
    };
  },
  watch: {
    order: function (order) {
      this.setLineItems(order.line_items);
    },
  },
  methods: {
    setLineItems(line_items) {
      this.line_items = line_items;
    },
    add() {
      this.line_items.push({
        product_sku: "value_to_replace",
        product_title: "",
        quantity: 1,
        price: undefined,
      });

      Vue.set(this.order, "line_items", this.line_items);
    },
    remove(li) {
      this.line_items.splice(this.line_items.indexOf(li), 1);
      Vue.set(this.order, "line_items", this.line_items);
    },
    getPrice(li) {
      return getPrice(li);
    },
    setPrice(li, val) {
      setPrice(li, val);
    },
   
    priceMessages(li) {
      if (li.product && li.product.price > li.price) 
        return [this.$i18n.t("price_is_lower")];
      else
        return [];
    },
    getProduct(li) {
      return li.product_title;
    },
    setProduct(li, product) {
      console.log("setProduct", product);
      console.log("this.order", this.order);
      if (product.value) {
        li.product_title = product.text;
        li.product_id = product.value;
        li.price =
          product.currency === this.order.currency ? product.price : undefined;
        li.product_sku = product.sku;
        li.product_weight = product.weight;
      } else {
        li.product_id = null;
        li.price = undefined;
        li.product_sku = null;
        li.product_weight = 100;
        li.product_title = product;
      }
    },
    updateProductsList(val) {
      var self = this;
      self.products_loading = true;
      ExtraPostAPI.get(`products?search=${val}`).then((response) => {
        self.products_loading = false;
        self.products = response.data.products.map(
          ({
            id: product_id,
            title: product_title,
            price: price,
            currency: currency,
            sku: sku,
            weight: weight,
          }) => {
            return {
              value: product_id,
              text: product_title,
              price: currency === self.order.currency ? price : undefined,
              sku: sku,
              weight: weight,
              currency: currency,
            };
          }
        );
      });
    },
  },
});
</script>
