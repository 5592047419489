<template>
  <v-layout row wrap>
    <v-flex v-for="field in ['api_token']" :key="field" xs12 sm6 md6 pr-4 pl-0>
      <v-text-field
        v-model="$attrs.parameters[field]"
        :label="$t(field)"
      ></v-text-field>
    </v-flex>

    <v-checkbox
      v-for="service in ['update_stock']"
      :key="service"
      v-model="$attrs.parameters[service]"
      :label="$t(service)"
      hide-details
    ></v-checkbox>
  </v-layout>
</template>
<script>
import Vue from "vue";

export default Vue.component("aliexpress", {
  data() {
    return {};
  },
});
</script>
