<template>
  <v-dialog
    v-model="on"
    scrollable
    hide-overlay
    full-width
    :persistent="persist"
    :fullscreen="isMobile()"
    id="view-json-dialog"
    :transition="getDialogTransition()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline pl-2">{{ item.id }}</span>
        <v-spacer />
        <v-btn icon @click.native="close()" :ripple="uiAnimation">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mb-4 pb-0 px-4">
        <slot name="preview" v-bind:item="item">
          <json-viewer :value="item" :expand-depth="2" copyable :theme="jsonTheme()"></json-viewer>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import JsonViewer from 'vue-json-viewer'
import { isMobile } from '../functions.js'

export default Vue.component('view-json-dialog', {
  name: 'view-json-dialog',
  props: {item: Object, persist: Boolean, onClose: Function},
  components: { JsonViewer },
  data () {
    return {
      on: false
    }
  },
  computed: {
    ...mapState(['uiAnimation']),
  },
  methods: {
    open () {
      this.on = true
    },
    close () {
      this.on = false
      if (this.onClose) this.onClose()
    },
    isOpened () {
      return this.on
    },
    getDialogTransition () {
       return (this.uiAnimation ? 'dialog-transition' : 'none')
    },
    jsonTheme () {
      return localStorage.getItem('extrapost_dark_theme') === 'true' ? 'json-dark' : 'json-light'
    },
    isMobile() {
      return isMobile()
    }
  }
})
</script>