var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l(([
      'api_key',
      'client_id',
      'notify_undelivered_phone',
      'notify_unfulfilled_phone',
      'telegram_notifications_chat',
      'ozon_warehouse_id',
    ]),function(field){return _c('v-flex',{key:field,attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-text-field',{attrs:{"label":_vm.$t(field)},model:{value:(_vm.$attrs.parameters[field]),callback:function ($$v) {_vm.$set(_vm.$attrs.parameters, field, $$v)},expression:"$attrs.parameters[field]"}})],1)}),_vm._l(([
      'update_stock',
      'update_prices',
      'trusting_acceptance',
    ]),function(service){return _c('v-checkbox',{key:service,attrs:{"label":_vm.$t(service),"hide-details":""},model:{value:(_vm.$attrs.parameters[service]),callback:function ($$v) {_vm.$set(_vm.$attrs.parameters, service, $$v)},expression:"$attrs.parameters[service]"}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }