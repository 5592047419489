var render = function render(){var _vm=this,_c=_vm._self._c;return _c('extra-table',{attrs:{"title":_vm.$t('users'),"entity":"users","fields":_vm.fields},scopedSlots:_vm._u([{key:"editForm",fn:function(props){return [_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-text-field',{attrs:{"value":props.item.name,"label":_vm.$t('name')},on:{"input":(e) => (props.item.name = e)}}),_c('v-text-field',{attrs:{"value":props.item.password,"label":_vm.$t('password'),"append-icon":_vm.show_password ? 'visibility' : 'visibility_off',"rules":[_vm.rules.min],"type":_vm.show_password ? 'text' : 'password',"hint":"At least 6 characters","counter":""},on:{"input":(e) => (props.item.password = e),"click:append":function($event){_vm.show_password = !_vm.show_password}}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-text-field',{attrs:{"value":props.item.email,"label":_vm.$t('email')},on:{"input":(e) => (props.item.email = e)}}),_c('v-select',{attrs:{"value":props.item.locale,"items":['en', 'ru', 'zh'],"label":_vm.$t('locale')},on:{"input":(e) => (props.item.locale = e)}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-select',{attrs:{"value":(props.item.extra && props.item.extra.locations) || [],"items":_vm.locations.map((l) => {
                return { text: l.reference, value: l.id };
              }),"attach":"","chips":"","label":_vm.$t('locations'),"multiple":"","hide-details":""},on:{"input":(e) => (props.item.extra.locations = e)}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-select',{attrs:{"items":_vm.roles,"attach":"","chips":"","label":_vm.$t('roles'),"multiple":"","hide-details":""},model:{value:(props.item.roles),callback:function ($$v) {_vm.$set(props.item, "roles", $$v)},expression:"props.item.roles"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pr-4":"","pl-0":""}},[_c('v-autocomplete',{attrs:{"value":(props.item.extra && props.item.extra.available_products) || [],"items":_vm.products.map((p) => {
                return { text: p.title, value: p.id };
              }),"attach":"","chips":"","label":_vm.$t('available_products'),"multiple":"","hide-details":""},on:{"input":(e) => (props.item.extra.available_products = e)}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-select',{attrs:{"value":(props.item.extra && props.item.extra.available_carriers) || [],"items":_vm.carriersList().map((c) => {
                return { text: _vm.$t(c), value: c };
              }),"attach":"","chips":"","label":_vm.$t('available_carriers'),"multiple":"","hide-details":""},on:{"input":(e) => (props.item.extra.available_carriers = e)}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }