<template>
  <v-container fluid grid-list-xl>
    <h1>{{ title }}</h1>
    <v-layout row wrap>
      <v-flex xs12 md6 v-for="card in items" :key="card.id">
        <v-card
          class="extra-card"
          :id="card.id"
          :style="!card.active ? 'filter: grayscale(100%)' : ''"
        >
          <v-card-text>
            <v-layout>
              <v-flex pr-0>
                <v-img
                  :src="'/img/logos/' + card[type] + '.svg'"
                  :alt="card[type]"
                  height="100px"
                  width="100px"
                  contain
                />
              </v-flex>
              <v-flex pl-0>
                <div>
                  <a class="extra-card-id" @click="showItem(card)">{{
                    card.id
                  }}</a>
                  <slot name="title" v-bind:item="card"></slot>
                </div>
                <slot name="actions" v-bind:item="card"></slot>
                <v-btn
                  flat
                  small
                  depressed
                  :ripple="uiAnimation"
                  @click="editCard(card)"
                  >{{ $t("edit") }}</v-btn
                >
                <v-btn
                  flat
                  small
                  depressed
                  :ripple="uiAnimation"
                  @click="deleteCard(card)"
                  >{{ $t("delete") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md6>
        <v-card>
          <v-card-text>
            <div id="add_card_title">{{ $t("add_card") }}</div>
            <v-btn
              fab
              large
              v-for="card in Object.keys(templates)"
              :key="card"
              @click="createCard(card)"
              style="padding: 0px 4px;"
            >
              <v-img
                :src="'/img/logos/' + card + '.svg'"
                :alt="card"
                :title="card"
                width="50px"
                height="50px"
                contain
              />
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-dialog
        v-model="dialog"
        hide-overlay
        max-width="600px"
        @keydown.esc="close()"
        :fullscreen="isMobile()"
        :transition="getDialogTransition()"
      >
        <v-card>
          <v-card-title class="pb-0">
            <span class="headline pl-3">{{
              editedItem && $t(editedItem[type]) + " " + (editedItem.id || "")
            }}</span>

            <v-checkbox
              v-model="editedItem.active"
              :label="$t('active')"
              hide-details
              style="margin-top: -0.5em; margin-left: 1em;"
            ></v-checkbox>

            <v-spacer />
            <v-btn icon @click.native="close()" :ripple="uiAnimation">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-0">
            <v-container grid-list-md>
              <v-layout wrap>
                <slot name="fields" v-bind:item="editedItem"></slot>

                <template v-if="cardFields(editedItem) instanceof Array">
                  <v-flex
                    xs12
                    sm6
                    md6
                    v-for="field in cardFields(editedItem)"
                    :key="field"
                    pr-4
                    pl-0
                  >
                    <v-text-field
                      @input="(e) => setValue(editedItem.parameters, field, e)"
                      :value="getValue(editedItem.parameters, field)"
                      :label="$t(lastField(field))"
                      :error-messages="itemErrors[field]"
                      @keyup.native.enter="save()"
                    ></v-text-field>
                  </v-flex>
                </template>

                <template v-else>
                  <component
                    :is="cardFields(editedItem)"
                    :parameters="editedItem.parameters"
                  />
                </template>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat :ripple="uiAnimation" @click="close">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="primary" dark :ripple="uiAnimation" @click="save">{{
              $t("save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <view-json-dialog
        ref="viewDialog"
        :item="editedItem"
        :persist="false"
      ></view-json-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import ExtraPostAPI from "../api.js";
import ViewJsonDialog from "./ViewJsonDialog.vue";
import { isMobile, snackMessage } from "../functions.js";
import { Socket } from "phoenix";
import _ from "lodash";

export default Vue.component("extra-cards", {
  name: "extra-cards",
  props: {
    title: String,
    entity: String,
    fields: Array,
    type: String,
    templates: Object,
  },
  components: { ViewJsonDialog },
  data() {
    return {
      items: [],
      loading: true,
      dialog: false,
      persistViewDialog: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      itemErrors: {},
    };
  },
  mounted() {
    if (this.items.length === 0) {
      // Don't load data here, beacause it will be loaded in pagination handler.
      this.loadData();
    }
    this.joinUpdateChannel();
  },
  computed: {
    ...mapState(["uiAnimation"]),
    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("add")
        : this.$i18n.t("edit") + " " + this.editedItem.id;
    },
    vueRoot() {
      return this.$root;
    },
  },
  methods: {
    loadData() {
      var self = this;

      self.loading = true;

      ExtraPostAPI.get(self.entity, { params: {} })
        .then((response) => {
          self.items = response.data[self.entity];
        })
        .then(() => {
          self.loading = false;
        });
    },
    joinUpdateChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.channel = socket.channel("updates", {});
        this.channel
          .join()
          .receive("ok", (resp) => {
            console.log("Server update channel joined successfully", resp);
          })
          .receive("error", (resp) => {
            console.log("Unable to join server update channel", resp);
          });

        this.channel.on("create:" + this.entity, (payload) => {
          console.log("Create from api server", payload);

          if (
            !self.items.some((p) => {
              return p.id === payload.id;
            })
          ) {
            self.items.unshift(payload);
            setTimeout(() => {
              self.highlighCard(payload.id, "changed");
            }, 100);
          }
        });

        this.channel.on("update:" + this.entity, (payload) => {
          console.log("Update from api server", payload);

          var updatee = self.items.find((p) => {
            return p.id === payload.id;
          });
          if (updatee) {
            _.merge(updatee, payload);
            console.log(updatee);
            self.highlightCard(updatee.id, "changed");
          }
        });

        this.channel.on("delete:" + this.entity, (payload) => {
          console.log("Delete from server", payload);

          self.highlightCard(payload.id, "deleted");
          setTimeout(() => {
            self.deleteItemById(payload.id);
          }, 500);
        });
      }
    },
    cardFields(card) {
      if (card != null) return this.templates[card[this.type]];
      else return [];
    },
    showItem(item) {
      this.editedItem = Object.assign({}, item);
      this.persistViewDialog = this.$refs.viewDialog.isOpened();
      this.$refs.viewDialog.open();
      setTimeout(() => {
        this.persistViewDialog = false;
      }, 300);
    },
    createCard(card_type) {
      var parameters = {};

      var fields = this.templates[card_type];
      if (fields instanceof Array) {
        fields.forEach((f) => {
          parameters[f] = "";
        });
      }
      this.editedItem = {
        id: null,
        [this.type]: card_type,
        parameters: parameters,
      };
      console.log(this.editedItem);
      this.dialog = true;
    },
    editCard(card) {
      this.editedItem = Object.assign({}, card);
      this.editedItem.itemErrors = {};
      this.dialog = true;
    },
    deleteCard(card) {
      var self = this;
      ExtraPostAPI.delete(this.entity + "/" + card.id)
        .then((response) => {
          console.log(response.data);
          self.items.splice(self.items.indexOf(card), 1);
        })
        .catch((error) => {
          snackMessage(
            self.$root,
            error.response.data["error"] || error.response.statusText,
            "error"
          );
          console.log(error.response.data);
        });
    },
    highlightCard(cardId, animation) {
      var card = document.getElementById(cardId);
      if (card) {
        console.log(cardId);
        console.log(card.style.animation);
        card.style.animation = "card-" + animation + " 5s";
        setTimeout(() => {
          card.style.animation = "";
        }, 5000);
      }
    },
    getValue(object, path) {
      if (path instanceof Function) {
        return path(object);
      } else {
        return _.get(object, path);
      }
    },
    setValue(object, path, value) {
      _.set(object, path, value);
    },
    lastField(field) {
      return _.last(_.split(field, "."));
    },
    close() {
      this.dialog = false;
    },
    save() {
      var self = this;
      var e = self.entity;
      console.log(self.editedItem);
      if (this.editedItem.id != null) {
        // Save existing entity
        ExtraPostAPI.put(e + "/" + self.editedItem.id, {
          [this.pluralToSingle(e)]: self.editedItem,
        })
          .then((response) => {
            // Object.assign(self.items[self.editedIndex], response.data)
            self.close();
          })
          .catch((error) => {
            self.itemErrors = error.response.data.errors;
            console.log(error.response.data.errors);
          });
      } else {
        // Create new entity
        ExtraPostAPI.post(e, { [this.pluralToSingle(e)]: self.editedItem })
          .then((response) => {
            // Don't insert new record, it will be done by websocket notificaiton
            // self.items.push(response.data)
            self.close();
          })
          .catch((error) => {
            self.itemErrors = error.response.data.errors;
            console.log(self.itemErrors);
            snackMessage(
              self.$root,
              error.response.data["error"] || error.response.statusText,
              "error"
            );
          });
      }
    },
    pluralToSingle(str) {
      if (str === "companies") return "company";
      else return str.replace(/s$/, "");
    },
    getDialogTransition() {
      return this.uiAnimation ? "dialog-transition" : "none";
    },
    isMobile() {
      return isMobile();
    },
  },
});
</script>

<style lang="scss">
@keyframes card-changed {
  from {
    background-color: #00bbff;
  }
  to {
    background-color: none;
  }
}

@keyframes card-deleted {
  from {
    background-color: red;
  }
  to {
    background-color: none;
  }
}

.extra-card {
  min-width: 270px;
}

.extra-card button {
  width: 45%;
}

.extra-card-id {
  margin-top: 12px;
  padding-left: 24px;
}

#add_card_title {
  margin-top: -10px;
  margin-bottom: 2px;
  font-size: 120%;
}
</style>
