<template>
  <p id="footer" class="text-md-center">
    {{ "v" + version }}
    &copy;2024&nbsp;
    <a
      @click="$ga.event('Navigate', 'Click', 'http://extra.ooo')"
      href="http://extra.ooo"
      target="_blank"
      >{{ $t("ooo_extra") }}</a
    >
    &nbsp;&nbsp;+7(499)110-23-26
  </p>
</template>

<script>
import PackageJson from "../../package.json";

export default {
  name: "app-footer",
  data() {
    return {
      version: PackageJson.version,
    };
  },
};
</script>

<style scoped>
#footer {
  color: #aaa;
  background: transparent;
  padding-left: 0.75em;
}

#footer a {
  color: #aaa;
  text-decoration: underline;
}
</style>
