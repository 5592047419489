import Vue from "vue";
import { createApp } from "vue";
import "./plugins/vuetify";
import i18n from "@/plugins/i18n";
import _ from "lodash";
import FlagIcon from "vue-flag-icon";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import VueAnalytics from "vue-analytics";
import VueCookie from "vue-cookie";
import Moment from "moment-timezone";
import VueBarcodeScanner from "vue-barcode-scanner";
import ExtraPostAPI from "./api.js";
import { globalSettings, isLoggedIn, login } from "./functions.js";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import PackageJson from "../package.json";
import OrderPriceTooltip from "./components/OrderPriceTooltip.vue";

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: "UA-23213950-17",
  router,
  fields: {
    userId: localStorage.getItem("extrapost_api_user_id"),
  },
});

if (process.env.NODE_ENV === "production") {
  // Sentry.init({
  //   dsn: 'https://0b68b518f5104cd785b4d8414b6a0ce1@sentry.io/1498209',
  //   integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  //   release: PackageJson.version,
  //   environment: process.env.NODE_ENV
  // })
  // Sentry.configureScope(scope => {
  //   scope.setUser({ email: login() })
  // })
}

Vue.use(FlagIcon);
Vue.use(VueCookie);
Vue.use(VueBarcodeScanner);

var timezone = localStorage.getItem("extrapost_timezone") || Moment.tz.guess();

// Global date-time formatter, use: {{ dateTime | formatDate }}
Vue.filter("formatDate", function (value) {
  if (value) {
    // Timestamps are stored in UTC
    return Moment.utc(String(value))
      .tz(timezone)
      .format("DD MMM YYYY HH:mm:ss");
    // .format("lll");
  }
});

Vue.filter("formatDayOfMonth", function (value) {
  if (value) {
    // Timestamps are stored in UTC
    return Moment.utc(String(value)).format("DD MMM");
  }
});

Vue.filter("formatTimestamp", function (value) {
  if (value) {
    // Timestamps are stored in UTC
    return Moment.utc(String(value))
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss.SSSZ");
  }
});

Vue.filter("formatShortTimestamp", function (value) {
  if (value) {
    // Timestamps are stored in UTC
    return Moment.utc(String(value)).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
  }
});

router.beforeEach((to, from, next) => {
  if (isLoggedIn()) {
    if (_.isEmpty(store.state.companies)) {
      ExtraPostAPI.get("companies?only_active=true")
        .then((response) => {
          store.commit("setCompanies", response.data.companies);
        })
        .catch((error) => {
          console.log(error.response);
          router.app.$emit(
            "snack-message",
            "Cannot connect to server: " +
              (error.response.data.error || error.response.data),
            "error"
          );
        });
    }

    if (_.isEmpty(store.state.locations)) {
      ExtraPostAPI.get("locations?only_active=true").then((response) => {
        store.commit("setLocations", response.data.locations);
      });
    }

    if (_.isEmpty(store.state.projects)) {
      ExtraPostAPI.get("projects?only_active=true").then((response) => {
        store.commit("setProjects", response.data.projects);
      });
    }

    if (_.isEmpty(store.state.products)) {
      ExtraPostAPI.get("products?only_active=true&size=1000").then(
        (response) => {
          store.commit("setProducts", response.data.products);
        }
      );
    }

    if (_.isEmpty(store.state.order_sources)) {
      ExtraPostAPI.get("order_sources?only_active=true").then((response) => {
        store.commit("setOrderSources", response.data.order_sources);
      });
    }

    if (_.isEmpty(store.state.settings)) {
      ExtraPostAPI.get("settings").then((response) => {
        store.commit("setSettings", response.data);
      });
    }
    if (_.isEmpty(store.state.currentUser)) {
      ExtraPostAPI.get("current_user").then((response) => {
        store.commit("setCurrentUser", response.data);
      });
    }
  }
  next();
});

// Vue.component("OrderPriceTooltip", OrderPriceTooltip);

var app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

app.$mount("#app");
