var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l(([
      'login',
      'password',
      'token',
      'post_office',
      'extra_weight',
      'contract',
    ]),function(field){return _c('v-flex',{key:field,attrs:{"xs12":"","sm6":"","md6":"","pr-4":"","pl-0":""}},[_c('v-text-field',{attrs:{"label":_vm.$t(field)},model:{value:(_vm.$attrs.parameters[field]),callback:function ($$v) {_vm.$set(_vm.$attrs.parameters, field, $$v)},expression:"$attrs.parameters[field]"}})],1)}),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pr-4":"","pl-0":""}},[_c('v-select',{attrs:{"items":_vm.services,"item-value":"value","item-text":"label","attach":"","chips":"","label":_vm.$t('mailing_types'),"multiple":"","hide-details":""},model:{value:(_vm.$attrs.parameters.services),callback:function ($$v) {_vm.$set(_vm.$attrs.parameters, "services", $$v)},expression:"$attrs.parameters.services"}})],1),_vm._l(([
      'completness_checking',
      'sms_notice_recipient',
      'use_online_balance',
      'card_on_delivery',
      'create_order_2',
      'direct_label_load',
    ]),function(service){return _c('v-checkbox',{key:service,attrs:{"label":_vm.$t(service),"hide-details":""},model:{value:(_vm.$attrs.parameters[service]),callback:function ($$v) {_vm.$set(_vm.$attrs.parameters, service, $$v)},expression:"$attrs.parameters[service]"}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }