<template>
  <div>
    <v-tooltip left transition="none" open-delay="100">
      <template #activator="{ on }">
        <span v-on="on"
          ><a class="name" @click="searchFor(item.to_address.name)">{{
            item.to_address.name
          }}</a></span
        >
      </template>
      <div class="order-client-address-tooltip">
        <div>
          <strong>{{ $t("phone") }}:</strong>
          {{ item.to_address.phone }}
        </div>
        <div v-if="item.to_address.email && item.to_address.email.length > 0">
          <strong>{{ $t("email") }}:</strong>
          {{ item.to_address.email }}
        </div>
        <div style="max-width: 20em;">
          <strong>{{ $t("address") }}:</strong>
          {{ fullAddress(item.to_address) }}
        </div>
        <div
          v-if="
            item.to_address.carrier_facility &&
              item.to_address.carrier_facility.length > 0
          "
        >
          <strong>{{ $t("carrier_facility") }}:</strong>
          {{ item.to_address.carrier_facility }}
        </div>
      </div>
    </v-tooltip>
    <v-tooltip
      right
      open-delay="100"
      transition="none"
      v-if="item.comment && item.comment.length > 0"
    >
      <template #activator="{ on }">
        <span v-on="on">
          <o-icon small style="margin-left: 3px;" color="primary"
            >chat_bubble_outline</o-icon
          >
        </span>
      </template>
      <div class="order-comment-tooltip">{{ item.comment }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import OIcon from "./OIcon.vue";
import { fullAddress } from "../functions.js";

export default Vue.component("order-tooltip", {
  props: { item: Object, extraTable: Object },
  components: { OIcon },
  methods: {
    fullAddress(address) {
      return fullAddress(address);
    },
    newlineToBreaks(text) {
      return text.replace("\n", "<br/>");
    },
    searchFor(query) {
      // this.$router.push({
      //   query: { search: query },
      // });
      this.extraTable.searchQuery = query;
    },
  },
});
</script>

<style>
.order-client-address-tooltip {
  font-size: 110%;
}

.order-comment-tooltip {
  font-size: 110%;
  white-space: pre;
}
</style>
