<template>
  <v-layout>
    <v-flex md9 sm8>
      <h1>ReRegister Shipment</h1>
      <div v-if="shipment">
        <h2>{{ shipment.tracking_code }}</h2>
        <h2>{{ shipment.reference }}</h2>
        <h2>{{ shipment.to_address.city }}</h2>
        <h2>{{ shipment.to_address.name }}</h2>
      </div>
    </v-flex>
    <v-flex md3 sm4>
      <v-btn
        large
        right
        block
        :ripple="uiAnimation"
        :loading="printingLabel"
        @click="reregisterShipment()"
        :disabled="shipment === null"
        id="label_button"
      >
        <v-icon left dark>print</v-icon>
        {{ $t("reregister_shipment") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import ExtraPostAPI from "../api.js";
import ExtraPostNodes from "../nodes.js";
import { mapState } from "vuex";

export default {
  name: "reregister_shipment",
  metaInfo() {
    return { title: this.$i18n.t("reregister_shipment") };
  },
  data() {
    return {
      shipment: null,
      printingLabel: false,
    };
  },
  computed: {
    ...mapState(["uiAnimation"]),
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  methods: {
    onBarcodeScanned(barcode) {
      console.log("Barcode: " + barcode);
      const ean13Regexp = /^\d{13}$/;

      if (ean13Regexp.test(barcode)) {
        // This is product
      } else {
        // Try to find shipment by tracking code.
        this.findShipmentByTrack(barcode);
      }
    },
    findShipmentByTrack(track) {
      var self = this;
      ExtraPostAPI.get("shipments/find_by_track?track=" + track).then(
        (response) => {
          console.log(response);
          if (response.data.shipments && response.data.shipments.length == 1) {
            self.shipment = response.data.shipments[0];
            self.$root.$emit(
              "snack-message",
              "Found 1 shipment with tracking code " +
                self.shipment.tracking_code,
              "success"
            );
          } else {
            self.shipment = null;
          }
        }
      );
    },
    reregisterShipment() {
      var self = this;
      self.printingLabel = true;

      ExtraPostAPI.post(`shipments/${self.shipment.id}/reregister`, {})
        .then((response) => {
          console.log(response);
          self.shipment = response.data;
          self.printLabel();

          if (response.data.messages) {
            response.data.messages.forEach((message) => {
              self.$root.$emit("snack-message", message);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          self.$root.$emit(
            "snack-message",
            error.response.data || error.response.statusText,
            "error"
          );
        });
    },
    printLabel() {
      var self = this;
      var printing = null;
      if (self.shipment.label.type === "label")
        printing = ExtraPostNodes.printLabel(self.shipment.label_id);
      else printing = ExtraPostNodes.printDocument(self.shipment.label_id);

      printing
        .then((response) => {
          console.log(response);
          self.printingLabel = false;
        })
        .catch((error) => {
          console.log(error);
          self.$root.$emit(
            "snack-message",
            error["message"] || error.response.statusText,
            "error"
          );
          self.printingLabel = false;
        });
    },
  },
};
</script>
