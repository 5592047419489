<template>
  <extra-table
    :title="$t('documents')"
    entity="documents"
    search
    :fields="fields"
    ref="documents"
  >
    <template v-slot:header="{ selected }">
      <v-btn
        v-if="selected.length > 0"
        small
        depressed
        outlined
        :loading="printing"
        @click="printMultiple(selected)"
        >{{ $t("print") }}</v-btn
      >
      <v-btn
        v-if="selected.length > 0"
        small
        depressed
        outlined
        :loading="downloadingMerged"
        @click="downloadMergedPDF(selected)"
        >{{ $t("download_merged_pdf") }}</v-btn
      >
    </template>

    <template v-slot:preview="props">
      <extra-document :document="props.item"></extra-document>
    </template>
  </extra-table>
</template>

<script>
import ExtraTable from "./ExtraTable.vue";
import Document from "./Document.vue";
import ExtraPostNodes from "../nodes.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("documents") };
  },
  components: {
    "extra-table": ExtraTable,
    "extra-document": Document,
  },
  data() {
    return {
      fields: [
        {
          name: "size",
          editable: false,
          align: "right",
          value: (doc) => {
            return (
              Math.ceil(doc.size / 1024).toLocaleString(this.$i18n.locale) +
              "&nbsp;<sup>kB</sup>"
            );
          },
        },
        {
          name: "format",
          list: ["PDF", "ZPL", "EPL", "HTML", "JPG", "PNG", "GIF", "SVG"],
        },
        {
          name: "type",
          list: ["label", "document", "manifest", "invoice", "product_image"],
        },
        { name: "document", column: false },
        { name: "extra", column: false },
      ],
      channel: null,
      downloadingMerged: false,
    };
  },
  methods: {
    printMultiple(documents) {
      this.printing = true;
      for (let doc of documents) {
        this.doPrint(doc).catch((error) => {
          console.log(error);
        });
      }
      this.printing = false;
    },
    doPrint(document) {
      if (document.type === "label")
        return ExtraPostNodes.printLabel(document.id);
      else return ExtraPostNodes.printDocument(document.id);
    },
    downloadMergedPDF(documents) {
      this.downloadingMerged = true;
      var self = this;
      // If we import pdfjs as other components in the beggining of the script block
      // on production we get error "TypeError: cannot call function _init of undefined"
      // when calling our components like OrderTooltip and OrderPriceTooltip.
      // import("pdfjs").then((pdfjs) => {
      //   console.log(pdfjs);
      //   var merged_pdf = new pdfjs.Document();
      //   for (let doc of documents) {
      //     if (doc.format === "PDF")
      //       merged_pdf.addPagesOf(
      //         new pdfjs.ExternalDocument(
      //           this._base64ToArrayBuffer(doc.document)
      //         )
      //       );
      //   }
      //   merged_pdf.asBuffer().then((data) => {
      //     download(
      //       "data:application/octet-stream;base64," + data.toString("base64"),
      //       "merged.pdf",
      //       "application/pdf"
      //     );
      //     self.downloadingMerged = false;
      //   });
      // });
    },
    _base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
  },
};
</script>
