<template>
  <extra-table
    :title="$t('companies')"
    entity="companies"
    :fields="fields"
    search
  ></extra-table>
</template>

<script>
import ExtraTable from './ExtraTable.vue'
import { countriesList } from '../functions.js'

export default {
  metaInfo () {
    return { title: this.$i18n.t('companies') }
  },
  components: { 'extra-table': ExtraTable },
  data () {
    return {
      fields: [
        { name: 'taxpayer_number', sortable: true },
        { name: 'title', sortable: true },
        { name: 'legal_entity', sortable: true },
        { name: 'active' },
        { name: 'address.country', column: false, list: countriesList() },
        { name: 'address.zip', column: false },
        { name: 'address.region', column: false },
        { name: 'address.city' },
        { name: 'address.street', column: false },
        { name: 'address.phone', column: false },
        { name: 'address.email', column: false } 
      ]
    }
  }
}
</script>
