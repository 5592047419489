<template>
  <extra-table
    :title="$t('shipments')"
    entity="shipments"
    :fields="fields"
    :exportFunction="exportSelected"
    search
    :companiesTabs="true"
    :nonAdminCanDelete="true"
    :rowClass="
      (shp) => {
        if (shp.order && shp.order.archived) return 'grey--text';
        else return '';
      }
    "
  >
    <template v-slot:header>
      <v-btn
        color="success"
        small
        depressed
        v-if="$children[0].selected.length > 0"
        @click="generateBatches()"
        >{{ $t("generate_batches") }}</v-btn
      >
      <v-btn
        small
        depressed
        v-if="$children[0].selected.length > 0"
        @click="reregister($children[0].selected)"
        >{{ $t("reregister_shipments") }}</v-btn
      >
    </template>
  </extra-table>
</template>

<script>
import ExtraTable from "./ExtraTable.vue";
import ExtraPostAPI from "../api.js";
import ExtraPostNodes from "../nodes.js";

import {
  carrierImageTag,
  loadingIconOn,
  loadingIconOff,
  cityWithFlag,
  trackerStatusIcon,
  shipmentWarningsIcon,
} from "../functions.js";
import OrderPriceTooltip from "./OrderPriceTooltip.vue";

export default {
  metaInfo() {
    return { title: this.$i18n.t("shipments") };
  },
  components: { "extra-table": ExtraTable },
  data() {
    return {
      fields: [
        {
          name: "reference",
          title: "order",
          sortable: true,
          value: (sh) => {
            return (
              (sh.order_id
                ? `<a href='/orders/${sh.order_id}'>${sh.reference}</a>`
                : sh.reference) + shipmentWarningsIcon(sh, this)
            );
          },
        },
        {
          name: "tracking_code",
          value: (sh) => {
            return (
              "<nobr>" +
              carrierImageTag(
                (sh.selected_rate && sh.selected_rate.carrier) ||
                  (sh.rates[0] && sh.rates[0].carrier)
              ) +
              "&nbsp;" +
              (sh.tracking_code || "") +
              "</nobr>"
            );
          },
        },
        {
          name: "status",
          value: (sh) => {
            return sh.trackers[0] && trackerStatusIcon(sh.trackers[0].status);
          },
          editable: false,
        },
        {
          name: "parcels",
          align: "right",
          value: (shp) => {
            return shp.parcels
              .map((p) => {
                return `${p.weight.toLocaleString(
                  this.$i18n.locale
                )}<sup>${this.$i18n.t("gr")}</sup>`;
              })
              .join(", ");
          },
        },
        {
          name: "value",
          align: "right",
          value: (shp) => {
            return shp.parcels
              .map((p) => {
                return `${(p.value / 100.0).toLocaleString(this.$i18n.locale)}`;
              })
              .join(", ");
          },
        },
        {
          name: "to_address.city",
          value: (shipment) => {
            return cityWithFlag(shipment.to_address);
          },
        },

        { name: "to_address.name" },
        { name: "batch_id" },
        {
          name: "parcels",
          column: false,
          fields: [{ name: "id" }, { name: "weight" }, { name: "value" }],
        },
      ],
    };
  },
  methods: {
    generateBatches() {
      var extraTable = this.$children[0];
      var shipment_ids = this.shipmentIds(extraTable.selected);
      var self = this;

      loadingIconOn(this.$root);
      ExtraPostAPI.post("batches/autocreate", { shipment_ids: shipment_ids })
        .then((response) => {
          console.log(response);
          loadingIconOff(self.$root);

          response.data.batches.forEach((batch) => {
            ExtraPostNodes.printDocument(batch.document_id);
          });

          response.data.messages.forEach((message) => {
            self.$root.$emit("snack-message", message);
          });

          self.$root.$emit(
            "Batch " + response.data.reference + " successfully created."
          );
        })
        .catch((error) => {
          loadingIconOff(this.$root);
          console.log(error.response);
          self.$root.$emit(
            "snack-message",
            error.response.data || error.response.statusText,
            "error"
          );
        });
    },
    reregister(selected) {
      console.log(this.shipmentIds(selected));

      var self = this;

      ExtraPostAPI.post("shipments/reregister", {
        shipment_ids: this.shipmentIds(selected),
      })
        .then((response) => {
          console.log(response);

          response.data.messages.forEach((message) => {
            self.$root.$emit("snack-message", message);
          });
        })
        .catch((error) => {
          console.log(error.response);
          self.$root.$emit(
            "snack-message",
            error.response.data || error.response.statusText,
            "error"
          );
        });
    },
    exportSelected(selected) {
      let csv =
        "data:text/csv;charset=utf-8,ID,REFERENCE,DATE,CARRIER_SERVICE,TRACKING_CODE,STATUS,COUNTRY,ZIP,CITY,ADDRESS,NAME,COMPANY,FULFILLED_BY\n" +
        selected
          .map(
            (e) =>
              `${e.id},${e.reference},${e.inserted_at},${
                (e.selected_rate &&
                  (e.selected_rate.carrier || "") +
                    ":" +
                    (e.selected_rate.service || "")) ||
                ""
              },${e.tracking_code || ""},${
                (e.trackers[0] && e.trackers[0].status) || ""
              },"${e.to_address.country}","${e.to_address.zip}","${
                e.to_address.city
              }","${e.to_address.street}","${e.to_address.name}",${
                e.from_address.name
              },${e.extra.fulfilled_by || ""}`
          )
          .join("\n");

      window.open(encodeURI(csv));
    },
    shipmentIds(shipments) {
      return shipments.map((shipment) => {
        return shipment.id;
      });
    },
  },
};
</script>

<style lang="scss">
.grey--text a {
  color: rgb(158, 158, 158);
}
</style>
