<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-tabs fixed-tabs :grow="true">
            <v-tab key="login">{{ $t("login") }}</v-tab>
            <v-tab key="register">{{ $t("register") }}</v-tab>
            <v-tab-item key="login">
              <div class="oauth-buttons">
                <p>{{ $t("in_one_click_with") }}:</p>
                <v-btn
                  class="oauth-button"
                  :href="oauthHref('vk')"
                  @click="trackOAuthRegistration('VKontakte')"
                >
                  <img src="/img/vkontakte_icon.png" />
                  ВКонтакте
                </v-btn>

                <v-btn
                  class="oauth-button"
                  :href="oauthHref('google')"
                  @click="trackOAuthRegistration('Google')"
                >
                  <img src="/img/google_icon.png" />
                  Google
                </v-btn>
                <v-btn
                  class="oauth-button"
                  :href="oauthHref('facebook')"
                  @click="trackOAuthRegistration('Facebook')"
                >
                  <img src="/img/facebook_icon.png" />
                  Facebook
                </v-btn>
                <p>{{ $t("or_with_login_and_password") }}:</p>
              </div>
              <v-form
                @keyup.native.enter="doLogin"
                v-model="validLogin"
                lazy-validation
              >
                <v-container>
                  <v-text-field
                    v-model="login"
                    autofocus
                    :label="$t('email')"
                    required
                    :rules="loginRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    :label="$t('password')"
                    required
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="doLogin"
                    :disabled="!validLogin"
                    depressed
                    >{{ $t("login") }}</v-btn
                  >
                </v-container>
              </v-form>
            </v-tab-item>
            <v-tab-item key="register">
              <div class="oauth-buttons">
                <p>{{ $t("in_one_click_with") }}:</p>
                <v-btn
                  class="oauth-button"
                  :href="oauthHref('vk')"
                  @click="trackOAuthRegistration('VKontakte')"
                >
                  <img src="/img/vkontakte_icon.png" />
                  ВКонтакте
                </v-btn>

                <v-btn
                  class="oauth-button"
                  :href="oauthHref('google')"
                  @click="trackOAuthRegistration('Google')"
                >
                  <img src="/img/google_icon.png" />
                  Google
                </v-btn>
                <v-btn
                  class="oauth-button"
                  :href="oauthHref('facebook')"
                  @click="trackOAuthRegistration('Facebook')"
                >
                  <img src="/img/facebook_icon.png" />
                  Facebook
                </v-btn>
                <p>{{ $t("or_with_login_and_password") }}:</p>
              </div>
              <v-form
                @keyup.native.enter="doRegister"
                v-model="validRegistration"
                lazy-validation
              >
                <v-container>
                  <v-text-field
                    v-model="login"
                    autofocus
                    :label="$t('email')"
                    required
                    :rules="loginRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    :label="$t('password')"
                    required
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="password_confirm"
                    type="password"
                    :label="$t('password_confirm')"
                    required
                    :rules="password_confirmRules"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="doRegister"
                    :disabled="!validRegistration"
                    >{{ $t("register") }}</v-btn
                  >
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="3000" top>
      {{ snackMessage }}
      <v-btn color="pink" flat @click="snackbar = false">
        {{ $t("close") }}
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import ExtraPostAPI from "../api.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("login") };
  },
  name: "login",
  data() {
    return {
      login: "",
      snackMessage: "",
      snackbar: false,
      loginRules: [
        (v) => !!v || this.$i18n.t("email_is_mandatory"),
        (v) => /.+@.+/.test(v) || this.$i18n.t("wrong_email_format"),
      ],
      password: "",
      passwordRules: [
        (v) => !!v || this.$i18n.t("password_is_mandatory"),
        (v) => (v && v.length > 6) || this.$i18n.t("password_longer_6"),
      ],
      password_confirm: "",
      password_confirmRules: [
        (v) => !!v || this.$i18n.t("password_confirm_is_mandatory"),
        (v) =>
          (v && v === this.password) || this.$i18n.t("password_confirm_equal"),
      ],
      validLogin: true,
      validRegistration: true,
    };
  },
  mounted() {
    if (this.$route.query.error) {
      this.snackMessage = this.$route.query.error;
      this.snackbar = true;
    }
  },
  methods: {
    doLogin() {
      var self = this;

      ExtraPostAPI.post(
        "login",
        { login: this.login, password: this.password },
        { headers: {} }
      )
        .then((response) => {
          self.storeTokenAndLogin(response.data["token"], self.login);
          self.$ga.event("Authentication", "Login", self.login);

          // window.location.href = '/shipments'
          self.$router.push("/orders");
          // self.$router.replace('/')
        })
        .catch((error) => {
          self.snackMessage =
            error.response.data["error"] || error.response.statusText;
          self.snackbar = true;
        });
    },
    doRegister() {
      var self = this;

      ExtraPostAPI.post(
        "register",
        { login: this.login, password: this.password },
        { headers: {} }
      )
        .then((response) => {
          self.storeTokenAndLogin(response.data["token"], self.login);
          self.$ga.event("Authentication", "Register", self.login);
          window.location.href = "/shipments";
        })
        .catch((error) => {
          self.snackMessage =
            error.response.data["error"] || error.response.statusText;
          self.snackbar = true;
        });
    },
    storeTokenAndLogin(token, login) {
      localStorage.setItem("extrapost_api_access_token", token);
      localStorage.setItem("extrapost_api_login", login);
    },
    oauthHref(provider) {
      const apiHost =
        process.env.NODE_ENV !== "production"
          ? "http://localhost:5555"
          : "https://api.extrapost.ru:5433";
      const locationHost =
        window.location.protocol + "//" + window.location.host;

      return (
        apiHost +
        "/oauth/" +
        provider +
        "?state=" +
        locationHost +
        "/store_token"
      );
    },
    trackOAuthLogin(provider) {
      this.$ga.event("Authentication", "OAuth Login Start", provider);
    },
    trackOAuthRegistration(provider) {
      this.$ga.event("Authentication", "OAuth Registration Start", provider);
    },
  },
};
</script>

<style scoped>
.oauth-buttons {
  padding: 0px 16px;
  margin-top: 10px;
  margin-bottom: -15px;
}

.oauth-buttons p {
  margin-top: 15px;
  margin-bottom: 5px;
}

@media (max-width: 450px) {
  .oauth-button {
    width: 100%;
  }
}

@media (min-width: 451px) {
  .oauth-button {
    width: 30%;
  }
}

.oauth-button {
  padding-left: -3px !important;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: left;
  margin-left: 0px;
}

.oauth-button img {
  margin-right: 5px;
  width: 27px;
}
</style>
