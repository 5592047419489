<template>
  <v-sheet>
    <v-container>
      <h4 class="display-1">{{ $t("settings") }}</h4>
      <p>{{ $t("settings_explanation") }}</p>
      <v-btn v-if="nodes.length > 0" @click="loadZPLFonts()">{{
        $t("load_label_fonts")
      }}</v-btn>
      <v-radio-group v-model="defaultPrinter">
        <v-treeview :items="nodes" :open="['node']" id="nodes_tree">
          <template v-slot:prepend="{ item }">
            <v-icon>
              {{
                item.id === "node"
                  ? "computer"
                  : item.type === "scales"
                  ? "fitness_center"
                  : "print"
              }}
            </v-icon>
            <v-checkbox
              v-model="selectedScales"
              :value="item.node + ':' + item.name"
              :label="item.name"
              v-if="item.type === 'scales'"
              @change="saveSelectedScales()"
            />
            <v-radio
              :value="item.node + ':' + item.name"
              :label="item.name"
              name="printer"
              v-if="item.type === 'printer'"
              @change="setDefaultPrinter(item.node, item.name)"
            />
            <span v-if="item.type === 'printer'">
              <v-icon :title="$t('label_printer') + '?'">label</v-icon>
              <input
                class="label-printer-radio"
                type="radio"
                name="label_printer"
                :value="item.node + ':' + item.name"
                :checked="labelPrinter === item.node + ':' + item.name"
                @change="setLabelPrinter(item.node, item.name)"
              />
            </span>
          </template>
          <template v-slot:label="{ item }">
            <span v-if="item.id === 'node'">{{ item.name }}</span>
          </template>
        </v-treeview>
      </v-radio-group>

      <h5>{{ $t("other_settings") }}</h5>
      <v-checkbox
        v-if="isAdmin()"
        v-model="prohibitManualMarking"
        primary
        hide-details
        :label="$t('prohibit_manual_marking')"
        @change="saveManualMarking()"
      ></v-checkbox>
    </v-container>
  </v-sheet>
</template>

<script>
import { Socket } from "phoenix";
import OIcon from "./OIcon.vue";
import ExtraPostNodes from "../nodes.js";
import ExtraPostAPI from "../api.js";
import { globalSettings, isAdmin } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("settings") };
  },
  components: { OIcon },
  data() {
    return {
      channel: null,
      nodes: [],
      selectedScales: [],
      defaultPrinter:
        localStorage.getItem("extrapost_default_printer_node") +
        ":" +
        localStorage.getItem("extrapost_default_printer"),
      labelPrinter:
        localStorage.getItem("extrapost_label_printer_node") +
        ":" +
        localStorage.getItem("extrapost_label_printer"),
      prohibitManualMarking: null,
    };
  },
  mounted() {
    var apiToken = localStorage.getItem("extrapost_api_access_token");

    this.prohibitManualMarking =
      globalSettings(this).prohibit_manual_marking === true;

    this.selectedScales =
      JSON.parse(localStorage.getItem("extrapost_selected_scales")) || [];

    this.selectedScales = [this.selectedScales].flat();

    if (apiToken) {
      let socket = new Socket("/api/socket", {
        params: { api_access_token: apiToken },
      });
      socket.connect();

      var self = this;

      this.channel = socket.channel("nodes", {});
      this.channel
        .join()
        .receive("ok", (resp) => {
          console.log("Server nodes channel joined successfully", resp);
          self.updateNodesFromChannelResponse(resp);
        })
        .receive("error", (resp) => {
          console.log("Unable to join server nodes channel", resp);
        });

      this.channel.on("full_data", (payload) => {
        console.log("full_data from server", payload);
        self.updateNodesFromChannelResponse(payload);
        ExtraPostNodes.connectCOMPorts();
      });

      // Perspective tracking through extranodes channel with Phoenix.Presence
      var extrachannel = socket.channel("extranodes", {});
      extrachannel
        .join()
        .receive("ok", (resp) => {
          console.log("ExtraNodes channel joined successfully", resp);
          self.updateNodesFromChannelResponse(resp);
        })
        .receive("error", (resp) => {
          console.log("Unable to join ExtraNodes channel", resp);
        });

      extrachannel.on("presence_state", (state) => {
        console.log("presence_state", state);
      });

      extrachannel.on("presence_diff", (state) => {
        console.log("presence_diff", state);
      });
    }
  },
  methods: {
    updateNodesFromChannelResponse(response) {
      this.nodes = Object.keys(response).map((key) => {
        return {
          id: "node",
          name: key,
          children: [
            ...response[key].scales.map((s) => {
              return { name: s, type: "scales", node: key };
            }),
            ...response[key].printers.map((s) => {
              return { name: s, type: "printer", node: key };
            }),
          ],
        };
      });
    },
    setDefaultPrinter(node, printer) {
      console.log(node + ":" + printer);
      this.defaultPrinter = node + ":" + printer;
      localStorage.setItem("extrapost_default_printer", printer);
      localStorage.setItem("extrapost_default_printer_node", node);
      return false;
    },
    setLabelPrinter(node, printer) {
      console.log(node + ":" + printer);
      this.labelPrinterNode = node;
      this.labelPrinter = printer;
      localStorage.setItem("extrapost_label_printer", printer);
      localStorage.setItem("extrapost_label_printer_node", node);
      return false;
    },
    saveSelectedScales() {
      console.log(this.selectedScales);
      localStorage.setItem(
        "extrapost_selected_scales",
        JSON.stringify(this.selectedScales)
      );
      ExtraPostNodes.connectCOMPorts();
    },
    saveManualMarking() {
      ExtraPostAPI.post("settings", {
        prohibit_manual_marking: this.prohibitManualMarking,
      });
    },
    loadZPLFonts() {
      var document_with_fonts_loading_label_id = "loadZPLFontsRus";
      ExtraPostNodes.printLabel(document_with_fonts_loading_label_id);
    },
    isAdmin() {
      return isAdmin(this);
    },
  },
};
</script>

<style>
#nodes_tree .v-input__slot {
  margin-bottom: 0;
}

#nodes_tree .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

#nodes_tree .v-icon {
  margin-top: -7px;
}

#nodes_tree .v-input--selection-controls__input .v-icon {
  margin-top: 0px;
}

#nodes_tree .label-printer-radio {
  position: relative;
  top: -3px;
  margin-left: 3px;
}
</style>
