// Outlined material icons
<template>
  <i
    :class="iconClass()"
    :style="fontSize()"
    @click="onClick()"
    @click.middle="onClickMiddle()"
    v-on="on"
  >
    <slot></slot>
  </i>
</template>

<script>
export default {
  name: "o-icon",
  props: {
    small: Boolean,
    dark: Boolean,
    disabled: Boolean,
    on: Object,
    color: String,
  },
  methods: {
    fontSize() {
      if (this.small) {
        return "font-size: 16px";
      } else {
        return "";
      }
    },
    iconClass() {
      var cls = "v-icon material-icons-outlined";
      if (this.$listeners.click) cls += " v-icon--link";
      if (this.dark) cls += " theme--dark";
      if (this.color) cls += ` ${this.color}--text`;
      if (this.disabled) cls += " v-icon--disabled";

      return cls;
    },
    onClick(event) {
      if (!this.disabled && this.$listeners.click) {
        this.$listeners.click();
      }
    },
    onClickMiddle(event) {
      if (this.$listeners.click_middle) {
        this.$listeners.click_middle();
      }
    },
  },
};
</script>
