<template>
  <v-container>
    <h1 v-if="shipment">
      {{ $t("shipment") }} {{ shipment.reference || shipment.id }}
    </h1>
    <extra-shipment :shipment="shipment"></extra-shipment>
  </v-container>
</template>

<script>
import Shipment from "./Shipment.vue";
import ExtraPostAPI from "../api.js";
import { loadingIconOn, loadingIconOff } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("shipment") };
  },
  components: {
    "extra-shipment": Shipment,
  },
  data() {
    return {
      shipment: null,
      errors: {},
    };
  },
  watch: {
    "$route.params.shipment_id": function(shipment_id) {
      this.loadShipment();
    },
  },
  mounted() {
    this.loadShipment();
  },
  methods: {
    loadShipment() {
      if (this.$route.params.shipment_id != undefined) {
        loadingIconOn(this.$root);
        this.shipment = { line_items: [] };
        var self = this;
        ExtraPostAPI.get("shipments/" + self.$route.params.shipment_id)
          .then((response) => {
            loadingIconOff(this.$root);
            self.shipment = response.data;
          })
          .then(() => {
            loadingIconOff(this.$root);
          });
      }
    },
  },
};
</script>
