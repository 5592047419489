<template>
  <v-container>
    <v-container grid-list-md pa-0>
      <v-layout wrap>
        <v-flex key="title" md12>
          <v-text-field
            v-model="product.title"
            :label="$t('title')"
            :error-messages="errors['title']"
            height="1em"
          ></v-text-field>
        </v-flex>

        <v-flex
          v-for="field in ['weight', 'length', 'width', 'height']"
          :key="field"
          md3
          sm3
          xs6
          pr-4
          pl-0
          mt-0
          py-0
        >
          <v-text-field
            v-model="product[field]"
            :label="
              $t(field) +
              (field === 'weight' ? ` (${$t('gr')})` : ` (${$t('mm')})`)
            "
            :error-messages="errors[field]"
            height="1em"
            :suffix="field === 'weight' ? $t('gr') : $t('mm')"
          ></v-text-field>
        </v-flex>

        <v-flex
          v-for="field in [
            'skus',
            'barcodes',
            'hs_code',
            'customs_description',
          ]"
          :key="field"
          md6
          sm6
          xs12
          pr-4
          pl-0
          mt-0
          py-0
        >
          <v-combobox
            v-if="['skus', 'barcodes'].includes(field)"
            v-model="product[field]"
            attach
            chips
            deletable-chips
            small-chips
            :label="$t(field)"
            :error-messages="errors[field]"
            multiple
          ></v-combobox>

          <v-text-field
            v-else
            v-model="product[field]"
            :label="$t(field)"
            :error-messages="errors[field]"
            height="1em"
          ></v-text-field>
        </v-flex>

        <v-flex md3 sm3 xs4 pr-4 pl-0 py-0>
          <v-text-field
            :value="getPrice(product)"
            @input="
              (e) => {
                setPrice(product, e);
              }
            "
            :label="$t('price')"
            :error-messages="errors.price"
            height="1em"
          ></v-text-field>
        </v-flex>

        <v-flex md3 sm3 xs4 pr-4 pl-0 py-0>
          <v-select
            v-model="product.currency"
            :items="['RUB', 'USD', 'EUR', 'CNY']"
            :label="$t('currency')"
            :error-messages="errors.currency"
            height="1em"
          ></v-select>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 py-0>
          <v-autocomplete
            v-model="product.origin_country"
            :items="countriesSelectList()"
            :label="$t('origin_country')"
            :error-messages="errors.origin_country"
            height="1em"
          ></v-autocomplete>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 py-0>
          <v-text-field
            :value="product.extra && product.extra.slot"
            @input="
              (val) => {
                if (isNumber(val)) product.extra.slot = Number(val);
                else product.extra.slot = val;
              }
            "
            :label="$t('slot')"
            height="1em"
          ></v-text-field>
        </v-flex>

        <v-flex md6 sm6 xs12 pr-4 pl-0 py-0>
          <v-select
            :items="
              companies.map((c) => {
                return { text: c.title, value: c.id };
              })
            "
            v-model="product.company_id"
            :label="$t('company_id')"
            height="1em"
          />
        </v-flex>

        <v-flex md12 sm12 xs12 pr-4 pl-0 py-0>
          <v-textarea
            auto-grow
            rows="1"
            v-model="product.comment"
            :label="$t('comment')"
            :error-messages="errors.comment"
          ></v-textarea>
        </v-flex>

        <v-flex md6 sm6 xs12 pr-4 pl-0 mt-0 py-0>
          <v-checkbox
            class="mt-4"
            v-model="product.extra && product.extra.avia_forbidden"
            :label="$t('avia_forbidden')"
            :error-messages="errors.extra && errors.extra.avia_forbidden"
          ></v-checkbox>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 mt-0 py-0>
          <v-checkbox
            class="mt-4"
            v-model="product.extra && product.extra.paper_product"
            :label="$t('paper_product_suitable_for_letter')"
            :error-messages="errors.extra && errors.extra.paper_product"
          ></v-checkbox>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 mt-0 py-0>
          <v-checkbox
            class="mt-4"
            v-model="product.extra && product.extra.does_not_need_package"
            :label="$t('does_not_need_package')"
            :error-messages="errors.extra && errors.extra.does_not_need_package"
          ></v-checkbox>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 mt-0 py-0>
          <v-checkbox
            class="mt-4"
            v-model="product.extra && product.extra.unreadable_barcode"
            :label="$t('unreadable_barcode')"
            :error-messages="errors.extra && errors.extra.unreadable_barcode"
          ></v-checkbox>
        </v-flex>
        <v-flex md6 sm6 xs12 pr-4 pl-0 mt-0 py-0>
          <v-checkbox
            class="mt-4"
            v-model="product.archived"
            :label="$t('archived')"
            :error-messages="errors.archived"
          ></v-checkbox>
        </v-flex>
        <v-flex md12>
          <v-img
            :src="'/api/' + product.image_url"
            contain
            height="400px"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { getPrice, setPrice, isNumber } from "../functions.js";
import { countriesSelectList } from "../plugins/i18n.js";

export default Vue.component("product", {
  name: "product",
  props: {
    product: {
      type: Object,
      default: { currency: "RUB", origin_country: "RU" },
    },
    errors: Object,
  },
  created() {
    if (this.product.currency === undefined) this.product.currency = "RUB";
    if (this.product.origin_country === undefined)
      this.product.origin_country = "RU";
  },
  computed: {
    ...mapState(["companies"]),
  },
  methods: {
    countriesSelectList,
    getPrice,
    setPrice,
    isNumber,
  },
});
</script>
