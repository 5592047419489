<template>
  <v-layout row wrap>
    <v-flex
      v-for="field in [
        'api_key',
        'client_id',
        'notify_undelivered_phone',
        'notify_unfulfilled_phone',
        'telegram_notifications_chat',
        'ozon_warehouse_id',
      ]"
      :key="field"
      xs12
      sm6
      md6
      pr-4
      pl-0
    >
      <v-text-field
        v-model="$attrs.parameters[field]"
        :label="$t(field)"
      ></v-text-field>
    </v-flex>
    
    <v-checkbox
      v-for="service in [
        'update_stock',
        'update_prices',
        'trusting_acceptance',
      ]"
      :key="service"
      v-model="$attrs.parameters[service]"
      :label="$t(service)"
      hide-details
    ></v-checkbox>
  </v-layout>
</template>
<script>
import Vue from "vue";
import { isNumber } from "../functions.js";

export default Vue.component("ozon", {
  data() {
    return {};
  },
  methods: {
    isNumber,
  },
});
</script>
