<template>
  <v-sheet>
    <v-container>
      <h4 class="display-1">
        {{ $t("parcels") }}
        <sup>max {{ maxParcels }}, last {{ lastParcels }}</sup>
      </h4>
      <v-sparkline
        key="Parcels"
        :smooth="15"
        :gradient="['#f72047', '#ffd200', '#00bbff']"
        :line-width="1"
        :show-labels="false"
        :value="parcelsDistribution"
        stroke-linecap="round"
        auto-draw
        fill
        padding="0"
      ></v-sparkline>
      <h4 class="display-1">
        {{ $t("orders") }}
        <sup>max {{ maxOrders }}, last {{ lastOrders }}</sup>
      </h4>
      <v-sparkline
        key="Orders"
        :smooth="15"
        :gradient="['#f72047', '#ffd200', '#00bbff']"
        :line-width="1"
        :show-labels="false"
        :value="ordersDistribution"
        stroke-linecap="round"
        auto-draw
        fill
        padding="0"
      ></v-sparkline>
      <h4 class="display-1">
        {{ $t("products") }}
        <sup>max {{ maxProducts }}, last {{ lastProducts }}</sup>
      </h4>
      <v-sparkline
        key="Products"
        :smooth="15"
        :gradient="['#f72047', '#ffd200', '#00bbff']"
        :line-width="1"
        :show-labels="false"
        :value="productsDistribution"
        stroke-linecap="round"
        auto-draw
        fill
        padding="0"
      ></v-sparkline>
      <!-- <h4 class="display-1">
        {{ $t("value") }}
        <sup>max {{ maxValue.toLocaleString($i18n.locale) }} RUB</sup>
      </h4>
      <v-sparkline
        key="Value"
        :smooth="15"
        :gradient="['#f72047', '#ffd200', '#00bbff']"
        :line-width="1"
        :value="valueDistribution"
        stroke-linecap="round"
        auto-draw
        fill
        padding="0"
      ></v-sparkline> -->

      <v-flex v-for="user in users" :key="user.user">
        <h4 class="display-1">
          {{ user.user }}
          <sup
            >shipments
            {{
              user.stats.reduce((acc, h) => {
                return h.shipments + acc;
              }, 0)
            }}, products
            {{
              user.stats.reduce((acc, h) => {
                return h.products + acc;
              }, 0)
            }}
          </sup>
        </h4>
        <v-sparkline
          key="User"
          :smooth="15"
          :gradient="['#00bbff']"
          :line-width="1"
          :value="
            user.stats.map((h) => {
              return h.products;
            })
          "
          stroke-linecap="round"
          auto-draw
          fill
          padding="0"
        ></v-sparkline>
      </v-flex>
    </v-container>
  </v-sheet>
</template>

<script>
import ExtraPostAPI from "../api.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("dashboard") };
  },
  data() {
    return {
      checking: false,
      // Prepopulate with zeros to avoid empty path errors
      parcelsDistribution: [0, 0],
      ordersDistribution: [0, 0],
      productsDistribution: [0, 0],
      valueDistribution: [0, 0],
      maxParcels: 0,
      lastParcels: 0,
      maxOrders: 0,
      lastOrders: 0,
      maxProducts: 0,
      lastProducts: 0,
      maxValue: 0,
      users: [],
      labels: [0, 0],
    };
  },
  mounted() {
    var self = this;

    ExtraPostAPI.get("parcels/dashboard", {}).then((response) => {
      var count = 0;
      self.users = response.data.users;
      response.data.days.forEach((d) => {
        count += 1;
        // Show only each 10-th label
        if (d[0][8] === "1" && d[0][9] === "5") self.labels.push(d[0]);
        else self.labels.push(" ");

        self.parcelsDistribution.push(d[1]);
        self.valueDistribution.push(d[2] / 100);
        self.ordersDistribution.push(d[3]);
        self.productsDistribution.push(d[5]);
      });

      self.maxParcels = Math.max(...self.parcelsDistribution);
      self.maxOrders = Math.max(...self.ordersDistribution);
      self.maxProducts = Math.max(...self.productsDistribution);
      self.maxValue = Math.max(...self.valueDistribution);

      self.lastParcels =
        self.parcelsDistribution[self.parcelsDistribution.length - 1];
      self.lastOrders =
        self.ordersDistribution[self.ordersDistribution.length - 1];
      self.lastProducts =
        self.productsDistribution[self.productsDistribution.length - 1];
    });
  },
  methods: {},
};
</script>

<style scoped>
h4.display-1 {
  margin-top: 30px;
  margin-bottom: -20px;
}
</style>
