<template>
  <div>
    <v-card>
      <v-toolbar tabs id="extra-table-toolbar">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <slot name="dialog" v-bind:dialog="dialog">
          <v-dialog
            v-model="dialog"
            ref="editDialog"
            @keydown.esc="close()"
            :fullscreen="true"
            :persistent="persistEditDialog"
            scrollable
            :transition="getDialogTransition()"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="loadDefaultItem()"
                depressed
                small
                :ripple="uiAnimation"
                >{{ $t("add") }}</v-btn
              >
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon @click.native="close()" :ripple="uiAnimation">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark flat :ripple="uiAnimation" @click="save">{{
                    $t("save")
                  }}</v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text class="py-0 pr-2" style="height: 100%">
                <slot
                  name="editForm"
                  v-bind:item="editedItem"
                  v-bind:errors="itemErrors"
                >
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        sm6
                        :md4="
                          getValueLength(editedItem, field.name) <= 100 &&
                          field.fields === undefined
                        "
                        :md12="
                          getValueLength(editedItem, field.name) > 100 ||
                          field.fields
                        "
                        v-for="field in fields.filter(
                          (f) => f.editable !== false
                        )"
                        :key="field.name"
                        pr-4
                        pl-0
                      >
                        <v-select
                          v-if="field.list"
                          :items="
                            field.list.map((f) => {
                              if (f.text) return f;
                              else return { text: $t(f), value: f };
                            })
                          "
                          @input="(e) => setValue(editedItem, field.name, e)"
                          :value="
                            getValue(editedItem, field.name) || {
                              text: $t(field.default),
                              value: setValue(
                                editedItem,
                                field.name,
                                field.default
                              ),
                            }
                          "
                          :label="$t(lastField(field.name))"
                          :error-messages="itemErrors[field.name]"
                          @keyup.native.enter="save"
                        />
                        <v-data-table
                          v-else-if="field.fields"
                          :items="editedItem[field.name]"
                          :headers="fields2headers(field.fields)"
                          hide-actions
                        >
                          <template v-slot:items="props">
                            <td
                              v-for="subField in field.fields"
                              :key="subField.name"
                            >
                              {{ getValueHTML(props.item, subField.name) }}
                            </td>
                          </template>
                        </v-data-table>

                        <v-text-field
                          v-else-if="
                            getValueLength(editedItem, field.name) < 100
                          "
                          @input="(e) => setValue(editedItem, field.name, e)"
                          :value="
                            getValueHTML(editedItem, field.name) ||
                            setValue(editedItem, field.name, field.default)
                          "
                          :label="$t(lastField(field.name))"
                          :error-messages="itemErrors[field.name]"
                          @keyup.native.enter="save"
                        ></v-text-field>
                        <v-textarea
                          v-else
                          @input="(e) => setValue(editedItem, field.name, e)"
                          :value="
                            getValueHTML(editedItem, field.name) ||
                            setValue(editedItem, field.name, field.default)
                          "
                          :label="$t(lastField(field.name))"
                          :error-messages="itemErrors[field.name]"
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </slot>
              </v-card-text>
            </v-card>
          </v-dialog>
        </slot>

        <template
          v-slot:extension
          v-if="$store.state.companies.length > 1 && companiesTabs"
        >
          <v-tabs
            right
            v-model="company_num"
            color="transparent"
            @change="changeCompanyTab()"
          >
            <v-tab key="0" :ripple="uiAnimation">
              {{ $t("all") }}
              <sup>&nbsp;{{ companiesTotals() }}</sup>
            </v-tab>
            <v-tab
              v-for="comp in $store.state.companies"
              :key="comp.id"
              :ripple="uiAnimation"
            >
              {{ comp.title }}
              <sup
                title="Количество несобранных заказов → Неотправленных посылок"
                v-if="comp.orders_count > 0 || comp.shipments_count > 0"
              >
                &nbsp;{{ comp.orders_count }}&rarr;{{ comp.shipments_count }}
              </sup>
            </v-tab>
          </v-tabs>
        </template>

        <template v-if="!($store.state.companies.length > 1 && companiesTabs)">
          <slot name="header" v-bind:selected="selected"></slot>

          <v-btn
            depressed
            small
            v-if="selected.length > 0 && exportFunction"
            @click="exportFunction(selected)"
            >{{ $t("export") }}</v-btn
          >

          <v-btn
            color="error"
            depressed
            small
            v-if="selected.length > 0"
            @click="deleteSelected"
            >{{ $t("delete") }}</v-btn
          >

          <v-spacer></v-spacer>

          <span id="fast-search">
            <v-text-field
              ref="fastSearch"
              v-if="search"
              v-model="searchQuery"
              append-icon="search"
              :label="$t('fast_search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </span>
        </template>
      </v-toolbar>

      <v-toolbar
        flat
        color="transparent"
        v-if="$store.state.companies.length > 1 && companiesTabs"
      >
        <slot name="header" v-bind:selected="selected"></slot>

        <v-btn
          depressed
          small
          v-if="selected.length > 0 && exportFunction"
          @click="exportFunction(selected)"
          >{{ $t("export") }}</v-btn
        >

        <v-btn
          color="error"
          depressed
          small
          v-if="selected.length > 0"
          @click="deleteSelected"
          >{{ $t("delete") }}</v-btn
        >

        <v-spacer></v-spacer>

        <v-text-field
          id="fast-search"
          ref="fastSearch"
          v-if="search"
          v-model="searchQuery"
          append-icon="search"
          :label="$t('fast_search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-toolbar>

      <view-json-dialog
        ref="viewDialog"
        :item="editedItem"
        :persist="persistViewDialog"
        :onClose="onViewDialogClose"
      >
        <template v-slot:preview="editedItem">
          <slot name="preview" v-bind="editedItem" />
        </template>
      </view-json-dialog>

      <v-data-table
        v-model="selected"
        :headers="headers()"
        :items="items"
        :total-items="totalItems"
        :loading="loading"
        :pagination.sync="pagination"
        @update:pagination="updatePagination"
        item-key="id"
        select-all
        class="extra-table"
        :rows-per-page-items="[
          10,
          20,
          50,
          100,
          250,
          1000,
          { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
        ]"
      >
        <template v-slot:items="items">
          <tr
            :id="items.item.id"
            :class="rowClass(items.item)"
            :style="animateStyle(items.item)"
          >
            <td>
              <v-checkbox
                :ripple="uiAnimation"
                v-model="items.selected"
                primary
                hide-details
              ></v-checkbox>
            </td>
            <td>
              <a @click="showItem(items.item)">{{ items.item.id }}</a>
            </td>
            <td>{{ items.item.inserted_at | formatDate }}</td>
            <td
              v-for="field in fields.filter((f) => f.column !== false)"
              :key="field.name"
              :id="items.item.id + '.' + field.name"
              :class="'text-xs-' + field.align + ' column-' + field.name"
            >
              <!-- In production component var gets obfuscated to 'o' name -->
              <order-tooltip
                v-if="field.order_tooltip"
                :item="items.item"
                :extraTable="extraTable()"
              ></order-tooltip>
              <order-price-tooltip
                v-else-if="field.order_price_tooltip"
                :item="items.item"
                :extraTable="extraTable()"
              ></order-price-tooltip>
              <component
                v-else-if="
                  field.value &&
                  (field.value.name === 'VueComponent' ||
                    field.value.name === 'o')
                "
                v-bind:is="field.value"
                :item="items.item"
                :extraTable="extraTable()"
              ></component>

              <span
                v-else
                v-html="getValueHTML(items.item, field.value || field.name)"
                @click="
                  () => {
                    field.href && field.href(items.item)
                      ? $router.push(field.href(items.item))
                      : undefined;
                  }
                "
                :style="
                  field.href && field.href(items.item)
                    ? 'cursor: pointer; color: #00bbff;'
                    : ''
                "
              ></span>
            </td>
            <td class="justify-center layout px-0">
              <slot name="actions" v-bind:item="items.item"></slot>
              <o-icon
                small
                class="mr-2"
                @click="editItem(items.item)"
                :title="$t('edit')"
                >edit</o-icon
              >
              <o-icon
                v-if="isAdmin() || nonAdminCanDelete"
                small
                @click="deleteItem(items.item)"
                :title="$t('delete')"
                >delete</o-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import ExtraPostAPI from "../api.js";
import { isMobile, acceptableWarehouse, isAdmin } from "../functions.js";
import { Socket } from "phoenix";
import OIcon from "./OIcon.vue";
import ViewJsonDialog from "./ViewJsonDialog.vue";
import OrderTooltip from "./OrderTooltip.vue";
import OrderPriceTooltip from "./OrderPriceTooltip.vue";
import _ from "lodash";

import Oboe from "oboe";

export default Vue.component("extra-table", {
  name: "extra-table",
  props: {
    title: String,
    entity: String,
    companiesTabs: { type: Boolean, default: false },
    fields: Array,
    sortBy: { type: String, default: "inserted_at" },
    search: Boolean,
    nonAdminCanDelete: { type: Boolean, default: false },
    exportFunction: { type: Function, default: null },
    defaultItem: { type: Object, default: () => {} },
    rowClass: {
      type: Function,
      default: (i) => {
        return "";
      },
    },
    onViewDialogOpen: Function,
    onViewDialogClose: Function,
    extraParams: Object,
  },
  components: {
    OIcon,
    ViewJsonDialog,
    "order-tooltip": OrderTooltip,
    "order-price-tooltip": OrderPriceTooltip,
  },
  data() {
    return {
      items: [],
      itemsForDeletion: [],
      deletionTimeout: null,
      selected: [],
      totalItems: 0,
      searchQuery: "",
      loading: true,
      dialog: false,
      persistViewDialog: false,
      persistEditDialog: false,
      pagination: {
        sortBy: this.sortBy,
        descending: true,
        page: this.$route.query.page || 1,
        rowsPerPage: parseInt(localStorage.getItem("rowsPerPage")) || 10,
      },
      editedIndex: -1,
      editedItem: {},
      itemErrors: {},
      company_num: 0,
    };
  },
  mounted() {
    if (this.items.length === 0) {
      // Don't load data here, beacause it will be loaded in pagination handler.
      // this.loadData()
    }
    if (this.$route.query.page !== undefined) {
      console.log(this.pagination.page, "Mounted");
      this.pagination.page = parseInt(this.$route.query.page) || 1;
      console.log(this.pagination.page);
    }

    if (this.$route.query.search !== undefined)
      this.searchQuery = this.$route.query.search;

    this.joinUpdateChannel();
  },
  beforeRouteUpdate(to, from, next) {
    console.log("Going to " + to);
    next();
  },
  watch: {
    "$store.state.companies": function (_, _companies) {
      this.setCompanyTabFromParams();
    },
    pagination: {
      handler() {
        console.log(this.pagination.page, "Handler");
        this.pushRouteQuery({
          size: this.pagination.rowsPerPage,
          page: this.pagination.page,
        });
        this.loadData();
      },
      deep: true,
    },
    searchQuery: _.debounce(function (query) {
      console.log(this.pagination.page, "searchQuery");
      this.pagination.page = 1;

      this.pushRouteQuery({ page: 1, search: query });

      this.loadData();
    }, 180),
    extraParams: {
      handler(params) {
        console.log(params);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["uiAnimation"]),
    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("add")
        : this.$i18n.t("edit") + " " + this.editedItem.id;
    },
    vueRoot() {
      return this.$root;
    },
  },
  methods: {
    comp(component) {
      console.log(component);
      return component;
    },
    isAdmin() {
      return isAdmin(this);
    },
    pushRouteQuery(query) {
      // Clone the source query, as otherways it wont work because of vue router bug
      var newQuery = _.pickBy(
        Object.assign(_.clone(this.$route.query || {}), query),
        (v) => {
          return v;
        }
      );
      delete newQuery.company_id;
      this.$router.push({ query: newQuery });
    },
    headers() {
      // Fill fields
      var i18nFields = this.fields2headers(this.fields);
      return [
        { text: "ID", value: "id", sortable: true },
        { text: this.$i18n.t("date"), value: "inserted_at", sortable: true },
      ].concat(
        i18nFields.concat([{ text: this.$i18n.t("actions"), sortable: false }])
      );
    },
    fields2headers(fields) {
      return fields
        .filter((f) => f.column !== false)
        .map((f) => {
          return {
            text: this.$i18n.t(f.title || this.lastField(f.name)),
            value: f.name,
            align: f.align,
            sortable: f.sortable || false,
          };
        });
    },
    emptyErrorMessages() {
      var messages = {};
      this.fields.forEach(function (f) {
        messages[f.name] = [];
      });
      return messages;
    },
    changeCompanyTab() {
      if (this.company_num > 0) {
        var company_id = this.$store.state.companies[this.company_num - 1].id;
        this.$router.push(`/${this.entity}/comp/` + company_id);
      } else {
        this.$router.push(`/${this.entity}`);
      }
      this.pagination.page = 1;
      this.loadData();
    },
    loadData(extraParams) {
      var self = this;

      self.loading = true;

      console.log(this.$route.query.page, "loadData");
      var params = _.merge(
        this.$route.query,
        {
          size: this.$route.query.size || this.pagination.rowsPerPage,
          page: this.$route.query.page || this.pagination.page,
        },
        extraParams || {}
      );

      if (this.$route.params.company_id !== undefined)
        params.company_id = this.$route.params.company_id;

      if (this.searchQuery && this.searchQuery.length > 0)
        params.search = this.searchQuery;

      if (this.pagination.sortBy) {
        params.sort = this.pagination.sortBy;

        if (this.pagination.descending) params.desc = true;
        else params.desc = false;
      }

      var token = localStorage.getItem("extrapost_api_access_token");

      var query = Object.keys(params)
        .map((key) => {
          return key + "=" + params[key];
        })
        .join("&");

      // Load one by one for slow connections
      Oboe({
        url: "/api/v1/" + self.entity + "?" + query,
        headers: { Authorization: "Token " + token },
      })
        .start((status, headers) => {
          self.items = [];
        })
        .node(self.entity + "[0]", (first) => {
          self.items = [first];
        })
        .node(self.entity + "[*]", (item) => {
          self.items.push(item);
        })
        .node("_total", (total) => {
          self.totalItems = total;
          if (total == 0) self.items = [];
        })
        .node("total", (total) => {
          self.totalItems = total;
          if (total == 0) self.items = [];
        })
        .done(() => {
          self.loading = false;
          self.$emit("extra-table:loaded", self.company_num);
        })
        .fail((error) => {
          if (
            error.statusCode === 401 &&
            window.location.pathname !== "/login"
          ) {
            localStorage.removeItem("extrapost_api_access_token");
            window.location = "/login";
          } else {
            self.loading = false;
            self.$root.$emit(
              "snack-message",
              "Error while loading data: " +
                (error.data || error.jsonBody || error.body),
              "error"
            );
            console.log(error);
          }
        });

      // ExtraPostAPI.get(self.entity,
      //   { params:  params, responseType: 'text'  })
      //   .then(response => {
      //     self.items = response.data[self.entity]
      //     self.totalItems = response.data['total']
      //   })
      //   .then(() => {
      //     self.loading = false
      //   })
      //   .catch((error) => {
      //     self.loading = false
      //     self.$root.$emit('snack-message', 'Connection error: ' + error.response.data)
      //     // console.log(error.response.data)
      //   })
    },
    setCompanyTabFromParams() {
      if (this.$route.params.company_id !== undefined) {
        this.company_num =
          this.$store.state.companies.findIndex(
            (comp) => comp.id === this.$route.params.company_id
          ) + 1;
      }
    },
    joinUpdateChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.channel = socket.channel("updates", {});
        this.channel
          .join()
          .receive("ok", (resp) => {
            console.log("Server update channel joined successfully", resp);
          })
          .receive("error", (resp) => {
            console.log("Unable to join server update channel", resp);
          });

        this.channel.on("create:" + this.entity, (payload) => {
          console.log("ExtraTable create from API server", payload);
          self.maybeNotifyExpress(payload);

          if (
            !self.items.some((p) => {
              return p.id === payload.id;
            }) &&
            self.acceptableWarehouse(self, payload) &&
            self.matchesCurrentCompany(payload)
          ) {
            payload.timestamp = Date.now();
            self.items.unshift(payload);
          }
        });

        this.channel.on("update:" + this.entity, (payload) => {
          console.log("ExtraTable update from API server: ", payload);

          var updatee = self.items.find((p) => {
            return p.id === payload.id;
          });
          if (updatee) {
            _.merge(updatee, payload);
            self.highlightRow(updatee.id, "changed");
            var tr = document.getElementById(updatee.id);
            if (tr) {
              tr.className = self.rowClass(updatee);
            }
          }
        });

        this.channel.on("delete:" + this.entity, (payload) => {
          self.highlightRow(payload.id, "deleted");

          self.markItemForDeleteion(payload.id);
        });
      }
    },
    showItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.persistViewDialog = this.$refs.viewDialog.isOpened();
      if (this.onViewDialogOpen) this.onViewDialogOpen();
      this.$refs.viewDialog.open();
      setTimeout(() => {
        this.persistViewDialog = false;
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = _.cloneDeep(item, true);
      this.itemErrors = this.emptyErrorMessages();
      this.persistEditDialog = this.dialog;
      this.dialog = true;
      setTimeout(() => {
        this.persistEditDialog = false;
      }, 300);
    },
    deleteSingleItem(item) {
      var self = this;
      ExtraPostAPI.delete(self.entity + "/" + item.id)
        .then((response) => {
          // Delete from table, if it was not already deleted by update from websocket
          self.markItemForDeleteion(item.id);
        })
        .catch((error) => {
          console.log(error.response.data);
          self.$root.$emit("snack-message", error.response.data, "error");
        });
    },
    deleteItem(item) {
      if (confirm(this.$i18n.t("sure_to_delete"))) this.deleteSingleItem(item);
    },
    markItemForDeleteion(id) {
      if (this.itemsForDeletion.indexOf(id) === -1) {
        this.itemsForDeletion.push(id);
      }

      if (this.deletionTimeout === null) {
        this.deletionTimeout = setTimeout(() => {
          this.items = this.items.filter(
            (item) => !this.itemsForDeletion.includes(item.id)
          );

          this.itemsForDeletion = [];
          this.deletionTimeout = null;
        }, 500);
      }
    },
    deleteSelected() {
      if (confirm(this.$i18n.t("sure_to_delete"))) {
        var self = this;
        this.selected.forEach(function (item) {
          self.deleteSingleItem(item);
        });
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = _.clone(this.defaultItem, true);
        this.editedIndex = -1;
        this.itemErrors = {};
      }, 300);
    },
    closeViewDialog() {
      this.$refs.viewDialog.close();
      this.persistViewDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    loadDefaultItem() {
      console.log("defaultItem", this.defaultItem);
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    save(event) {
      // If it's direct keyboard event and no ctrl was pressed — dont exit.
      if (
        event &&
        ["keyup", "keydown"].includes(event.type) &&
        event.ctrlKey === false
      ) {
        console.log(event);
        return;
      }

      var self = this;
      var e = self.entity;
      console.log("ExtraTable save", self.editedItem);
      if (this.editedIndex > -1) {
        // Save existing entity
        ExtraPostAPI.put(e + "/" + self.editedItem.id, {
          [this.pluralToSingle(e)]: self.editedItem,
        })
          .then((response) => {
            Object.assign(self.items[self.editedIndex], response.data);
            self.close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              self.itemErrors = error.response.data.errors;
              console.log(
                "ExtraTable saving errors",
                error.response.data.errors
              );
            } else {
              console.log(error.response.data);
              self.$root.$emit("snack-message", error.response.data, "error");
            }
          });
      } else {
        // Create new entity
        ExtraPostAPI.post(e, { [this.pluralToSingle(e)]: self.editedItem })
          .then((response) => {
            // Don't insert new record, it will be done by websocket notificaiton
            // self.items.push(response.data)
            self.close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              self.itemErrors = error.response.data.errors;
              console.log(error.response.data.errors);
            } else {
              self.$root.$emit("snack-message", error.response.data, "error");
            }
          });
      }
    },
    pluralToSingle(str) {
      if (str === "companies") return "company";
      else return str.replace(/[e']?s$/, "");
    },
    highlightRow(rowId, animation) {
      var tr = document.getElementById(rowId);
      if (tr) {
        tr.style.animation = "row-" + animation + " 5s";
        setTimeout(() => {
          tr.style.animation = "";
        }, 5000);
      }
    },
    animateStyle(item) {
      if (item.timestamp) {
        var elapsed = Date.now() - item.timestamp;
        if (elapsed < 5000)
          return `animation: row-changed 5s; animation-delay: -${
            elapsed / 1000
          }s;`;
        else return "";
      } else return "";
    },
    updatePagination(pagination) {
      localStorage.setItem("rowsPerPage", pagination.rowsPerPage);
    },
    jsonTheme() {
      return localStorage.getItem("extrapost_dark_theme") === "true"
        ? "json-dark"
        : "json-light";
    },
    getValue(object, path) {
      return _.get(object, path);
    },
    getValueHTML(object, path) {
      if (path instanceof Function) {
        try {
          return path(object);
        } catch (err) {
          return err;
        }
      } else return this.getValue(object, path);
    },
    getValueLength(object, path) {
      var val = this.getValueHTML(object, path);
      return (val && val.length) || 0;
    },
    setValue(object, path, value) {
      if (value !== undefined) {
        _.set(object, path, value);
      }
      return value;
    },
    lastField(field) {
      return _.last(_.split(field, "."));
    },
    getDialogTransition() {
      return this.uiAnimation ? "dialog-transition" : "none";
    },
    isMobile() {
      return isMobile();
    },
    acceptableWarehouse(comp, item) {
      return acceptableWarehouse(comp, item);
    },
    matchesCurrentCompany(item) {
      var company_id =
        this.company_num > 0
          ? this.$store.state.companies[this.company_num - 1].id
          : null;
      return (
        this.company_num == 0 ||
        item.company_id === undefined ||
        item.company_id === company_id
      );
    },
    maybeNotifyExpress(order) {
      if(order.extra.ym_delivery_address) {
        // Sound
        var audio = new Audio('https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3');
        audio.play();
      }
    },
    companiesTotals() {
      var companies = this.$store.state.companies;
      var totalOrders = companies.reduce((total, co) => {
        return total + co.orders_count;
      }, 0);
      var totalShipments = companies.reduce((total, co) => {
        return total + co.shipments_count;
      }, 0);
      return totalOrders + "→" + totalShipments;
    },
    extraTable() {
      return this;
    },
  },
});
</script>

<style lang="scss">
@keyframes row-changed {
  from {
    background-color: #00bbff;
  }
  to {
    background-color: none;
  }
}

@keyframes row-deleted {
  from {
    background-color: red;
  }
  to {
    background-color: none;
  }
}

#extra-table-toolbar .v-toolbar__extension {
  margin-top: -48px;
  padding-left: 244px;
}

.extra-table table tbody tr {
  /* border-bottom: none !important; */
}

.extra-table tbody td {
  height: 33px !important;
  line-height: 1em; /* Make lines narrower, so they don't blow row height, when wraping into two lines */
}

.extra-table tbody td:nth-child(1),
.extra-table thead th:nth-child(1) {
  .v-input--checkbox {
    width: 24px !important;
  }
}

.extra-table tbody td:not(:nth-child(1)),
.extra-table thead th:not(:nth-child(1)) {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

#fast-search {
  color: #00bbff;
}

/* Remove table moveing when turning loading on/off  */
.v-datatable__progress {
  height: 3px !important;
}

.v-dialog {
  width: unset;
}

.jv-container {
  max-width: 500px;
}

.json-light {
  border-radius: 5px;
  background: #eee;
  white-space: nowrap;
  color: #525252;
  font-size: 12px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #00bbff;
  }
  .jv-key {
    color: #111111;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: #111111;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-object {
      color: #111111;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      margin-right: 4px;

      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}

.json-dark {
  background: #212121;
  border-radius: 5px;
  white-space: nowrap;
  color: #ccc;
  font-size: 12px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #555;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #00bbff;
  }
  .jv-key {
    color: #999;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: #999;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-object {
      color: #999;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      margin-right: 4px;

      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
