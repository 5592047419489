<template>
  <v-layout row wrap>
    <v-flex
      v-for="field in [
        'login',
        'password',
        'token',
        'post_office',
        'extra_weight',
        'contract',
      ]"
      :key="field"
      xs12
      sm6
      md6
      pr-4
      pl-0
    >
      <v-text-field
        v-model="$attrs.parameters[field]"
        :label="$t(field)"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 sm12 md12 pr-4 pl-0>
      <v-select
        v-model="$attrs.parameters.services"
        :items="services"
        item-value="value"
        item-text="label"
        attach
        chips
        :label="$t('mailing_types')"
        multiple
        hide-details
      ></v-select>
    </v-flex>

    <v-checkbox
      v-for="service in [
        'completness_checking',
        'sms_notice_recipient',
        'use_online_balance',
        'card_on_delivery',
        'create_order_2',
        'direct_label_load',
      ]"
      :key="service"
      v-model="$attrs.parameters[service]"
      :label="$t(service)"
      hide-details
    ></v-checkbox>
  </v-layout>
</template>
<script>
import Vue from "vue";

export default Vue.component("russian-post", {
  data() {
    return {
      services: [
        { value: "Letter", label: this.$i18n.t("Letter") },
        { value: "Banderol", label: this.$i18n.t("Banderol") },
        { value: "SmallPacket", label: this.$i18n.t("SmallPacket") },
        {
          value: "InternationalParcel",
          label: this.$i18n.t("InternationalParcel"),
        },
        {
          value: "InternationalParcelAvia",
          label: this.$i18n.t("InternationalParcelAvia"),
        },
        { value: "Parcel", label: this.$i18n.t("Parcel") },
        { value: "ParcelOnline", label: this.$i18n.t("ParcelOnline") },
        {
          value: "ParcelOnlineCombined",
          label: this.$i18n.t("ParcelOnlineCombined"),
        },
        { value: "CourierOnline", label: this.$i18n.t("CourierOnline") },
        { value: "FirstClassParcel", label: this.$i18n.t("FirstClassParcel") },
        { value: "EMS", label: this.$i18n.t("EMS") },
        { value: "EMSRT", label: this.$i18n.t("EMSRT") },
        { value: "EMSTender", label: this.$i18n.t("EMSTender") },
        { value: "EMSOptimal", label: this.$i18n.t("EMSOptimal") },
        {
          value: "EMSOptimalCourier",
          label: this.$i18n.t("EMSOptimalCourier"),
        },
        { value: "ECOM", label: this.$i18n.t("ECOM") },
        { value: "RetailParcel", label: this.$i18n.t("RetailParcel") },
        {
          value: "RetailFirstClassParcel",
          label: this.$i18n.t("RetailFirstClassParcel"),
        },
      ],
    };
  },
});
</script>
