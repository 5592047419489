<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">{{ $t('install_create_company') }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">{{ $t('install_create_carrier') }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">{{ $t('install_create_order') }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-5" color="grey lighten-1" height="200px"></v-card>

        <v-btn color="primary" @click="e1 = 2">{{ $t('continue') }}</v-btn>

        <v-btn flat>{{ $t('cancel') }}</v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-5" color="grey lighten-1" height="200px"></v-card>

        <v-btn color="primary" @click="e1 = 3">{{ $t('continue') }}</v-btn>

        <v-btn flat>{{ $t('cancel') }}</v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mb-5" color="grey lighten-1" height="200px"></v-card>

        <v-btn color="primary" @click="e1 = 1">{{ $t('continue') }}</v-btn>

        <v-btn flat>{{ $t('cancel') }}</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

export default {
  metaInfo () {
    return { title: this.$i18n.t('install') }
  },
  data () {
    return {
      e1: 0
    }
  }
}

</script>