import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'
import zh from 'vuetify/es5/locale/zh-Hans'
import { detectLocale } from '@/plugins/i18n'

Vue.use(Vuetify, {
  theme: {
    primary: '#00bbff',
    accent: '#00BBFF'
  },
  iconfont: 'md',
  lang: {
    locales: {
      ru,
      en,
      zh
    },
    current: detectLocale()
  }
})
