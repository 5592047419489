<template>
  <extra-cards
    :title="$t('order_sources')"
    entity="order_sources"
    :fields="fields"
    type="type"
    :templates="templates"
    search
  >
    <template v-slot:title="props"> {{ props.item.title }}</template>

    <template v-slot:fields="props">
      <v-flex xs12 sm6 md6 pr-4 pl-0>
        <v-text-field v-model="props.item.title" :label="$t('title')" />
      </v-flex>
      <v-flex md6 sm6 xs12 pr-4 pl-0>
        <v-select
          :items="
            companies.map((c) => {
              return { text: c.title, value: c.id };
            })
          "
          v-model="props.item.company_id"
          :label="$t('company_id')"
        />
      </v-flex>
    </template>

    <template v-slot:actions="props">
      <v-btn flat small @click="install(props.item)">{{ $t("install") }}</v-btn>
      <v-btn flat small @click="uninstall(props.item)">
        {{ $t("uninstall") }}
      </v-btn>
      <br />
    </template>
  </extra-cards>
</template>

<script>
import { mapState } from "vuex";
import ExtraCards from "./ExtraCards.vue";
import RetailCRM from "./RetailCRM.vue";
import Ozon from "./Ozon.vue";
import YandexMarket from "./YandexMarket.vue";
import Wildberries from "./Wildberries.vue";
import AliExpress from "./AliExpress.vue";
import ExtraPostAPI from "../api.js";
import { loadingIconOn, loadingIconOff } from "../functions.js";

export default {
  metaInfo() {
    return { title: this.$i18n.t("order_sources") };
  },
  components: { "extra-cards": ExtraCards },
  data() {
    return {
      fields: [{ name: "type" }, { name: "company" }, { name: "title" }],
      templates: {
        RetailCRM: RetailCRM, // ["apiKey", "domain", "fulfillment"],
        Shopify: ["shop_name", "api_key", "password", "location_id"],
        Bitrix24: [
          "api_key",
          "domain",
          "default_sender_taxpayer_number",
          "company_field",
          "carrier_facility_field",
          "delivery_cost_field",
          "weight_field",
          "tracking_code_field",
          "tracking_status_field",
          "delivery_product_id",
          "fulfilled_deal_status",
        ],
        WooCommerce: ["api_key", "api_secret", "domain", "tracking_url"],
        MoySklad: ["api_key"],
        Ozon: Ozon,
        YandexMarket: YandexMarket,
        AliExpress: AliExpress,
        Wildberries: Wildberries,
      },
    };
  },
  computed: {
    ...mapState(["companies"]),
  },
  methods: {
    install(order_source) {
      var self = this;
      loadingIconOn(self.$root);

      ExtraPostAPI.post(`order_sources/${order_source.id}/install`)
        .then((response) => {
          console.log(response.data);
        })
        .then(() => {
          loadingIconOff(self.$root);
        })
        .catch(() => {
          loadingIconOff(self.$root);
        });
    },
    uninstall(order_source) {
      var self = this;
      loadingIconOn(self.$root);

      ExtraPostAPI.post(`order_sources/${order_source.id}/uninstall`)
        .then((response) => {
          console.log(response.data);
        })
        .then(() => {
          loadingIconOff(self.$root);
        })
        .catch(() => {
          loadingIconOff(self.$root);
        });
    },
  },
};
</script>
