<template>
  <v-app :dark="dark">
    <v-navigation-drawer
      permanent
      :mini-variant.sync="miniNavigation"
      clipped
      app
      width="180"
      mini-variant-width="69"
      v-if="isLoggedIn()"
    >
      <v-spacer></v-spacer>
      <v-list dense class="pt-3" id="main_menu">
        <v-list-tile to="/dashboard" v-if="isAdminOrOperator()">
          <v-list-tile-avatar>
            <o-icon>trending_up</o-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("dashboard") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/orders">
          <v-list-tile-avatar>
            <o-icon>shopping_cart</o-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("orders") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/shipments">
          <v-list-tile-avatar>
            <o-icon>mail</o-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("shipments") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/documents" v-if="isAdminOrOperator()">
          <v-list-tile-avatar>
            <o-icon>note</o-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("documents") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-group :value="extraEntities" v-if="isAdminOrOperator()">
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-avatar>
                <o-icon>apps</o-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>{{ $t("more") }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile to="/batches">
            <v-list-tile-avatar>
              <o-icon>storage</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("batches") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/trackers">
            <v-list-tile-avatar>
              <o-icon>search</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("trackers") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/companies" v-if="isAdminOrStoreowner()">
            <v-list-tile-avatar>
              <o-icon>business</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("companies") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/order_sources" v-if="isAdmin()">
            <v-list-tile-avatar>
              <o-icon>input</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("order_sources") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/carrier_accounts" v-if="isAdminOrStoreowner()">
            <v-list-tile-avatar>
              <o-icon>local_shipping</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("carriers") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/products">
            <v-list-tile-avatar>
              <o-icon>local_mall</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("products") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile to="/users">
            <v-list-tile-avatar>
              <o-icon>group</o-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("users") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>

        <v-list-tile @click.stop="miniNavigation = !miniNavigation">
          <v-list-tile-avatar>
            <v-icon v-if="miniNavigation">chevron_right</v-icon>
            <v-icon v-else>chevron_left</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("collapse") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar app fixed dense clipped-left>
      <img id="logo" src="/img/extrapost_logo_en.svg" />
      <v-toolbar-title class="headline text-uppercase">
        <span>{{ $t("extra") }}</span>
        <span class="font-weight-light">{{ $t("post") }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y v-if="isLoggedIn()">
          <template v-slot:activator="{ on }">
            <v-btn flat v-on="on" :ripple="uiAnimation">
              <span class="mr-2 hidden-xs-only">{{ login() }}</span>
              <v-icon class="hidden-sm-and-up">person</v-icon>
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-tile>
              <v-list-tile-avatar id="dark_switch">
                <v-switch
                  v-model="dark"
                  :ripple="uiAnimation"
                  @change="storeDarkTheme"
                ></v-switch>
              </v-list-tile-avatar>
              <v-list-tile-title>{{ $t("dark_theme") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-avatar id="transitions_switch">
                <v-switch
                  v-model="uiAnimation"
                  :ripple="uiAnimation"
                  @change="setUIAnimation"
                ></v-switch>
              </v-list-tile-avatar>
              <v-list-tile-title>{{ $t("ui_animation") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile to="/settings">
              <v-list-tile-avatar>
                <o-icon>settings</o-icon>
              </v-list-tile-avatar>
              <v-list-tile-title>{{ $t("settings") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile-avatar>
                <o-icon>power_settings_new</o-icon>
              </v-list-tile-avatar>
              <v-list-tile-title>{{ $t("logout") }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat icon v-if="!isLoggedIn()" @click="toggleDark()">
          <v-icon>invert_colors</v-icon>
        </v-btn>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" :ripple="uiAnimation">
              <flag :iso="langs[i18n.locale].flag" :squared="false" />
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-for="(lang, index) in langs"
              :key="lang.flag"
              @click="changeLocale(index)"
            >
              <v-list-tile-avatar>
                <flag :iso="lang.flag" v-bind:squared="false" />
              </v-list-tile-avatar>
              <v-list-tile-title>{{ lang.title }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-content>
      <v-dialog
        v-model="loadingDialog"
        persistent
        content
        content-class="loading-dialog"
        :transition="uiAnimation ? 'dialog-transition' : 'none'"
      >
        <svg
          width="90%"
          height="90%"
          viewBox="0 0 540 540"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="extrapost_logo"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <polygon
              id="sparkle"
              fill="#00C5FF"
              points="235.606496 44.1220081 190.123562 13.0242792 170.062292 64.3393128 117.434166 48.0300789 113.389265 102.978322 58.2909879 102.906306 70.6225356 156.605071 17.9524 172.776274 45.5633521 220.455697 0 251.4334 40.4370099 288.856538 6.02894332 331.888526 55.7008108 355.728238 35.5039107 406.99046 89.9960522 415.129473 85.8071189 470.066914 140.277656 461.783868 152.467572 515.516241 202.077025 491.5457 229.563149 539.298339 269.902938 501.768377 310.242726 539.298339 337.72765 491.5457 387.337103 515.516241 399.52822 461.783868 453.997556 470.066914 449.808623 415.129473 504.300764 406.99046 484.103864 355.728238 533.776932 331.888526 499.367665 288.856538 539.807076 251.4334 494.242523 220.455697 521.853475 172.776274 469.18214 156.605071 481.513687 102.906306 426.415411 102.978322 422.37171 48.0300789 369.743583 64.3393128 349.681113 13.0242792 304.198179 44.1220081 269.902938 1"
            />
            <path
              d="M419.332368,247.795539 L414.115717,230.283023 L384.464395,230.283023 L374.335723,247.795539 L362.668091,247.795539 L407.324268,173 L431,247.795539 L419.332368,247.795539 Z M390.281873,220.224974 L411.121071,220.224974 L403.889015,197.496862 L390.281873,220.224974 Z M304.518402,177.216327 L303.108041,187.274376 L282.43855,187.316539 L273.950029,247.83981 L261.819234,247.795855 L270.307755,187.272584 L249.132305,187.272584 L250.54372,177.214535 L304.518402,177.216327 Z M357.42551,247.795539 L345.627594,247.83981 L330.284378,217.139677 C328.850827,217.244031 321.688974,217.269329 321.688974,217.269329 L317.408347,247.795539 L306.821149,247.795539 L316.720032,177.214219 L335.468985,177.214219 C352.672655,177.214219 357.658462,187.272268 356.263912,197.21226 C355.283616,204.210309 350.930258,211.278982 340.952319,214.859698 L357.42551,247.795539 Z M323.091956,207.270308 L326.621022,207.270308 C342.007455,207.270308 345.050589,201.683675 345.676714,197.21226 C346.303892,192.741899 344.204161,187.272268 332.844322,187.272268 L325.896868,187.272268 L323.091956,207.270308 Z M233.150632,247.795855 L220.776766,247.795855 L208.093526,222.541846 L187.515319,247.795855 L174.017696,247.795855 L202.66764,211.559684 L185.504025,177.214535 L197.848378,177.214535 L210.238056,201.985458 L229.822896,177.214535 L242.169356,177.214535 L215.026749,211.560738 L233.150632,247.795855 Z M169.837313,187.272057 L138.075719,187.272057 L135.27924,207.212123 L166.158567,207.212123 L164.748206,217.269118 L133.867824,217.269118 L130.997559,237.737279 L162.759153,237.737279 L161.348792,247.795328 L119,247.795328 L128.898882,177.214008 L171.247674,177.214008 L169.837313,187.272057 Z"
              id="Extra"
              fill="#FEFEFE"
            />
            <path
              d="M360.025841,286.473944 L425,286.473944 L425,305.966589 L403.341505,305.966589 L403.341505,373.105272 L381.684336,373.105272 L381.684336,305.966589 L360.025841,305.966589 L360.025841,286.473944 Z M317.670543,308.151121 C317.670543,321.503837 357.142857,321.868357 357.142857,348.556432 C357.142857,377.331818 311.820864,386.371048 292.857143,359.973721 L309.338656,347.896825 C317.939594,359.973721 334.099983,358.52432 334.099983,348.21361 C334.099983,336.149732 294.940115,333.437529 294.940115,307.88641 C294.940115,293.505226 310.367124,282.890749 327.10033,284.092797 C336.564832,284.769763 344.662385,289.148343 349.787363,295.371221 L334.086964,306.87964 L334.073945,306.88398 C324.99566,299.58056 317.670543,303.729145 317.670543,308.151121 Z M286.622906,329.789608 C286.622906,354.908422 265.290681,375.271121 238.975543,375.271121 C212.660405,375.271121 191.326854,354.908422 191.326854,329.789608 C191.326854,304.670793 212.660405,284.308094 238.975543,284.308094 C265.290681,284.308094 286.622906,304.670793 286.622906,329.789608 Z M262.786625,329.789608 C262.786625,316.66188 252.101944,305.975873 238.974217,305.975873 C225.843837,305.975873 215.160482,316.66188 215.160482,329.789608 C215.160482,342.918661 225.843837,353.603342 238.974217,353.603342 C252.101944,353.603342 262.786625,342.918661 262.786625,329.789608 Z M157.348481,286.541983 C178.638265,286.541983 186.321526,302.840896 186.317547,315.040212 C186.321526,327.238202 178.638265,343.537115 157.348481,343.537115 L146.655843,343.564968 L146.655843,373.10567 L125,373.10567 L125,286.483626 L132.236298,286.483626 L132.236298,286.474342 L135.754975,286.483626 L146.655843,286.483626 L146.655843,286.514131 L157.348481,286.541983 Z M166.844796,315.044191 L166.866017,315.034907 L166.844796,315.034907 C166.844796,310.631588 164.380529,305.973619 155.628269,305.973619 L146.655843,305.96964 L146.655843,324.109458 L155.628269,324.10548 C164.380529,324.10548 166.844796,319.44751 166.844796,315.044191 Z"
              id="Post"
              fill="#000000"
            />
          </g>
        </svg>
      </v-dialog>
      <keep-alive max="12" v-if="isLoggedIn()">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackTimeout"
        top
        multi-line
        auto-height
        :color="snackColor"
      >
        {{ snackMessage }}
        <v-btn flat @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-content>

    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import {
  login,
  isLoggedIn,
  isAdmin,
  isOperator,
  isStoreowner,
} from "./functions.js";
import AppFooter from "./components/AppFooter.vue";
import OIcon from "./components/OIcon.vue";
import { detectLocale } from "@/plugins/i18n";
import Moment from "moment";
import { Socket, Presence } from "phoenix";
import "moment/locale/ru";
import "moment/locale/zh-cn";

export default {
  metaInfo() {
    return {
      titleTemplate: "%s | " + this.$i18n.t("extrapost"),
    };
  },
  name: "App",
  components: {
    // AppNav,
    AppFooter,
    OIcon,
  },
  data() {
    return {
      languages: [
        { locale: "en", flag: "gb", title: "English" },
        { locale: "ru", flag: "ru", title: "Русский" },
        { locale: "zhHans", flag: "cn", title: "简体中文" },
      ],
      langs: {
        en: { flag: "gb", title: "English" },
        ru: { flag: "ru", title: "Русский" },
        zh: { flag: "cn", title: "简体中文" },
      },
      i18n: this.$i18n,
      dark: localStorage.getItem("extrapost_dark_theme") === "true",
      miniNavigation: false,
      extraEntities: false,
      extraSettings: false,
      snackbar: false,
      snackMessage: "",
      snackColor: "",
      snackTimeout: 0,
      loadingDialog: false,
      channel: null,
      updatesChannel: null,
      messagesChannel: null,
      presence: null,
    };
  },
  computed: {
    uiAnimation: {
      get: function () {
        return this.getUIAnimation();
      },
      set: function (val) {
        this.setUIAnimation(val);
      },
    },
  },
  mounted() {
    var locale = detectLocale();
    // Set locale for date-time
    Moment.locale(locale === "zh" ? "zh-cn" : locale);
    this.$root.$on("snack-message", this.onSnackMessage);
    this.$root.$on("snack-close", this.onSnackClose);
    var self = this;
    this.joinStateChannel();
    this.joinUpdateSettingsChannel();
    this.joinMessagesChannel();
    this.$router.afterEach((to, from) => {
      self.updateStateChannelLocation(to.fullPath);
    });
  },
  methods: {
    ...mapMutations(["setUIAnimation"]),
    ...mapGetters(["getUIAnimation"]),
    login() {
      return localStorage.getItem("extrapost_api_login");
    },
    isLoggedIn() {
      return localStorage.getItem("extrapost_api_access_token") !== null;
    },
    logout: function () {
      this.$ga.event(
        "Authentication",
        "Logout",
        localStorage.getItem("extrapost_api_login")
      );
      localStorage.removeItem("extrapost_api_access_token");
      localStorage.removeItem("extrapost_api_login");
      this.$store.commit("clearAll");
      this.$ga.set("userId", null);
      this.$forceUpdate();
      this.$router.push("/login");
    },
    isAdmin() {
      return isAdmin(this);
    },
    isAdminOrOperator() {
      return isAdmin(this) || isOperator(this);
    },
    isAdminOrStoreowner() {
      return isAdmin(this) || isStoreowner(this);
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$vuetify.lang.current = locale;
      // Set locale for date-time
      Moment.locale(locale === "zh" ? "zh-cn" : locale);
      // Set lang cookie for ten years
      this.$cookie.set("currentLang", locale, 10 * 365);
    },
    toggleDark() {
      this.dark = !this.dark;
      localStorage.setItem("extrapost_dark_theme", this.dark);
    },
    storeDarkTheme() {
      localStorage.setItem("extrapost_dark_theme", this.dark);
    },
    onSnackMessage(message, color, timeout) {
      console.log("Snack message received: " + message);
      this.snackMessage = message;

      if (color !== undefined) this.snackColor = color;
      else this.snackColor = "";

      if (timeout !== undefined) this.snackTimeout = timeout;
      else this.snackTimeout = 0;

      this.snackbar = true;
    },
    onSnackClose() {
      console.log("Snack close received.");
      this.snackbar = false;
    },
    joinMessagesChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.messagesChannel = socket.channel("messages", {});

        this.messagesChannel
          .join()
          .receive("ok", (resp) => {
            console.log("Messages channel joined successfully", resp);
          })
          .receive("error", (resp) => {
            console.log("Unable to join messages channel", resp);
          });

        this.messagesChannel.on("message", (payload) => {
          console.log("Message from API server: ", payload);

          var curUser = self.$store.state.currentUser;
          if (
            payload.to === null ||
            payload.to === curUser.email ||
            (curUser.extra.warehouse &&
              payload.to === curUser.extra.warehouse) ||
            (curUser.extra.warehouse === null && payload.to !== null)
          )
            self.$root.$emit("snack-message", payload.message, payload.type);
        });
      }
    },
    joinUpdateSettingsChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.updatesChannel = socket.channel("updates", {});

        this.updatesChannel
          .join()
          .receive("ok", (resp) => {
            console.log("Settings update channel joined successfully", resp);
          })
          .receive("error", (resp) => {
            console.log("Unable to join settings update channel", resp);
          });

        this.updatesChannel.on("update:settings", (payload) => {
          console.log("Settings update from API server: ", payload);

          self.$store.commit("setSettings", payload);
        });
      }
    },
    loadSettings() {
      var self = this;
      ExtraPostAPI.get("settings").then((response) => {
        self.$store.commit("setSettings", response.data);
      });
    },
    loadCurrentUser() {
      var self = this;
      ExtraPostAPI.get("current_user").then((response) => {
        self.$store.commit("setCurrentUser", response.data);
      });
    },
    joinStateChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.channel = socket.channel("state", {});

        this.presence = new Presence(this.channel);
        this.presence.onSync(() => {
          // self.presence.list((id, {metas: metas}) => { if (metas !== undefined) console.log({id, metas}) })
          self.checkSimultaneousFulfillment(self.presence);
        });

        this.channel
          .join()
          .receive("ok", (response) => {
            console.log("Server state channel joined successfully", response);
            self.$store.state.serverState = response;
          })
          .receive("error", (resp) => {
            console.log("Unable to join server state channel", resp);
          });

        this.channel.on("update", (payload) => {
          // console.log('Update state from api server', payload)
          self.$store.state.serverState = payload;
        });
      }
    },
    updateStateChannelLocation(url) {
      this.channel.push("current_url", { url: url });
    },
    checkSimultaneousFulfillment(presence) {
      if (this.$router.currentRoute.fullPath.startsWith("/fulfillment")) {
        var usersOnThisPage = new Array();
        presence.list((id, { metas: metas }) => {
          if (metas !== undefined) {
            metas.forEach(({ email: email, current_url: url }) => {
              if (url === this.$router.currentRoute.fullPath) {
                usersOnThisPage.push(email);
              }
            });
          }
        });

        if (usersOnThisPage.length > 1) {
          this.onSnackMessage(
            "Этот заказ собирают несколько пользователей: " +
              usersOnThisPage.join(", "),
            "warning"
          );
        }
      }
    },
  },
};
</script>

<style>
img#logo {
  width: 37px;
}

div#app.theme--light {
  background-image: url("/img/bg_pattern.png");
  background-attachment: fixed;
}

/* Workaround for bug which adds 24px bottom margin at production. */
#dark_switch .v-input__slot {
  margin-bottom: 0px !important;
}

#main_menu .v-list__tile__avatar {
  min-width: 33px;
}

.loading-dialog {
  width: 120px;
  height: 120px;

  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  scrollbar-width: none;
}

#sparkle {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
  transform-origin: 270px 270px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
