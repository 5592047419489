<template>
  <v-container>
    <h1>{{ address.name }}</h1>

    <v-text-field
      v-model="address.name"
      :label="$t('name')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.email"
      :label="$t('email')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.phone"
      :label="$t('phone')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.country"
      :label="$t('country')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.zip"
      :label="$t('zip')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.region"
      :label="$t('region')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field
      v-model="address.city"
      :label="$t('city')"
      @keyup.native.enter="save()"
    ></v-text-field>
    <v-text-field v-model="address.street" :label="$t('street')"></v-text-field>
    <v-text-field
      v-model="address.carrier_facility"
      :label="$t('carrier_facility')"
    ></v-text-field>
  </v-container>
</template>

<script>
import Vue from "vue";
import { isMobile } from "../functions.js";
import ExtraPostAPI from "../api.js";

export default Vue.component("extra-address", {
  name: "extra-address",
  props: { address: Object },
  components: {},
  data() {
    return {
      on: false
    };
  },
  computed: {},
  methods: {
    close() {},
    save() {
      console.log(this.address.city);
      var self = this;
      ExtraPostAPI.put("addresses/" + this.address.id, {
        address: this.address
      })
        .then(response => {
          self.close();
        })
        .catch(error => {
          if (error.response.data.errors) {
            self.itemErrors = error.response.data.errors;
            console.log(error.response.data.errors);
          } else {
            console.log(error.response.data);
            self.$root.$emit("snack-message", error.response.data, "error");
          }
        });
    },
    isMobile() {
      return isMobile();
    }
  }
});
</script>
