import { Socket } from "phoenix";
import _ from "lodash";
import ExtraPostAPI from "./api.js";

const ExtraPostNodes = {
  channel: null,
  printDocument(document_id, node = null, printer = null, pages = null) {
    if (document_id.startsWith("#local_html:")) {
      // Load document
      var doc_id = document_id.split(":")[2];
      ExtraPostAPI.get(`documents/${doc_id}`).then((response) => {
        var html = response.data["document"];
        console.log(html);
        var printWindow = document.getElementById("print").contentWindow;
        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.print();
      });
      // Print with JS from browser
    } else {
      var params = { document_id: document_id };
      if (pages) params.pages = pages;
      return this.printCommand("print", params, node, printer);
    }
  },
  printDocuments(list_of_ids) {
    if (list_of_ids !== undefined) {
      list_of_ids.forEach((document_id) => {
        this.printDocument(document_id);
      });
    }
  },
  printLabel(document_id) {
    return this.printDocument(
      document_id,
      this.defaultLabelPrinterNode(),
      this.defaultLabelPrinter()
    );
  },
  printLabels(list_of_ids) {
    if (list_of_ids !== undefined) {
      list_of_ids.forEach((document_id) => {
        this.printLabel(document_id);
      });
    }
  },
  printInvoice(order_id, node = null, printer = null) {
    return this.printCommand(
      "print_invoice",
      { order_id: order_id },
      node,
      printer
    );
  },
  printCommand(command, params, node = null, printer = null) {
    printer = printer || this.defaultPrinter();
    node = node || this.defaultPrinterNode();

    var push = this.channel.push(
      command,
      _.merge({ node: node, printer: printer }, params),
      10000
    );

    return new Promise((resolve, reject) => {
      push.receive("ok", resolve);
      push.receive("error", reject);
      push.receive("timeout", reject);
    });
    // .receive("ok", msg => console.log("created message", msg))
    // .receive("error", reasons => console.log("create failed", reasons))
    // .receive("timeout", () => console.log("Timeout"));
  },
  onWeight(handler) {
    if (this.channel) this.channel.on("weight", handler);
    else console.log("Nodes channel is not initialized.");
  },
  defaultPrinter() {
    return localStorage.getItem("extrapost_default_printer");
  },
  defaultPrinterNode() {
    return localStorage.getItem("extrapost_default_printer_node");
  },
  defaultLabelPrinter() {
    return localStorage.getItem("extrapost_label_printer");
  },
  defaultLabelPrinterNode() {
    return localStorage.getItem("extrapost_label_printer_node");
  },
  selectedScales() {
    return JSON.parse(localStorage.getItem("extrapost_selected_scales"));
  },
  connectCOMPorts() {
    (this.selectedScales() || []).forEach((scale) => {
      var [node, port] = scale.split(":");
      this.channel.push("connect_com_port", { node: node, port: port });
    });
  },
};

var apiToken = localStorage.getItem("extrapost_api_access_token");

if (apiToken) {
  let socket = new Socket("/api/socket", {
    params: { api_access_token: apiToken },
  });
  socket.connect();

  ExtraPostNodes.channel = socket.channel("nodes", {});
  ExtraPostNodes.channel
    .join()
    .receive("ok", (resp) => {
      console.log("Server nodes channel joined successfully", resp);
      ExtraPostNodes.connectCOMPorts();
    })
    .receive("error", (resp) => {
      console.log("Unable to join server nodes channel", resp);
    });
}

export default ExtraPostNodes;
