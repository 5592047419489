<template>
  <v-layout row wrap v-if="order.extra">
    <v-dialog
      v-model="editDialog"
      ref="editDialog"
      @keydown.esc="closeDialog()"
      :fullscreen="true"
      scrollable
      transition="none"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click.native="closeDialog()" :ripple="uiAnimation">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("order") }} {{ order.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat :ripple="uiAnimation" @click="saveOrder()">{{
              $t("save")
            }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <extra-order
          ref="editOrder"
          :order="order"
          :errors="orderErrors"
        ></extra-order>
      </v-card>
    </v-dialog>

    <v-progress-linear
      id="fulfillment_progress"
      :value="
        (serverState.fulfilled_orders /
          (serverState.fulfilled_orders + serverState.unfulfilled_orders)) *
        100
      "
      height="7"
    >
      &nbsp;{{ serverState.fulfilled_orders }} {{ $t("from") }}
      {{ serverState.unfulfilled_orders + serverState.fulfilled_orders }}
      {{ $t("orders_fulfilled") }}
    </v-progress-linear>
    <v-flex md7 sm6>
      <v-container fluid id="fulfillment_header">
        <h1 :class="order.priority > 0 ? 'priority' : ''">
          {{ $t("order") }}
          {{ order.reference || $route.params.order_id }} ({{
            order.company.title
          }})
          <v-btn icon @click="openDialog()"
            ><v-icon color="#00bbff">edit</v-icon></v-btn
          >
        </h1>
        <h2>
          {{ $t(order.carrier) }}
          {{ $t(order.service) }}
        </h2>
        <div>
          {{ order.to_address.name }}
          &nbsp;&nbsp;
          <span v-if="order.to_address.phone">
            {{ order.to_address.phone }}
            <o-icon
              v-if="order.to_address.country === 'RU'"
              small
              class="mr-2"
              @click="callClient()"
              :title="$t('call_client')"
              >phone</o-icon
            >
          </span>
          &nbsp;&nbsp;
          <strong>{{ order.inserted_at | formatTimestamp }}</strong>
        </div>
        <div v-if="order.to_address.country">
          <span
            :class="
              'flag-icon flag-icon-' + order.to_address.country.toLowerCase()
            "
            :title="countryName(order.to_address.country)"
          ></span>
          {{ fullAddress(order.to_address) }}
          <span v-html="deliveryLimitIcon(order, true)"></span>
        </div>
        <!-- <div v-if="order.comment" id="comment">
          <o-icon>chat_bubble_outline</o-icon>
          <span contenteditable v-text="order.comment"></span>
          </div>-->
        <v-textarea
          id="comment"
          style="margin-bottom: -30px"
          :value="order.comment"
          @input="updateOrderComment"
          auto-grow
          prepend-icon="chat_bubble_outline"
          flat
          solo
          background-color="transparent"
          rows="1"
          :success="commentSuccess"
          :error="commentError"
        ></v-textarea>
      </v-container>
    </v-flex>
    <v-flex md2 sm2 pt-3>
      <v-container>
        <img
          id="carrier_logo"
          :src="'/img/logos/' + order.carrier + '.svg'"
          width="100%"
        /> </v-container
    ></v-flex>
    <v-flex md3 sm4>
      <v-layout align-end fill-height>
        <v-container>
          <!-- <v-text-field
              v-model="searchQuery"
              append-icon="search"
              :label="$t('fast_search')"
              single-line
              clearable
              hide-details
            ></v-text-field> -->

          <div id="weight" :style="{ color: weightColor }">
            <span contenteditable="true" v-on:keydown.enter="onWeightEnter">
              {{ weight }}
            </span>
            <span>kg</span>
          </div>
        </v-container>
      </v-layout>
    </v-flex>
    <v-flex md9 sm8 v-on:keydown.space="markLineItemOnSpace">
      <v-container>
        <v-data-table
          :headers="[
            { sortable: false, width: '10px' },
            { text: $t('sku'), value: 'product_sku' },
            { text: $t('barcode'), value: 'product_barcode' },
            { value: 'product.image' },
            {
              text: $t('product_title'),
              value: 'product_title',
              width: '500px',
            },
            { text: $t('quantity'), value: 'quantity', align: 'right' },
            {
              text: $t('price'),
              value: 'price',
              width: '100px',
              align: 'right',
            },
            { sortable: false, width: '10px' },
          ]"
          :items="order.line_items"
          item-key="product_sku"
          hide-actions
          disable-initial-sort
          :key="totalFulfilled"
          id="line_items_table"
        >
          <template v-slot:items="props">
            <tr
              :id="'li' + barcodes(props.item)[0]"
              :key="props.item.id"
              class="line_item"
              :quantity="props.item.quantity"
              :style="lineItemRowStyle(props.item)"
            >
              <td class="arrow-data">
                <v-btn
                  flat
                  icon
                  :disabled="isUnFulfilled(props.item)"
                  @click="markLineItemUnFulfilled(props.item)"
                >
                  <v-icon>chevron_left</v-icon>
                </v-btn>
              </td>
              <td>
                {{
                  props.item.product_sku ||
                  (props.item.product && props.item.product.skus[0])
                }}
              </td>
              <td>{{ barcodes(props.item).join(", ") }}</td>
              <td class="text-xs-center">
                <v-tooltip
                  v-if="props.item.product && props.item.product.image_id"
                  content-class="product-image-tooltip"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <img
                      height="32px"
                      :src="`/api/${props.item.product.image_url}`"
                      v-on="on"
                    />
                  </template>
                  <img
                    :src="`/api/${props.item.product.image_url}`"
                    style="height: 100%"
                  />
                </v-tooltip>
              </td>
              <td>
                {{
                  (props.item.product && props.item.product.title) ||
                  props.item.product_title
                }}
                <span
                  v-if="props.item.stock !== undefined"
                  :class="
                    props.item.stock > props.item.quantity
                      ? 'grey--text'
                      : 'red--text'
                  "
                  title="Остаток товара в МоёмСкладе"
                >
                  {{
                    `&nbsp;&nbsp;{${props.item.stock}${
                      props.item.coming > 0 ? ", ждём " + props.item.coming : ""
                    }\}`
                  }}
                </span>
                <strong
                  v-if="props.item.product"
                  title="Ячейка в которой хранится товар"
                  >&nbsp;&nbsp;[
                  <span
                    contenteditable="true"
                    style="min-width: 2em; display: inline-block"
                    v-on:keydown.enter="
                      onSlotEnter(props.item.product.id, $event)
                    "
                  >
                    {{ props.item.product.extra.slot || "   " }} </span
                  >]
                </strong>
                <div
                  v-if="props.item.product && props.item.product.comment"
                  class="caption grey--text"
                >
                  {{ props.item.product.comment }}
                </div>
              </td>
              <td class="text-xs-right">
                <v-btn
                  :loading="reportingLineItemId === props.item.id"
                  :disabled="exhaustedItems.includes(props.item.id)"
                  :color="exhaustedItems.includes(props.item.id) ? 'error' : ''"
                  flat
                  icon
                  @click="productExhausted(props.item)"
                  title="Сообщить о том, что товар закончился."
                >
                  <o-icon small>{{ exhaustedIcon(props.item.id) }}</o-icon>
                </v-btn>
                {{ props.item.quantity }}
              </td>
              <td class="text-xs-right">
                {{
                  (props.item.price / 100).toLocaleString($i18n.locale) +
                  "&nbsp;" +
                  $t(order.currency + "_sign")
                }}
              </td>
              <td class="arrow-data">
                <v-btn
                  flat
                  icon
                  :disabled="
                    isFulfilled(props.item) ||
                    prohibitManualMarking(props.item.product) ||
                    exhaustedItems.includes(props.item.id)
                  "
                  @click="markLineItemFulfilled(props.item)"
                >
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <td colspan="4"></td>
            <td class="text-xs-right">{{ $t("total") }}:</td>
            <td class="text-xs-right">
              {{
                order.line_items.reduce((acc, li) => {
                  return acc + li.quantity;
                }, 0)
              }}&nbsp;{{ $t("pcs") }}
            </td>
            <td class="text-xs-right">
              {{
                (
                  order.line_items.reduce((acc, li) => {
                    return acc + li.quantity * li.price;
                  }, 0) / 100
                ).toLocaleString($i18n.locale)
              }}&nbsp;{{ $t(order.currency + "_sign") }}
            </td>
            <td></td>
          </template>
        </v-data-table>
      </v-container>
    </v-flex>

    <v-flex md3 sm4>
      <h1 v-if="order.archived">{{ $t("archived") }}</h1>
      <v-container id="control_panel">
        <div id="fulfillment_buttons" v-if="!order.archived">
          <v-btn
            v-if="!isMarketplace(order.carrier)"
            large
            right
            block
            :ripple="uiAnimation"
            :loading="printingInvoice"
            @click="printInvoice()"
            ref="invoice_button"
            id="invoice_button"
            class="mb-3"
          >
            <v-icon left dark>print</v-icon>
            <span v-if="isInternational(order)">{{
              $t("invoice_invoice")
            }}</span>
            <span v-else>{{ $t("invoice") }}</span>
          </v-btn>

          <v-tooltip left>
            <v-btn
              class="mb-3"
              large
              right
              block
              @click="oneMoreParcel()"
              :depressed="fulfilled"
              :disabled="
                noFulfilledLineItems ||
                !hasUnfulfilledLineItems ||
                needsWeight() ||
                orderIsFulfilled() ||
                !scannedAndUnweighted ||
                order.extra.forbid_multiparcel
              "
              :ripple="uiAnimation"
              slot="activator"
            >
              <v-icon left dark>exposure_plus_1</v-icon>
              {{ $t("parcel") }}
            </v-btn>
            Если заказ не помещается в одну упаковку, взвешивайте каждую
            упаковку отдельно, нажимайте эту кнопку, а в конце нажмите кнопку
            "СОБРАН".
          </v-tooltip>

          <v-btn
            large
            class="mb-3"
            :color="fulfilled ? 'success' : 'primary'"
            right
            block
            @click="fulfill()"
            :loading="buying"
            :depressed="fulfilled"
            :disabled="
              hasUnfulfilledLineItems || needsWeight() || orderIsFulfilled()
            "
            :ripple="uiAnimation"
          >
            <v-icon left dark>{{ fulfill_icon }}</v-icon>
            {{ $t("fulfilled") }}
          </v-btn>
        </div>

        <v-btn
          color="primary"
          large
          right
          block
          :ripple="uiAnimation"
          @click="nextOrder()"
          id="next_button"
        >
          <v-icon left dark>fast_forward</v-icon>
          {{ $t("next") }}
        </v-btn>
        <v-tooltip left>
          <v-switch
            v-if="false"
            prepend-icon="mic"
            v-model="speechRecognitionOn"
            :ripple="uiAnimation"
            @change="initSpeechRecognition()"
            :label="$t('speech_interface')"
            slot="activator"
            :color="unexpectedSpeech ? 'error' : 'primary'"
          ></v-switch>
          <span>Работает в браузерах Хром и Андроид ВебВью.</span>
          <br />
          <span>Доступные команды:</span>
          <ul>
            <li>Дальше/Следующий/Далее/Вперёд</li>
            <li>Назад</li>
            <li>Вес [1234]</li>
            <li>Собран/Готов</li>
            <li>Ярлык</li>
            <li>Накладная</li>
            <li>Закрыть</li>
            <li>Комментарий [текст комментария]</li>
          </ul>
        </v-tooltip>
        <v-switch
          prepend-icon="bolt"
          color="warning"
          v-model="turboMode"
          :ripple="uiAnimation"
          :label="$t('turbo_mode')"
        ></v-switch>
        <h4 v-if="parcels.length > 0">
          {{ $t("parcels") }}
        </h4>
        <v-chip
          v-for="p in parcels"
          :key="parcels.indexOf(p)"
          close
          @input="
            (e) => {
              parcels.splice(parcels.indexOf(p), 1);
            }
          "
        >
          <v-avatar class="primary">{{ parcels.indexOf(p) + 1 }}</v-avatar>
          {{ p.weight / 1000 }} {{ $t("kg") }}
        </v-chip>

        <h2 v-if="order.shipments.length > 0" class="mb-3">
          {{ $t("existing_shipments") }}
        </h2>

        <v-chip
          v-for="sh in order.shipments"
          :key="sh.id"
          close
          @input="
            (e) => {
              deleteShipment(sh);
            }
          "
        >
          <v-avatar
            v-if="sh.tracking_code != null"
            v-html="'&nbsp;' + carrierImageTag(sh.selected_rate.carrier)"
          >
          </v-avatar>
          <v-avatar v-else class="error">E</v-avatar>
          <a>{{ sh.tracking_code || sh.id }}</a>
        </v-chip>

        <v-btn
          v-if="order.shipments.length > 0"
          class="mt-3"
          large
          round
          color="error"
          right
          block
          :ripple="uiAnimation"
          :loading="printingLabel"
          @click="printLabel()"
          :disabled="shipment === null || shipment.label_id === null"
          id="label_button"
        >
          <v-icon left dark>print</v-icon>
          {{ shipment === null ? $t("label") : $t("repeat_label") }}
        </v-btn>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { Socket } from "phoenix";
import ExtraPostNodes from "../nodes.js";
import {
  globalSettings,
  countryName,
  loadingIconOn,
  loadingIconOff,
  deliveryLimitIcon,
  callClient,
  isMarketplace,
} from "../functions.js";
import ExtraPostAPI from "../api.js";
import OIcon from "./OIcon.vue";
import Order from "./Order.vue";
import { carrierImageTag, fullAddress, isInteger } from "../functions.js";
import { mapState } from "vuex";
import _ from "lodash";
import Moment from "moment";

export default {
  metaInfo() {
    return { title: this.$i18n.t("fulfillment") };
  },
  name: "fulfillment",
  components: {
    OIcon,
    "extra-order": Order,
  },
  data() {
    return {
      editDialog: false,
      channel: null,
      searchQuery: "",
      weight: 0.0,
      weightColor: "#00bbff",
      receivedWeight: 0,
      order: { line_items: [] },
      orderErrors: {},
      shipment: null,
      buying: false,
      printingInvoice: false,
      printingLabel: false,
      fulfilled: false,
      totalFulfilled: 0, // Used solely to re-render table component on change
      hasUnfulfilledLineItems: true,
      noFulfilledLineItems: true,
      scannedAndUnweighted: false,
      expectedWeight: -1,
      fulfill_icon: "golf_course",
      commentSuccess: false,
      commentError: false,
      turboMode: false,
      speechRecognitionOn:
        localStorage.getItem("extrapost_speech_recognition") === "true",
      speechRecognition: null,
      isSpeechRecognized: false,
      unexpectedSpeech: false,
      reportingLineItemId: null,
      exhaustedItems: [],
      parcels: [],
      products: {},
    };
  },
  watch: {
    "$route.params.order_id": function (order_id) {
      if (order_id !== undefined) {
        this.weight = 0;
        this.weightColor = "#00bbff";
        this.buying = false;
        this.fulfilled = false;
        this.hasUnfulfilledLineItems = true;
        this.noFulfilledLineItems = true;
        this.parcels = [];
        this.fulfill_icon = "golf_course";
        this.loadOrder();
        this.initSpeechRecognition();

        // Clear rows background

        var liTable = document.getElementById("line_items_table");

        if (liTable) {
          var rows = liTable.getElementsByTagName("tr");

          for (var i = 0; i < rows.length; i++)
            rows[i].style.backgroundImage = "";
        }
      }
    },
    searchQuery: _.debounce(function (query) {
      console.log("searchQuery", query);
      this.findAndRedirectToOrder(query);
    }, 180),
  },
  computed: {
    ...mapState([
      "uiAnimation",
      "ordersFulfilledToday",
      "totalUnfulfilledOrders",
      "serverState",
    ]),
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (this.speechRecognition !== null) {
      this.speechRecognition.stop();
      this.speechRecognition = null;
    }
    next();
  },
  mounted() {
    ExtraPostNodes.onWeight(this.onWeight);
    this.loadOrder();
    this.joinUpdatesChannel();
    this.initSpeechRecognition();
    var self = this;
  },
  methods: {
    carrierImageTag,
    deliveryLimitIcon,
    isInteger,
    isMarketplace,
    onWeightEnter(e) {
      e.preventDefault();
      this.setWeight(e.target.innerText.replace(",", "."));
    },
    onWeight(payload) {
      console.log("Weight from api server", payload);
      var selectedScales =
        JSON.parse(localStorage.getItem("extrapost_selected_scales")) || [];
      console.log(selectedScales);
      if (
        selectedScales.includes(payload.node + ":" + payload.scales) ||
        payload.scales === "button"
      ) {
        this.setWeight(payload.weight);
      }
    },
    setWeight(weight) {
      // Remove all spaces from incoming weight before parsing.
      var oldWeight = this.weight;
      if (typeof weight === "string")
        this.weight = parseFloat(weight.replace(/ /g, ""));
      else this.weight = weight;

      if (this.orderIsFulfilled()) {
        if (!this.turboMode)
          this.$root.$emit(
            "snack-message",
            this.$i18n.t("cannot_fulfill_fulfilled_order"),
            "error"
          );
      } else if (
        this.turboMode &&
        oldWeight == 0.0 &&
        !this.hasUnfulfilledLineItems
      ) {
        // Press "Fulfilled" button
        this.fulfill();
      }

      const PACKAGE_WEIGHT_ESTIMATE = 300;
      const WEIGHT_CHANGE_THRESHOLD = 25;

      if (this.expectedWeight > 0) {
        if (
          this.weight * 1000 < this.expectedWeight - WEIGHT_CHANGE_THRESHOLD &&
          !this.hasUnfulfilledLineItems &&
          this.parcels.length == 0
        )
          this.weightColor = "darkorange";
        // alert(
        //   this.$i18n.t("received_weight_less_than_expected", {
        //     received_weight: this.weight * 1000,
        //     expected_weight: this.expectedWeight,
        //   })
        // );
        else if (
          this.weight * 1000 >
          this.expectedWeight + PACKAGE_WEIGHT_ESTIMATE
        )
          this.weightColor = "red";
        // alert(
        //   this.$i18n.t("received_weight_greater_than_expected", {
        //     received_weight: Math.round(this.weight * 1000),
        //     expected_weight: this.expectedWeight,
        //   })
        // );
        else this.weightColor = "green";
      }
    },
    onSlotEnter(product_id, e) {
      e.preventDefault();
      var slot = e.target.innerText;
      if (isInteger(slot)) slot = parseInt(slot);

      // Update product
      let span = e.target;
      ExtraPostAPI.put(`products/${product_id}`, {
        product: { extra: { slot: slot } },
      }).then((response) => {
        span.blur();
        span.style.color = "green";
      });
    },
    loadOrder() {
      if (this.$route.params.order_id != undefined) {
        loadingIconOn(this.$root);
        this.order = { line_items: [] };
        var self = this;
        ExtraPostAPI.get(
          "orders/" + self.$route.params.order_id + "?with_stock=true"
        )
          .then((response) => {
            if (
              response.data.company_id !== self.order.company_id &&
              self.order.company_id !== undefined
            )
              self.$root.$emit(
                "snack-message",
                self.$i18n.t("sender_change", {
                  sender: response.data.company.title,
                }),
                "warning",
                3000
              );

            loadingIconOff(this.$root);
            self.order = response.data;
            self.expectedWeight = self.order.line_items.reduce((acc, li) => {
              return (
                acc +
                ((li.product && li.product.weight) || li.product_weight) *
                  li.quantity
              );
            }, 0);
            self.shipment =
              self.order.shipments.length > 0 ? self.order.shipments[0] : null;
          })
          .then(() => {
            loadingIconOff(this.$root);
            self.hasUnfulfilledLineItems = self.order.line_items.length > 0;
            self.noFulfilledLineItems = self.order.line_items.length > 0;
            self.commentSuccess = false;
            self.commentError = false;
          });
      }
      this.updateStats();
    },
    initSpeechRecognition() {
      localStorage.setItem(
        "extrapost_speech_recognition",
        this.speechRecognitionOn
      );

      try {
        if (this.speechRecognitionOn) {
          if (this.speechRecognition === null) {
            var self = this;
            var SpeechGrammarList =
              window.SpeechGrammarList || window.webkitSpeechGrammarList;
            var SpeechRecognition =
              window.SpeechRecognition || window.webkitSpeechRecognition;

            var grammar =
              "#JSGF V1.0; grammar commands; public <command> = накладная | ярлык | собран | следующий | дальше | далее | назад | вперед | вперёд | собран | вес | комментарий ;";
            var speechRecognitionList = new SpeechGrammarList();
            speechRecognitionList.addFromString(grammar, 1);

            this.speechRecognition = new SpeechRecognition();
            this.speechRecognition.lang = "ru-RU";
            this.speechRecognition.continuous = false;
            this.speechRecognition.interimResults = true;
            this.speechRecognition.grammars = speechRecognitionList;

            this.speechRecognition.onresult = function (event) {
              // var speech = event.results[0][0].transcript
              console.log(event.results);
              var lastResult = event.results[event.results.length - 1];

              self.unexpectedSpeech = false;

              if (self.isSpeechRecognized) return;

              var speech = lastResult[0].transcript.trim();
              var confidence = lastResult[0].confidence;
              console.log(speech);
              speech = speech.toLowerCase();

              if (lastResult.isFinal) {
                if (speech.includes("вес")) {
                  self.weight = parseInt(speech.replace(/\D/g, "")) / 1000;
                } else if (speech.startsWith("коммент")) {
                  var commentPrefix = "";
                  if (self.order.comment && self.order.comment.length > 0)
                    commentPrefix = "\n";
                  self.doUpdateOrderComment(
                    (self.order.comment || "") +
                      commentPrefix +
                      speech
                        .replace("комментарий ", "")
                        .replace("комментарии ", "")
                  );
                }
              } else {
                if (
                  speech.startsWith("дал") ||
                  speech.startsWith("след") ||
                  speech.includes("вперед") ||
                  speech.includes("вперёд") ||
                  speech.includes("дальше") ||
                  speech.includes("следующий")
                ) {
                  self.highlightButton("next_button");
                  self.nextOrder();
                  self.isSpeechRecognized = true;
                  // new Audio('https://monitor.extrapost.ru/static/pozdraudio/1-hi.mp3').play()
                } else if (
                  speech.startsWith("наза") ||
                  speech.includes("назад")
                ) {
                  self.$router.go(-1);
                  self.isSpeechRecognized = true;
                } else if (
                  speech.includes("накладная") ||
                  speech.startsWith("накл")
                ) {
                  self.highlightButton("invoice_button");
                  self.printInvoice();
                  self.isSpeechRecognized = true;
                } else if (speech.includes("ярлык")) {
                  self.highlightButton("label_button");
                  self.printLabel();
                  self.isSpeechRecognized = true;
                } else if (
                  speech.includes("собран") ||
                  speech.includes("готово") ||
                  speech.startsWith("собр")
                ) {
                  self.fulfill();
                  self.isSpeechRecognized = true;
                } else if (
                  speech.includes("закрыть") ||
                  speech.startsWith("закр")
                ) {
                  self.$root.$emit("snack-close");
                  self.isSpeechRecognized = true;
                } else {
                  self.unexpectedSpeech = true;
                }
              }
            };

            this.speechRecognition.onend = function () {
              console.log("end");
              self.isSpeechRecognized = false;
              if (self.speechRecognition !== null)
                self.speechRecognition.start();
            };
            this.speechRecognition.start();
          }
        } else {
          if (this.speechRecognition !== null) {
            this.speechRecognition.stop();
            this.speechRecognition = null;
          }
        }
      } catch (e) {
        console.error(e);
        this.$root.$emit(
          "snack-message",
          this.$i18n.t("speech_only_chrome"),
          "error"
        );
        localStorage.setItem("extrapost_speech_recognition", false);
        this.$nextTick(function () {
          this.speechRecognitionOn = false;
        });
      }
    },
    speak(speech) {
      var utterance = new SpeechSynthesisUtterance();

      // Set the text and voice attributes.
      utterance.text = speech;
      utterance.volume = 1;
      utterance.rate = 1;
      utterance.pitch = 1;
      // utterance.voice = window.speechSynthesis.getVoices()[61]

      console.log(window.speechSynthesis.speak(utterance));
    },
    isInternational(order) {
      return (
        order.to_address && !["ru", "RU"].includes(order.to_address.country)
      );
    },
    printInvoice() {
      var self = this;
      self.printingInvoice = true;
      ExtraPostNodes.printInvoice(this.order.id)
        .then((response) => {
          self.printingInvoice = false;
        })
        .catch((error) => {
          self.$root.$emit(
            "snack-message",
            error["message"] || error.response.statusText,
            "error"
          );
          self.printingInvoice = false;
        }); //, null, "file.pdf")
    },
    printLabel() {
      // Print extras first, cuz they can be slow.
      this.printExtraDocuments();

      if (this.shipment.label_id !== null) {
        var self = this;
        self.printingLabel = true;
        var printing = null;
        if (this.shipment.label.type === "label")
          printing = ExtraPostNodes.printLabel(this.shipment.label.id);
        else printing = ExtraPostNodes.printDocument(this.shipment.label.id);

        printing
          .then((response) => {
            self.printingLabel = false;
          })
          .catch((error) => {
            self.$root.$emit(
              "snack-message",
              error["message"] || error.response.statusText,
              "error"
            );
            self.printingLabel = false;
          });

        this.printExtraLabels();
      }
    },
    printExtraDocuments() {
      if (this.shipment.extra.documents)
        ExtraPostNodes.printDocuments(this.shipment.extra.documents);
    },
    printExtraLabels() {
      if (this.shipment.extra.labels)
        ExtraPostNodes.printLabels(this.shipment.extra.labels);
    },
    oneMoreParcel() {
      // Store parcel weight and line items in parcels
      this.parcels.push({
        weight: Math.round(this.weight * 1000),
        products: this.products,
      });
      this.weight = 0.0;
      this.products = {};
      this.scannedAndUnweighted = false;

      console.log(this.parcels);
    },
    fulfill() {
      if (this.fulfilled || this.buying) return;

      this.buying = true;
      this.oneMoreParcel();
      console.log(this.parcels);
      var self = this;
      var url =
        "fulfill/" +
        this.order.id +
        "?weight=" +
        this.parcels
          .map(({ weight: w }) => {
            return Math.round(w * 1000);
          })
          .join(";");

      ExtraPostAPI.post(url, { parcels: this.parcels })
        .then((response) => {
          console.log(response);
          self.shipment = response.data;
          // Don't push now, cuz we'll get this shipment through socket boradcast update channel
          // self.order.shipments.push(self.shipment)
          self.printLabel();
          self.buying = false;
          self.fulfilled = true;
          self.fulfill_icon = "check_circle";
          self.updateStats();
          if (self.turboMode) {
            setTimeout(() => {
              self.nextOrder();
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error.response);
          var errorMessage =
            error.response.data["error"] ||
            JSON.stringify(error.response.data["errors"]) ||
            "";
          self.$root.$emit(
            "snack-message",
            error.response.statusText + ": " + errorMessage,
            "error"
          );
          self.buying = false;
          self.parcels = [];
          self.doUpdateOrderComment(
            (self.order.comment || "") +
              "\n" +
              Moment().format("YYMMDDThhmmss") +
              " " +
              errorMessage
          );
          var message2sender =
            "Ошибка в заказе №" + self.order.reference + ": " + errorMessage;
          self.telegramSender(message2sender);
        });
    },
    prohibitManualMarking(product) {
      return (
        globalSettings(this).prohibit_manual_marking === true &&
        (!product || !product.extra.unreadable_barcode === true)
      );
    },
    clearOrderData() {
      // Clear previous order data
      this.order.line_items = [];
      this.order.to_address = {};
      this.order.reference = "";
      this.order.comment = "";
      this.order.extra = {};
      this.order.inserted_at = "";
      this.order.company = {};
    },
    nextOrder() {
      var self = this;
      var orderId = this.order.id;

      // this.clearOrderData();
      this.order = { line_items: [] };

      loadingIconOn(this.$root);

      ExtraPostAPI.get("fulfillment/next_order?current_order_id=" + orderId)
        .then((response) => {
          loadingIconOff(self.$root);
          console.log(response);
          var order_id = response.data.next_order_id;
          if (order_id !== null)
            self.$router.push({ path: `/fulfillment/${order_id}` });
          else
            self.$root.$emit(
              "snack-message",
              self.$i18n.t("no_more_orders_to_fulfill")
            );

          if (response.data.restart === true)
            self.$root.$emit(
              "snack-message",
              self.$i18n.t("end_of_orders_to_fulfill"),
              "",
              3000
            );
        })
        .catch((error) => {
          console.log(error.response);
          self.$root.$emit(
            "snack-message",
            error.response.statusText +
              ": " +
              (error.response.data["error"] ||
                JSON.stringify(error.response.data["errors"]) ||
                error.response.data ||
                ""),
            "error"
          );
        });

      this.updateStats();
    },
    updateStats() {
      var self = this;
      ExtraPostAPI.get("fulfillment/stats").then((response) => {
        self.$store.state.totalUnfulfilledOrders =
          response.data.unfulfilled_orders;
        self.$store.state.ordersFulfilledToday = response.data.fulfilled_orders;
      });
    },
    onBarcodeScanned(barcode) {
      console.log("Barcode: " + barcode);
      const ean13Regexp = /^\d{13}$/;

      if (ean13Regexp.test(barcode)) {
        this.updateLineItemByBarcode(barcode);
        if (this.singlePrepackagedProductFulfilled()) {
          this.setWeight(this.order.line_items[0].product.weight / 1000);
        }
      } else {
        // Try to find order by reference or shipment by tracking code.
        this.findAndRedirectToOrderByTrackingCode(barcode);
      }
    },
    findAndRedirectToOrderByTrackingCode(tracking_code) {
      var self = this;
      ExtraPostAPI.get(
        "fulfillment/search?tracking_code=" + tracking_code
      ).then((response) => {
        console.log(response);
        if (response.data.length == 1) {
          self.$router.push({ path: `/fulfillment/${response.data[0]}` });
          self.$root.$emit(
            "snack-message",
            "Found 1 order with tracking code " + tracking_code,
            "success"
          );
        } else {
          self.$root.$emit(
            "snack-message",
            `Found ${response.data.length} orders with tracking code ${tracking_code}`,
            "warning"
          );
        }
      });
    },
    findAndRedirectToOrder(query) {
      var self = this;
      ExtraPostAPI.get("fulfillment/search?query=" + query).then((response) => {
        console.log(response);
        if (response.data.length == 1) {
          self.$router.push({ path: `/fulfillment/${response.data[0]}` });
        }
      });
    },
    updateLineItemByBarcode(barcode) {
      var line_item = this.order.line_items.find((li) => {
        return this.barcodes(li).includes(barcode);
      });
      if (line_item !== undefined) {
        this.markLineItemFulfilled(line_item);
      } else {
        this.$root.$emit(
          "snack-message",
          this.$i18n.t("line_item_not_found", { barcode: barcode }),
          "warning",
          3000
        );
      }
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      let barcode = this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    barcodes(line_item) {
      if (line_item.product !== undefined)
        return line_item.product.barcodes || [];
      else return [line_item.product_sku];
    },
    markLineItemFulfilled(line_item) {
      if (line_item.fulfilled === undefined) {
        line_item.fulfilled = 1;
        this.scannedAndUnweighted = true;
      } else {
        if (line_item.fulfilled == line_item.quantity)
          alert(
            this.$i18n.t("enough_line_items", {
              barcode: line_item.product.barcodes[0],
              quantity: line_item.quantity,
            })
          );
        else {
          line_item.fulfilled += 1;
          this.scannedAndUnweighted = true;
        }
      }
      this.totalFulfilled += 1;

      this.products[line_item.product_id] =
        (this.products[line_item.product_id] || 0) + 1;

      this.hasUnfulfilledLineItems =
        this.findUnfulfilledLineItem() !== undefined;

      this.noFulfilledLineItems = false;

      if (this.singlePrepackagedProductFulfilled()) {
        this.setWeight(this.order.line_items[0].product.weight / 1000);
      }
    },
    markLineItemUnFulfilled(line_item) {
      if (line_item !== undefined) {
        if (line_item.fulfilled === undefined) line_item.fulfilled = 0;
        else {
          if (line_item.fulfilled > 0) {
            line_item.fulfilled -= 1;
          }
        }
        this.totalFulfilled -= 1;
        this.products[line_item.product_id] -= 1;
      } else {
        alert(this.$i18n.t("line_item_not_found", { barcode: barcode }));
      }

      this.hasUnfulfilledLineItems =
        this.findUnfulfilledLineItem() !== undefined;

      this.noFulfilledLineItems = this.totalFulfilled < 1;
    },
    isFulfilled(line_item) {
      var li_barcodes = this.barcodes(line_item);
      var line_item = this.order.line_items.find((li) => {
        return this.barcodes(li)[0] === li_barcodes[0];
      });

      return line_item.fulfilled >= line_item.quantity;
    },
    isUnFulfilled(line_item) {
      var li_barcodes = this.barcodes(line_item);
      var line_item = this.order.line_items.find((li) => {
        return this.barcodes(li) === li_barcodes;
      });

      return (
        line_item !== undefined &&
        (line_item.fulfilled === 0 || line_item.fulfilled === undefined)
      );
    },
    singlePrepackagedProductFulfilled() {
      return (
        this.order.line_items.length == 1 &&
        this.order.line_items[0].quantity == 1 &&
        this.order.line_items[0].fulfilled == 1 &&
        this.order.line_items[0].product &&
        this.order.line_items[0].product.extra["does_not_need_package"]
      );
    },
    lineItemRowStyle(line_item) {
      var percent = (line_item.fulfilled / line_item.quantity) * 100;
      return (
        "background-image: linear-gradient(to right, #00bbff " +
        percent +
        "%, " +
        (this.isDarkTheme() ? "#424242 " : "#ffffff ") +
        percent +
        "%);" +
        (line_item.product_id ? "" : "color: red;")
      );
    },
    markLineItemOnSpace(event) {
      var line_item = this.findUnfulfilledLineItem();
      console.log(line_item);
      if (line_item) {
        line_item.fulfilled = (line_item.fulfilled || 0) + 1;
        this.totalFulfilled += 1;
      }
      this.hasUnfulfilledLineItems =
        this.findUnfulfilledLineItem() !== undefined;

      this.noFulfilledLineItems = false;
    },
    setWeightToOne(event) {
      console.log(event);
    },
    orderIsFulfilled() {
      return this.order.shipments.length > 0;
    },
    needsWeight() {
      const WEIGHTLESS_CARRIERS = ["Ozon", "OzonFBO", "YandexMarket", "Wildberries"];
      return (
        this.weight * 1000 < 10 && !WEIGHTLESS_CARRIERS.includes(this.order.carrier)
      );
    },
    findUnfulfilledLineItem() {
      return this.order.line_items.find((li) => {
        return li.fulfilled === undefined || li.fulfilled < li.quantity;
      });
    },
    fullAddress(address) {
      return fullAddress(address);
    },
    countryName(country) {
      return countryName(country);
    },
    doUpdateOrderComment(comment) {
      this.order.comment = comment;
      var self = this;
      this.commentSuccess = false;
      this.commentError = false;
      ExtraPostAPI.put("orders/" + this.order.id, {
        order: { comment: comment },
      })
        .then((response) => {
          self.commentSuccess = true;
        })
        .catch((error) => {
          self.commentError = true;
          self.$root.$emit(
            "snack-message",
            error.response.data["error"] || error.response.statusText,
            "error"
          );
        });
    },
    updateOrderComment: _.debounce(
      function (comment) {
        this.doUpdateOrderComment(comment);
      },
      1000,
      { trailing: true }
    ),
    joinUpdatesChannel() {
      var apiToken = localStorage.getItem("extrapost_api_access_token");

      if (apiToken) {
        let socket = new Socket("/api/socket", {
          params: { api_access_token: apiToken },
        });
        socket.connect();

        var self = this;

        this.channel = socket.channel("updates", {});

        this.channel
          .join()
          .receive("ok", (resp) => {
            console.log(
              "Server order update channel joined successfully",
              resp
            );
          })
          .receive("error", (resp) => {
            console.log("Unable to join server update channel", resp);
          });

        this.channel.on("update:orders", (payload) => {
          console.log("Fulfillment: update from api server", payload);

          if (payload.id === self.order.id) {
            if (payload.line_items === undefined) {
              _.merge(self.order, payload);
            } else {
              // Reload order, because we cannot merge it correctly with line_items
              self.loadOrder();
            }
          }
        });

        this.channel.on("delete:orders", (payload) => {
          console.log("Fulfillment: delete from server", payload);

          if (payload.id === self.order.id) {
            self.$root.$emit(
              "snack-message",
              "Заказ только что удалён. Сборка невозможна.",
              "error"
            );
          }
        });
      }
    },
    highlightButton(button_id) {
      var button = document.getElementById(button_id);
      if (button !== undefined && button !== null) {
        button.style.animation = "highlight-button 3s";
        setTimeout(() => {
          button.style.animation = "";
        }, 3000);
      }
    },
    telegramSender(message) {
      return ExtraPostAPI.post("telegram/company/" + this.order.company.id, {
        message: message,
      });
    },
    smsReceiver(message) {
      return ExtraPostAPI.post("sms/order/" + this.order.id, {
        message: message,
      });
    },
    productExhausted(line_item) {
      this.reportingLineItemId = line_item.id;
      this.exhaustedItems.push(line_item.id);
      var self = this;
      var message2sender =
        line_item.product_title +
        ` [${line_item.product_sku}]` +
        " ЗАКОНЧИЛСЯ.";
      var message2receiver = `Заказ №${this.order.reference}(${
        this.order.project && this.order.project.domain
      }) ожидает поступления '${line_item.product_title}'`;

      this.doUpdateOrderComment(
        (this.order.comment || "") +
          "\n" +
          Moment().format() +
          ": " +
          message2sender
      );
      this.smsReceiver(message2receiver);
      this.telegramSender(message2sender).then((resp) => {
        self.reportingLineItemId = null;
      });
    },
    exhaustedIcon(line_item_id) {
      if (this.exhaustedItems.includes(line_item_id)) return "cancel";
      else return "remove_shopping_cart";
    },
    isDarkTheme() {
      return localStorage.getItem("extrapost_dark_theme") === "true";
    },
    deleteShipment(sh) {
      if (confirm(this.$i18n.t("sure_to_delete")))
        ExtraPostAPI.delete(`shipments/${sh.id}`)
          .then((response) => {
            this.order.shipments.splice(this.order.shipments.indexOf(sh), 1);
          })
          .catch((error) => {
            console.log(error);
            self.$root.$emit("snack-message", error.response.data, "error");
          });
    },
    openDialog() {
      this.$refs.editOrder.setOrder(this.order);
      this.editDialog = true;
    },
    closeDialog() {
      this.editDialog = false;
    },
    saveOrder() {
      var self = this;
      ExtraPostAPI.put("orders/" + this.order.id, {
        order: this.order,
      })
        .then((response) => {
          self.closeDialog();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            self.orderErrors = error.response.data.errors;
            console.log(
              "Fulfillment order saving errors",
              error.response.data.errors
            );
          } else {
            console.log(error.response.data);
            self.$root.$emit("snack-message", error.response.data, "error");
          }
        });
    },
    callClient() {
      callClient(this, this.order);
    },
  },
};
</script>

<style>
#fulfillment_progress {
  margin-top: 0px;
  margin-bottom: -30px;
}

#fulfillment_progress .v-progress-linear__content {
  color: #fff;
  font-size: 110%;
  padding-top: 3px;
  padding-left: 8px;
}

#fulfillment_progress * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

div#fulfillment_progress:hover,
div#fulfillment_progress:hover > div {
  height: 30px !important;
}

#fulfillment_header {
  min-height: 160px;
  padding-bottom: 0px;
}

#carrier_logo {
  margin-bottom: -12px;
}

#comment i.v-icon {
  margin-bottom: -5px;
}

#comment {
  padding-top: 0px;
}

#control_panel {
  padding-top: 18px;
}

#control_panel button {
  margin-bottom: 0.6em;
}

#control_panel button .v-btn__content {
  justify-content: left !important;
}

@keyframes highlight-button {
  from {
    background-color: #00bbff;
  }
  to {
    background-color: none;
  }
}

#weight {
  font-size: 400%;
  color: #00bbff;
  border: none;
  min-height: 50px;
  min-width: 80px;
  margin-left: 10px;
}

#weight span {
  padding-right: 5px;
}

td.arrow-data {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#line_items_table tbody td:not(:nth-child(1)),
#line_items_table tfoot td:not(:nth-child(1)),
#line_items_table thead tr th.column:not(:nth-child(1)) {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.product-image-tooltip {
  height: 97%;
}

#line_items_table tfoot td {
  font-weight: bold;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.priority {
  background: repeating-linear-gradient(
    45deg,
    #bca66088,
    #bca66088 12px,
    #98464688 12px,
    #98464688 24px
  );
  background-blend-mode: difference;
}
</style>
