<template>
  <router-view />
</template>

<script>
  export default {
    name: 'StoreToken',
    beforeCreate: function () {
      // If we have these params,
      // then this is a callback from ExtraPost API authenticator
      var token = this.$route.query.token
      var login = this.$route.query.login
      if (token && login) {
        localStorage.setItem('extrapost_api_access_token', token)
        localStorage.setItem('extrapost_api_login', login)
        this.$ga.event('Authentication', 'OAuth Login', login)

        window.location.href = '/'
      } else {
        this.$ga.event('Authentication', 'OAuth Login Error', this.$route.query.error)
        window.location.href = '/login?error=' + this.$route.query.error
      }
    }
  }
</script>
